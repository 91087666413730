import axios from 'axios';
import { Salesforce } from "../utils/urls";


// Salesforce hierarchy service
export const SalesforceHierarchyService = {
    // Get all distributors data
    getAllDistributorsData: async () => {
        let result = await axios.get(Salesforce.Hierarchy.ALL_DISTRIBUTORS_DATA);

        return result;
    },
}