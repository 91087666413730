// Geography Rollup Service
import axios from 'axios';
import { v4 as uuid_v4 } from 'uuid';
import { Rollups } from '../utils/urls';

export const GeographyRollupService = {
	// Get all Geography rollups
    getAllGeographyRollups: async () => {
        let result = await axios.get(Rollups.Geography.ALL_GEOGRAPHY_ROLLUPS);
        rebuildResult(result);
        
        return result;
    },

	// Update rollup position while dragging item
	updateGeographyRollupPosition: async (rowData) => {
		let result = await axios.put(Rollups.Geography.UPD_ROLLUP_POSITION, {
            data: rowData
        });
        rebuildResult(result);
        
        return result;
	},

    // Add Geography rollup
    addGeographyRollup: async (rowData) => {
        let result = await axios.post(Rollups.Geography.ADD_GEOGRAPHY_ROLLUPS, {
            data: rowData
        });
        rebuildResult(result);
        
        return result;
    },

    // Update Geography rollup
    updateGeographyRollup: async (rowData) => {
        let result = await axios.put(Rollups.Geography.UPD_GEOGRAPHY_ROLLUPS, {
            data: rowData
        });
        rebuildResult(result);
        
        return result;
    },

    // Delete Geography rollup
    deleteGeographyRollup: async (rowData) => {
        let result = await axios.delete(Rollups.Geography.DEL_GEOGRAPHY_ROLLUPS, {
            data: {
                data: rowData
            }
        });
        rebuildResult(result);
        
        return result;
    },    

	// Copy / move Geography Rollup(s) into another position
	cutPasteGeographyRollups: async (rowData) => {
		let result = await axios.put(Rollups.Geography.CUT_PASTE_ROLLUPS, {
            data: rowData
        });
        rebuildResult(result);
        
        return result; 
    }
}

// Re-build resultset
const rebuildResult = (result) => {
    // Geography rollups re-build to add uuid
    let _rows_data_geography_rollups = [];
    (result?.data?.data_geography_rollups?.rows || []).forEach(item => {
        _rows_data_geography_rollups.push({...item, UUID: uuid_v4()});
    });

    if (result?.data?.data_geography_rollups) {
        result.data.data_geography_rollups = {
            ...result?.data?.data_geography_rollups, 
            rows: _rows_data_geography_rollups
        }
    }
}