
// import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';


// Styled component
const StyledNotFound = styled('div')(({theme}) => ({
	margin: theme.spacing(25, 'auto', 0)
}));


// Notfound module
export const NotFound = () => {
	
    return (
        <StyledNotFound>
			<Typography align='center' variant='h5'>OOPS! The requested page was not found.</Typography>
		</StyledNotFound>
    );
};
