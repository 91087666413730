import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, IconButton, Avatar } from '@mui/material';
import { useIsAuthenticated } from '@azure/msal-react';
import logo from '../assets/logo.png';
// include utils module
require('../utils/utils');


// Styled component - logo
const StyledLogo = styled('img')({
	height: '2.8em',
	verticalAlign: 'middle'
});

// Styled component - logo link
const StyledLogoLink = styled(Link)(({theme}) => ({
	textDecoration: 'none',
	marginLeft: theme.spacing(1.5),
	marginRight: theme.spacing(1),
	color: theme.palette.common.white
}));

// Styled component - Avatar initials
const StyledAvatar = styled(Avatar)({
	width: 34,
	height: 34,
	fontWeight: '500',
	fontSize: '1.06rem',
	color: '#1976d2',
	backgroundColor: '#fff'
});


// Header section
export const Header = (props) => {
	const {graphData: userInfo} = props;
	const isAuthenticated = useIsAuthenticated();
	const isUserInfoAvailable = !!userInfo?.name;
	
	return (
		<AppBar position="sticky">
	        <Toolbar disableGutters>
				<StyledLogoLink to="/">
					<StyledLogo src={logo} alt="SMIS Admin" />
				</StyledLogoLink>
				<Typography variant="h6" component="span" sx={{flexGrow: 1}}>SMIS Admin</Typography>
				{isAuthenticated && isUserInfoAvailable ? (
					<div>
						<IconButton size="large" aria-label="account of current user" color="inherit">
							<StyledAvatar alt="">{userInfo?.initials}</StyledAvatar>
							<Typography style={{marginLeft: '10px'}} textAlign="right">Welcome, {userInfo?.name}</Typography>
						</IconButton>
					</div>
				) : null}
			</Toolbar>
		</AppBar>
	)
}
