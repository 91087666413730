import { env } from '../utils/env.variables';

/**
 * Configuration object to be passed to MSAL instance on creation
 */
export const msalConfig = {
    auth: {
        clientId: env.getEnv('REACT_APP_CLIENT_APP_ID'),
        authority: env.getEnv('REACT_APP_AUTHORITY'),
        redirectUri: env.getEnv('REACT_APP_REDIRECT_URI')
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,      // Set this to true if having issues on IE11 or Edge
    }
}

/**
 * Scopes you add here will be prompted for user consent during sign-in
 */
export const loginRequest = {
    scopes: ['User.Read'],
    prompt: 'select_account'
}

/**
 * API Bearer token request
 */
export const tokenRequest = {
    scopes: [`api://${env.getEnv('REACT_APP_CLIENT_APP_ID')}/AppAccess`]
}

/**
 * Add here the scopes to request when obtaining an access token for MS Graph API
 * https://graph.microsoft.com/v1.0/me/photo/$value
 * https://graph.microsoft.com/oidc/userinfo
 */
export const graphRequest = {
    graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me'
}
