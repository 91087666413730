import { createTheme } from '@mui/material/styles';

// Create theme
export const smisTheme = createTheme({
	// palette: {
	// 	mode: 'dark',
	// 	primary: {
	// 		main: '#1976d2'
	// 	}
	// }
});
