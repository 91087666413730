import axios from 'axios';
import { v4 as uuid_v4 } from 'uuid';
import { Salesforce } from '../utils/urls';


// Salesforce Rep Names service
export const SalesforceRepNameService = {
    // Get all distributors data
    getAllDistributorsData: async () => {
        let result = await axios.get(Salesforce.RepNames.ALL_DISTRIBUTORS_DATA);

        return result;
    },

	// Get all Sales Rep data for selected distributor
    getSfRepNamesData: async (data) => {
        let result = await axios.get(Salesforce.RepNames.REP_NAMES_DATA, {
            params: {
                jdeAbNo: data?.jdeAbNo
            }
        });
        rebuildResult(result);

        return result;
    },

	// Update Salesforce Representative Name
	updateSfRepresentativeName: async (rowData) => {
		let result = await axios.put(Salesforce.RepNames.REP_NAMES_UPD_DATA, {
            data: rowData
        });
        rebuildResult(result);
        
        return result;
	}

}

// Re-build resultset
const rebuildResult = (result) => {
    // Salesfore rep names data re-build to add uuid
    let _rows_data_sf_rep_names = [];
    (result?.data?.rows || []).forEach(item => {
        _rows_data_sf_rep_names.push({...item, UUID: uuid_v4()});
    });
    if (result?.data?.rows) {
        result.data = {
            ...result?.data, 
            rows: _rows_data_sf_rep_names
        }
    }
}
