import React, { useState, useEffect, /* useRef,  */useCallback } from 'react';
import { useWindowDimensions } from '../hooks/hook.window.dimensions';
import { List } from 'react-virtualized';
import { CircularProgressLoader, OverlayLoader, SnackbarAlert } from '../components/component.snackbar.alert';
import { Container, StyledCaptionBar, StyledColumnContainerDiv, StyledColumnResizeDisableDiv, StyledColumnResizeHandler, StyledColumnSortDiv, 
    StyledDropDownExd, StyledFilterContainerDiv, StyledFilterInput, StyledFilterItemDiv, StyledListItem, StyledNoRecordDiv, StyledPlaceHolderContainerDiv, 
    StyledPlaceHolderDiv, StyledPlaceHolderInnerContainerDiv, StyledRowItemContainer, StyledRowItemDiv, StyledRowItemText, TableRowContainer, Title } from '../themes/style';
// import { IconButton, InputAdornment } from '@mui/material';
import * as ColumnResizer  from 'react-draggable';
/* 
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext'; */
import { SearchBoxDialog } from '../components/component.search.dialog';
import { ConstCfg, GridCfg, SnackbarCfg } from '../utils/utils';
import { Checkbox, Divider, IconButton, InputAdornment, Menu, Select } from '@mui/material';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
// import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
// import { PopupDialog } from '../components/component.popup.dialog';
import { ProductXrefValidationService } from '../services/service.prod.xref.validation';
import { env } from '../utils/env.variables';
import { VirtualizedDataGrid1 } from '../components/component.table1.virtualized';


// Distributor Product Xref Validation Component
export const ProductXrefValidation = (props) => {
    const {/* leftDivRef,  */dimensions: tableDimension, graphData: userInfo} = props;
    const {isUserCanModify: isModifyEnabled} = userInfo;
	/* const {width} = useWindowDimensions();
    const _width = ((width - (leftDivRef?.current?.clientWidth || 182)) - 34);
    const _height = (leftDivRef?.current?.clientHeight || window.screen.availHeight) - 210;
    const tableDimension = {width: _width, height: _height}; */
	// const searchInputRef = useRef(null);
    // const tableDimension = dimensions;
    // console.log('>dimensions>#>', dimensions);

	/* const [searchInputText, setSearchInputText] = useState('xxx');
    const [searchStates, setSearchStates] = useState({
		prevNextCount: 0, 
		totalCount: 1, 
		prevBtnDisable: true, 
		nextBtnDisable: true
	}); */
    
    const [sortColumnOrder, setSortColumnOrder] = useState({
		sortBy: '',
		sortOrder: 'asc'
	});
    const [showLoader, setShowLoader] = useState(false);
    const [showOverlayLoader, setShowOverlayLoader] = useState(false);
	const [rowData, setRowData] = useState({
        odata: undefined,
        fdata: undefined,
        cdata: undefined
    });
    const [tableData, setTableData] = useState();
    const [gridColumnWidths, setGridColumnWidths] = useState({});
    /* const [showDialog, setShowDialog] = useState(false);
	const [dialogType, setDialogType] = useState({
        icon: ArrowCircleRightOutlinedIcon,
        action: (e, d) => {}
    }); */
    const [filterObj, setFilterObj] = useState();
    const [selectedRow, setSelectedRow] = useState({});
    const [, setShowSnackbar] = useState();
	const [scrollToIndex, setScrollToIndex] = useState(-1);
    // const [matchedSearchIds, setMatchedSearchIds] = useState([]);
    // const [searchSelectedIndex, setSearchSelectedIndex] = useState();
    const [gridColumnWidthTotal, setGridColumnWidthTotal] = useState(tableDimension?.width);
    const [xRefGridColumns, setXRefGridColumns] = useState([
        {label: 'Dist ID', field: 'JDE_ADDR_BOOK_N', width: 80, visible: true, filter: true, sorting: true, searching: true},
        {label: 'Market', field: 'MKT_D', width: 140, visible: true, filter: true, sorting: true, searching: true},
        {label: 'State', field: 'ST_C', width: 60, visible: true, filter: true, sorting: true},
        {label: 'Affiliation', field: 'DIST_AFLTN_D', width: 120, visible: true, filter: true, sorting: true, searching: true},
        {label: 'Dist Prod ID', field: 'DIST_PROD_I', width: 100, visible: true, filter: true, sorting: true},
        {label: 'Dist Prod Desc', field: 'DIST_PROD_D', width: 160, visible: true, filter: true, sorting: true, searching: true},
        {label: 'Curr SKU', field: 'CURR_XREF_SKU', width: 80, visible: true, filter: true, sorting: true},
        {label: 'Curr Prod Desc', field: 'CURR_DIST_PROD_DESC', width: 160, visible: true, filter: true, sorting: true},
        {label: 'Sugg SKU', field: 'SUGG_SKU', width: 80, visible: true, filter: true, sorting: true},
        {label: 'Sugg Prod Desc', field: 'SUGG_PROD_DESC', width: 160, visible: true, filter: true, sorting: true},
        {label: 'Valid Flag', field: 'VLD_DIST_PROD_CNFG_X', width: 100, visible: true, /* filter: true,  */sorting: true, iedit: {edt: false, typ: 'select'}, etdinline: true},
        {label: 'Brand', field: 'PLN_BRAND_D', width: 140, visible: true, filter: true, sorting: true},
        {label: 'Curr Ship Qty', field: 'CURR_SHIP_QTY', width: 110, visible: true, filter: true, sorting: true},
        {label: 'Sugg Ship Qty', field: 'SUGG_SHIP_QTY', width: 110, visible: true, filter: true, sorting: true},
        {label: 'Curr Depl Elig', field: 'DEPL_ELIG_X', width:110, visible: true, filter: true, sorting: true},
        {label: 'Sugg Depl Elig', field: 'SUGG_DEPL_ELIG_X', width: 110, visible: true, filter: true, sorting: true},
        {label: 'CS Qty', field: 'CS_Q', width: 80, visible: true, filter: true, sorting: true}
    ]);

    GridCfg.setActionColumnProps({
        width: 80
    });

    SnackbarCfg.setState({
        state: setShowSnackbar
    })

    // Table grid input properties - columns/options/actions etc.
    const tableInputProps = {
        // Columns
        columns: [
            {label: 'Dist ID', field: 'JDE_ADDR_BOOK_N', width: 80, visible: true, filter: true, sorting: true, searching: true},
            {label: 'Market', field: 'MKT_D', width: 140, visible: true, filter: true, sorting: true, searching: true},
            {label: 'State', field: 'ST_C', width: 60, visible: true, filter: true, sorting: true},
            {label: 'Affiliation', field: 'DIST_AFLTN_D', width: 120, visible: true, filter: true, sorting: true, searching: true},
            {label: 'Dist Prod ID', field: 'DIST_PROD_I', width: 100, visible: true, filter: true, sorting: true},
            {label: 'Dist Prod Desc', field: 'DIST_PROD_D', width: 160, visible: true, filter: true, sorting: true, searching: true},
            {label: 'Curr SKU', field: 'CURR_XREF_SKU', width: 80, visible: true, filter: true, sorting: true},
            {label: 'Curr Prod Desc', field: 'CURR_DIST_PROD_DESC', width: 160, visible: true, filter: true, sorting: true},
            {label: 'Sugg SKU', field: 'SUGG_SKU', width: 80, visible: true, filter: true, sorting: true},
            {label: 'Sugg Prod Desc', field: 'SUGG_PROD_DESC', width: 160, visible: true, filter: true, sorting: true},
            {label: 'Valid Flag', field: 'VLD_DIST_PROD_CNFG_X', width: 100, visible: true, sorting: true, iedit: {edt: true, typ: 'select'}},
            {label: 'Brand', field: 'PLN_BRAND_D', width: 140, visible: true, filter: true, sorting: true},
            {label: 'Curr Ship Qty', field: 'CURR_SHIP_QTY', width: 110, visible: true, filter: true, sorting: true},
            {label: 'Sugg Ship Qty', field: 'SUGG_SHIP_QTY', width: 110, visible: true, filter: true, sorting: true},
            {label: 'Curr Depl Elig', field: 'DEPL_ELIG_X', width:110, visible: true, filter: true, sorting: true},
            {label: 'Sugg Depl Elig', field: 'SUGG_DEPL_ELIG_X', width: 110, visible: true, filter: true, sorting: true},
            {label: 'CS Qty', field: 'CS_Q', width: 80, visible: true, filter: true, sorting: true}
        ],
        
        // Table actions
        /* actions: [
            {
                // Edit salesforce rep names data
                title: 'Process',
                icon: ArrowCircleRightOutlinedIcon,
                onClick: function(e, result) {
                    if (!isModifyEnabled) {
                        return false;
                    }
                    console.log('>action clicked>', e, result);
                    
                    // Open dialog to confirm deletion
                    setDialogType({
                        type: 'P',
                        title: this.title,
                        icon: this.icon,
                        actionText: this.title,
                        rowItem: result?.rowItem,
                        action: (e, newData) => {
                            // Get Sales Rep data based on distributor
                            console.log('>process-new-data');
                            (async () => {
                                // await updateSfRepresentativeName({...newData, usrid: userInfo?.upn});
                            })();
                        }
                    });
                    setShowDialog(true);
                }
            }
        ], */

        mactions: {
            action1: {
                onClick: (e, {rowItem}) => {
                    if (!isModifyEnabled) {
                        return false;
                    }
                    console.log(e, rowItem);
                    setSelectedRow(rowItem);
                    /* setSelectedRow(prev => ({
                        ...prev, 
                        ...rowItem
                    })); */
                    console.log('>data>', {...rowItem, usrid: userInfo?.upn});
                    /* let newTableData = [];
                    tableData.forEach((data, i) => {
                        if (data.UUID === rowItem.UUID) {
                            if (rowItem?.NV_VLD_DIST_PROD_CNFG_X !== 'Y') {
                                data['VLD_DIST_PROD_CNFG_X'] = rowItem?.NV_VLD_DIST_PROD_CNFG_X;
                                newTableData.push(data);
                            }
                        }
                        else {
                            newTableData.push(data);
                        }
                    });
                    console.log('>newTableData>', rowItem.UUID, newTableData); */
                    
                    // return;

                    (async () => {
                        setShowOverlayLoader(true);
                        await ProductXrefValidationService.updateProdXrefData({...rowItem, usrid: userInfo?.upn})
                            .then(response => {
                                console.log('response>', response);
                                const {status} = response.data;
                                if (status.isOk) {
                                    let newTableData = [];
                                    tableData.forEach((data, i) => {
                                        if (data.UUID === rowItem.UUID) {
                                            if (rowItem?.NV_VLD_DIST_PROD_CNFG_X !== 'Y') {
                                                data['VLD_DIST_PROD_CNFG_X'] = rowItem?.NV_VLD_DIST_PROD_CNFG_X;
                                                newTableData.push(data);
                                            }
                                        }
                                        else {
                                            newTableData.push(data);
                                        }
                                    });
                                    setTableData(newTableData);
                                    /* setRowData(prev => ({
                                        odata: prev.odata?.filter(itm => rowItem[`${ConstCfg._FLD_PREFIX}VLD_DIST_PROD_CNFG_X`] === 'Y' && itm.UUID !== rowItem.UUID),
                                        fdata: prev.fdata?.filter(itm => rowItem[`${ConstCfg._FLD_PREFIX}VLD_DIST_PROD_CNFG_X`] === 'Y' && itm.UUID !== rowItem.UUID),
                                        cdata: prev.cdata?.filter(itm => rowItem[`${ConstCfg._FLD_PREFIX}VLD_DIST_PROD_CNFG_X`] === 'Y' && itm.UUID !== rowItem.UUID)
                                    })); */
                                }
                                else {
                                    setSelectedRow(prev => ({
                                        ...prev,
                                        [`${ConstCfg._FLD_PREFIX}VLD_DIST_PROD_CNFG_X`]: !prev[`${ConstCfg._FLD_PREFIX}VLD_DIST_PROD_CNFG_X`]
                                    }))
                                }
                                SnackbarCfg
                                    .setSeverity(status.isOk ? SnackbarCfg.severities.success : SnackbarCfg.severities.error)
                                        .setMessage(status.isOk ? env.getEnv('REACT_APP_MSG_UPDATE_SUCCESS') : status?.message)
                                            .show();
                            })
                            .catch(error => {
                                setSelectedRow(prev => ({
                                    ...prev,
                                    [`${ConstCfg._FLD_PREFIX}VLD_DIST_PROD_CNFG_X`]: !prev[`${ConstCfg._FLD_PREFIX}VLD_DIST_PROD_CNFG_X`]
                                }))
                                SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                                    .setMessage((error?.response?.data?.message || error?.message)).show();
                            })
                            .finally(() => {
                                setShowOverlayLoader(false);
                                setTimeout(() => { setSelectedRow({}); }, 10000);
                            });
                    })();


                    // Open dialog to confirm deletion
                    /* setDialogType({
                        type: 'P',
                        title: 'Process',
                        icon: ArrowCircleRightOutlinedIcon,
                        actionText: 'Process',
                        rowItem: rowItem,
                        action: (e, newData) => {
                            // Get Sales Rep data based on distributor
                            console.log('>process-new-data>>', newData);
                            / * (async () => {
                                await updateSfRepresentativeName({...newData, usrid: userInfo?.upn});
                            })(); * /
                        }
                    });
                    setShowDialog(true); */
                }
            }
        },
        // Table options
        options: {
            action: false,
            filtering: true,
            searching: true,
            contextmenu: true
        }
    }


    // Snackbar close callback
    /* const onSnackbarCloseCallback = useCallback(() => {
        console.log('>onSnackbarCloseCallback>', SnackbarCfg.open);
        // setShowSnackbar(SnackbarCfg.open);
    }, []) */

    // onChangeColumnMenuItemCallback to show / hide grid columns
    const onChangeColumnMenuItemCallback = useCallback((e, item) => {
        const xRefGridColumnsNew = xRefGridColumns
            .map(itm => (itm.field === item.field ? {...itm, visible: !item?.visible} : itm));
        setXRefGridColumns(xRefGridColumnsNew);

        // Rest columns width as per column visibility
        const get_calculated_col_widths = GridCfg.fnSetGridColumnWidths({
            columns: xRefGridColumnsNew, 
            options: tableInputProps.options
        });
        setGridColumnWidths(get_calculated_col_widths);

        // Set grid columns total width
        const _gridColumnsTotalWidth = xRefGridColumnsNew
            .reduce((prev, curr) => curr?.visible && curr?.width ? prev + curr?.width : prev, 0);
        setGridColumnWidthTotal(_gridColumnsTotalWidth < tableDimension.width ? tableDimension.width : _gridColumnsTotalWidth);
    }, [xRefGridColumns, tableDimension.width, tableInputProps.options])

    // onSearch callback
    const onSearchCallback = useCallback((e, result) => {
        const {foundFirstIndex, searchData: {cdata}} = result;
        setRowData(prev => ({
            ...prev,
            cdata: cdata
        }));
		setScrollToIndex(foundFirstIndex);
    }, [])

    // onSearchClear callback
    const onSearchClearCallback = useCallback((e, {odata}) => {
        setScrollToIndex(-1);
        setRowData(prev => ({
            ...prev,
            cdata: odata
        }));
    }, [])

    // onSearchFirstCallback callback
    const onSearchFirstCallback = useCallback((e, searchedIndex) => {
		setScrollToIndex(searchedIndex);
    }, [])

    // onSearchLastCallback callback
    const onSearchLastCallback = useCallback((e, searchedIndex) => {
		setScrollToIndex(searchedIndex);
    }, [])

    // onSearchPrevCallback callback
    const onSearchPrevCallback = useCallback((e, searchedIndex) => {
		setScrollToIndex(searchedIndex);
    }, [])

    // onSearchNextCallback callback
    const onSearchNextCallback = useCallback((e, searchedIndex) => {
		setScrollToIndex(searchedIndex);
    }, [])
    
    // onSortColumn callback to sort column
    const onSortColumnCallback = useCallback((e, props) => {
        GridCfg.fnSortColumn(e, {
            ...props, 
            setRowData: setRowData,
            sortColumns: sortColumnOrder,
            setSortColumns: setSortColumnOrder
        });
    }, [sortColumnOrder])

    // onFilterChangeCallback callback to filter column data
    const onFilterChangeCallback = useCallback(({target: {name, value}}) => {
        const {odata} = rowData;
		const newFilterObj = {...filterObj, [name]: value};
        const filteredData = odata?.filter(props => Object
            .entries(newFilterObj)
                .every(([key, val]) => 
                    !val?.length || (props[key] || '')?.toString().toLowerCase().includes((val || '').toLowerCase())
                ));
        
        setFilterObj(newFilterObj);
		setRowData(prev => ({
            ...prev,
            fdata: filteredData,
            cdata: filteredData
        }));
    }, [filterObj, rowData])
    
    // onResizeColumn callback to resize column while dragging
    const onResizeColumnCallback = useCallback((e, props) => {
        GridCfg.fnResizeColumn(e, {
            ...props, 
            columns: xRefGridColumns,
            setColWidths: setGridColumnWidths
        });
    }, [xRefGridColumns])

    // onRowActionClick callback to perform specific action
    const onRowActionClickCallback = useCallback((e, rowItem, rowAction) => {
		if (!isModifyEnabled) {
			return false;
		}

		rowAction?.onClick && 
            rowAction?.onClick(e, {rowItem: rowItem});
    }, [isModifyEnabled])

    // Popup Dialog close callback
    /* const onDialogCloseCallback = useCallback(e => {
        setSelectedRow(prev => ({
            ...prev,
            [`${ConstCfg._FLD_PREFIX}VLD_DIST_PROD_CNFG_X`]: !prev[`${ConstCfg._FLD_PREFIX}VLD_DIST_PROD_CNFG_X`]
        }))
        // setShowDialog(false);
    }, []) */

    // userEffect hook to set column widths while resizing
    useEffect(() => {
        // Filter columns
        !filterObj && 
            setFilterObj(xRefGridColumns.reduce((item, {field}) => {item[field] = ''; return item;}, {}));
        
        // Set column widths
        if (!gridColumnWidths[GridCfg._ACT_COL]) {
            const get_calculated_col_widths = GridCfg.fnSetGridColumnWidths({
                columns: xRefGridColumns, 
                options: tableInputProps.options
            });
            setGridColumnWidths(get_calculated_col_widths);

            // Set grid columns total width
            const _gridColumnsTotalWidth = xRefGridColumns
                .reduce((prev, curr) => curr?.visible && curr?.width ? prev + curr?.width : prev, 0);
            setGridColumnWidthTotal(_gridColumnsTotalWidth);
        }
    }, [gridColumnWidths, filterObj, xRefGridColumns, tableInputProps.options])

    // useEffect hook to load data during page load
    useEffect(() => {
        (async () => {
            setShowLoader(true);
            // Get all distributor product xref data
            await ProductXrefValidationService.getAllProdXrefData()
                .then(response => {
                    const {status, rows} = response.data;
                    setTableData(rows || []);

                    /* setRowData({
                        odata: rows || [],
                        fdata: rows || [],
                        cdata: rows || []
                    }); */
                    if (!status.isOk) {
                        SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                            .setMessage(env.getEnv('REACT_APP_MSG_RETRIEVE_FAILED')).show();
                    }
                })
                .catch(error => {
                    SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                        .setMessage((error?.response?.data?.message || error?.message)).show();
                })
                .finally(() => {
                    setShowLoader(false);
                });
        })();
    }, [])


    // Customized Properties
	const customProps1 = {
		title: 'Distributor Product XRef Validation',
        showLoader: showLoader,
        isModifyEnabled: isModifyEnabled,
        vgrid: {
            id: 'idPrdXrefValidationCntr',
            rowData: rowData.cdata,
            selectedRow: selectedRow,
            tblProps: {
                ...tableInputProps, 
                columns: xRefGridColumns
            },
            gridWidth: tableDimension?.width,
            gridHeight: tableDimension?.height,
            gridTotalWidth: gridColumnWidthTotal,
            gridColWidths: gridColumnWidths,
            scrollToIndex: scrollToIndex,
            psearch: {
                gridData: rowData,
                columns: xRefGridColumns,
                callbacks: {
                    onSearch: onSearchCallback,
                    onSearchClear: onSearchClearCallback,
                    onSearchFirst: onSearchFirstCallback,
                    onSearchLast: onSearchLastCallback,
                    onSearchPrev: onSearchPrevCallback,
                    onSearchNext: onSearchNextCallback
                }
            },
            psorts: {
                sortColumnOrder: sortColumnOrder,
                onSortColumn: onSortColumnCallback
            },
            callbacks: {
                onFilterChange: onFilterChangeCallback,
                onResizeColumn: onResizeColumnCallback,
                onRowActionClick: onRowActionClickCallback
            },
            /* pdialog: {
                title: 'Product XRef Validation',
                showDialog: showDialog,
                dialogType: dialogType,
                callbacks: {
                    onDialogClose: onDialogCloseCallback
                }
            }, */
            dwcolmenu: {
                onChangeColumnMenuItem: onChangeColumnMenuItemCallback
            }
        }
    }



    // Customized Grid Properties
	const customProps = {
		title: 'Distributor Product XRef Validation',
        isModifyEnabled: isModifyEnabled,
        loader: {
            showLoader: showLoader,
            showOverlayLoader: showOverlayLoader
        },
        vgrid: {
            id: 'idPrdXrefValidationCntr',
            rowData: tableData,
            selectedRow: selectedRow,
            tblProps: tableInputProps,
            dimensions: {
                ...tableDimension
                /* height: tableDimension?.height,
                width: tableDimension?.width, */
                // actionColumnWidth: 80
            },
            psearch: {
                // width: 200
                
            }


        }
    }

    return (
        <VirtualizedDataGrid1 {...customProps}/>
    )

    /* return (
		<Container>
            <SnackbarAlert/>
            <ProdXrefValidationContainer {...customProps}/>
            <OverlayLoader loading={showOverlayLoader}/>
        </Container>
    ) */
}

// Main Sales Force Hierarchy Container
const ProdXrefValidationContainer = React.memo(props => {
    const {
        vgrid: {gridWidth}
    } = props;

    return (
        <TableRowContainer style={{width: `${gridWidth}px`}}>
            <TableCaptionBar {...props} />
            <TableGridContainer {...props}/>
            {/* <PopupDialog {...props.vgrid.pdialog}/> */}
        </TableRowContainer>
    )
})

// Table Caption Bar - Title / Search
const TableCaptionBar = React.memo(props => {
    const {
        title,
        vgrid: {rowData, psearch}
    } = props;

    return (
        <StyledCaptionBar>
            <Title>{title} ({(rowData && rowData.length) || 0})</Title>
            <SearchBoxDialog {...psearch}/>
        </StyledCaptionBar>
    )
})

// Table Grid Container
const TableGridContainer = React.memo(props => {
    const {
        id,
        showLoader,
        vgrid: {
            rowData,
            gridWidth,
            gridTotalWidth,
            gridColWidths,
            scrollToIndex,
            tblProps: {options},
            callbacks: {onFilterChange}
        }
    } = props;

    return (
        <StyledPlaceHolderContainerDiv id={id}>
            <StyledPlaceHolderInnerContainerDiv style={{width: `${gridTotalWidth}px`}}>
                <TableColumnContainer {...props} />
                {options?.filtering && 
                    <TableFilterContainer onFilterChange={onFilterChange} gridWidth={gridTotalWidth} gridColWidths={gridColWidths} {...props?.vgrid?.tblProps} />}
                
                <StyledPlaceHolderDiv>
                    <CircularProgressLoader width={gridWidth} loading={showLoader || !rowData}/>
                    <List 
                        height={495}
                        rowCount={rowData?.length || 0}
                        rowHeight={30}
                        width={gridTotalWidth}
                        style={{
                            backgroundColor: getBackgroundColor(),
                            transition: 'background-color 0.2s ease'
                        }}
                        rowRenderer={getRowRender(props)}
                        scrollToIndex={scrollToIndex}
                        overscanRowCount={17}
                        scrollToAlignment={'start'}
                        noRowsRenderer={() => <NoRowsRenderer width={gridWidth}/>}/>
                </StyledPlaceHolderDiv>
            </StyledPlaceHolderInnerContainerDiv>
        </StyledPlaceHolderContainerDiv>
    )
})

// Table column container
const TableColumnContainer = React.memo(props => {
	const {
        vgrid: {
            gridTotalWidth,
            gridColWidths,
            dwcolmenu,
            tblProps: {columns, options},
            psorts: {
                onSortColumn,
                sortColumnOrder: {sortBy, sortOrder}
            },
            callbacks: {onResizeColumn}
        }
    } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const _ACT_COL_WIDTH = gridColWidths[GridCfg._ACT_COL];
    const open = Boolean(anchorEl);
    
    return (
		<StyledColumnContainerDiv role="row" style={{fontWeight: 500}}>
			{columns.map((column, idx) => {
                const gridColWidth = gridColWidths[column.field];
                
                return (
                    column?.visible && 
                        <StyledRowItemDiv role="columnheader" key={`h-${column.field}${idx}`} style={{flex: `0 1 ${gridColWidth}px`}}>
                            {column?.sorting 
                                ? <><StyledColumnSortDiv style={{justifyContent: column.align, textAlign: column.align}} active={sortBy === (column?.sortColumn || column.field)} 
                                        direction={sortOrder} onClick={(e) => onSortColumn(e, {sortCol: column.field, altSortCol: column?.sortColumn})}>{column.label}</StyledColumnSortDiv>
                                        <ColumnResizerRenderer gridWidth={gridTotalWidth} column={column} onResize={onResizeColumn} open={open} setAnchorEl={setAnchorEl}/></>
                                : <><StyledRowItemText style={{justifyContent: column.align, textAlign: column.align}}>{column.label}</StyledRowItemText>
                                        <ColumnResizerRenderer gridWidth={gridTotalWidth} column={column} onResize={onResizeColumn} open={open} setAnchorEl={setAnchorEl}/></>
                            }
                        </StyledRowItemDiv>
				)
			})}
			{options?.action && 
                <StyledRowItemDiv style={{justifyContent: 'center', flex: `0 1 ${_ACT_COL_WIDTH}px`}}>Actions</StyledRowItemDiv>}
            <DropdownColumnMenu open={open} anchorEl={anchorEl} onDpColumnMenuClose={() => setAnchorEl(null)} columns={columns} {...dwcolmenu}/>
		</StyledColumnContainerDiv>
	)
})

// Column resizer placeholder
const ColumnResizerRenderer = React.memo((props) => {
	const {gridWidth, column, onResize, open, setAnchorEl} = props;
    
    return (
		<>
            <IconButton aria-label="more" id={`long-button`} aria-controls={open ? `dwpcolmenu-long` : undefined} aria-haspopup={true} aria-expanded={open ? 'true' : undefined} 
                onClick={(e) => setAnchorEl({target: e.currentTarget, parent: column.field})} style={{padding: 0, borderRadius: '40%', color: 'rgb(0 0 0 / 28%)'}}>
                <MoreVertIcon sx={{width: '0.5em', height: '0.7em'}}/>
            </IconButton>
            
			{column.field && (column?.resize === undefined || !!column?.resize) 
				? <ColumnResizer axis="x" onDrag={(e, {deltaX}) => onResize(e, {gridWidth: gridWidth, dataKey: column.field, deltaX: deltaX})} position={{x: 0}}>
				    <StyledColumnResizeHandler>|</StyledColumnResizeHandler>
				  </ColumnResizer>
				: <StyledColumnResizeDisableDiv>|</StyledColumnResizeDisableDiv>
			}
		</>
	)
})

// Table column filter container
const TableFilterContainer = React.memo(props => {
	const {
        columns,
        options,
        gridColWidths,
        onFilterChange
    } = props;
    const _ACT_COL_WIDTH = gridColWidths[GridCfg._ACT_COL];
    
    return (
        <StyledFilterContainerDiv role="row" style={{fontWeight: 500}}>
            {columns.map((column, idx) => {
                const gridColWidth = gridColWidths[column.field];
                
                return (
                    column?.visible && (column?.filter 
						? <StyledFilterItemDiv key={`f-${column.field}${idx}`} style={{flex: `0 1 ${gridColWidth}px`}}>
                                <StyledFilterInput id={`filter-${column.field}-input`} onKeyUp={onFilterChange} name={column.field} 
									startAdornment={<InputAdornment position="start">
                                        <FilterListOutlinedIcon sx={{width: '0.65em'}}/></InputAdornment>}>
								</StyledFilterInput>
                            </StyledFilterItemDiv>
                        : <StyledFilterItemDiv key={`f-${column.field}${idx}`} style={{flex: `0 1 ${gridColWidth}px`}}/>)
                )
            })}
            {options?.action && 
                <StyledFilterItemDiv style={{flex: `0 1 ${_ACT_COL_WIDTH}px`}}/>}
        </StyledFilterContainerDiv>
	)
})

// Using a higher order function so that we can look up the rows data to retrieve row from within the rowRender function
const getRowRender = (props) => ({index, style, parent: {props: {rowCount}}}) => {
    const {
        isModifyEnabled,
        vgrid: {
            rowData,
            selectedRow,
            gridColWidths,
            callbacks: { onRowActionClick },
            tblProps: { columns, options, actions, mactions }
        }
    } = props;
    const row = rowData[index] || undefined;
	if (!row) {
		return null;
	}
	// Rendering an extra item for the placeholder by increasing data set size to include one 'fake' item
	const patchedStyle = {
		...style,
		left: style.left,
		top: style.top + 2,
		width: style.width,
		height: style.height - 0.5,
        paddingRight: rowCount < 18 ? 10: 0
	};
    const _ACT_COL_WIDTH = gridColWidths[GridCfg._ACT_COL];
    const isRowMatched = (selectedRow['UUID'] === row['UUID']);
    const backgroundColors = isRowMatched ? {color1: ConstCfg.Colors.LIGHT_YELLOW} : undefined;

    return (
        <StyledRowItemContainer role="row" key={`${row.UUID}-${index}`} style={{...getStyle(patchedStyle, backgroundColors)}}>
            {columns?.map(column => {
                const gridColWidth = gridColWidths[column.field];
                const newRowFieldName = `${ConstCfg._FLD_PREFIX}${column.field}`;
				const _textValue = column.format && typeof row[column.field] === 'number' 
                    ? column.format(row[column.field]) 
                        : getMarkdownText(row[column.field]);
                
                return (
                    column?.visible && 
                        <StyledRowItemDiv role="gridcell" key={`${row.UUID}-${column.field}`} 
                            style={{justifyContent: column.align, overflow: 'hidden', flex: `0 1 ${gridColWidth}px`}}>
                                {column?.etdinline ? 
                                    isModifyEnabled ? 
                                        <StyledDropDownExd variant={'standard'} sx={{width: '70%'}}>
                                            <Select labelId="valid-flag-select-label" name={column.field} value={(isRowMatched && selectedRow[newRowFieldName]) || row[column.field]} defaultValue={'N'} 
                                                onChange={e => onRowActionClick(e, {...row, [newRowFieldName]: e.target.value}, mactions.action1)}>
                                                <StyledListItem value="N">N</StyledListItem>
                                                <StyledListItem value="Y">Y</StyledListItem>
                                            </Select>
                                        </StyledDropDownExd>
                                    : <StyledRowItemText style={{textAlign: column.align}} dangerouslySetInnerHTML={_textValue}/>
                                : <StyledRowItemText style={{textAlign: column.align}} dangerouslySetInnerHTML={_textValue}/>}
                        </StyledRowItemDiv>
                )
            })}
            {options.action &&
				<StyledRowItemDiv style={{justifyContent: 'center', width: `${_ACT_COL_WIDTH}px`}}>
				{actions?.map((action, idx) => (
					<span key={`_actions_${idx}`} sx={{flexGrow: 1, padding: '.1em'}}>
                        <IconButton title={action?.title} disabled={!isModifyEnabled} onClick={e => onRowActionClick(e, row, action)}>
                            <action.icon/>
                        </IconButton>
                    </span>
				))}
				</StyledRowItemDiv>
			}
        </StyledRowItemContainer>
    )
}

// No rows found placeholder
const NoRowsRenderer = React.memo(({width}) => {
	return (
		<StyledNoRecordDiv {...(width && {style: {width: `${width}px`}})}>No record(s) found!</StyledNoRecordDiv>
	)
})

// Dropdown menu
const DropdownColumnMenu = React.memo(props => {
    const {
        open,
        columns,
        menuHeight=50,
        onDpColumnMenuClose,
        onChangeColumnMenuItem,
        anchorEl
    } = props;

    return (
        <Menu id={`dwpcolmenu-long`} MenuListProps={{'aria-labelledby': `long-button`}} 
            open={open} onClose={onDpColumnMenuClose} anchorEl={anchorEl?.target} 
            PaperProps={{
                style: {
                    maxHeight: menuHeight * 5,
                    width: 'auto'
                }
            }}>
            <div style={{padding: '5px 20px', fontWeight: 500}}>All Columns</div>
            <Divider sx={{my: 0.5}} />
            {columns.map((item, idx) => (
                <StyledListItem disableRipple key={`dpcolmenu-${item.field}-${idx}`} {...(item.field !== anchorEl?.parent && {onClick: (e) => onChangeColumnMenuItem(e, item)})}>
                    <Checkbox size={'small'} {...(item.field === anchorEl?.parent && {disabled: true})} checked={item?.visible} 
                        onChange={(e) => onChangeColumnMenuItem(e, item)} inputProps={{'aria-label': 'controlled'}}/> {item.label}
                </StyledListItem>
            ))}
        </Menu>
    )
})

// Get drag item style while dragging
const getStyle = (styles, bgColors) => {
	const {color1} = bgColors || {color1: 'inherit'};
	
	return {
		...styles,
		color: 'inherit',
		borderTop: '0 none',
		backgroundColor: color1
	};
}

// Backgroud color
const getBackgroundColor = () => {
	return 'inherit';
};

// Get Mark down text
const getMarkdownText = (value) => {
    return {__html: value};
}
