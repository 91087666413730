import axios from 'axios';
import { SalesCalendar } from '../utils/urls';

// Sales Calendar - End of Month
export const EndOfMonthDaysService = {
    // Get all distributors data
    getAllDistributorsData: async () => {
        let result = await axios.get(SalesCalendar.EndOfMonth.ALL_DISTRIBUTORS_DATA);

        return result;
    },

	// Get all Sales Calendar End Of Month Data
    getEndOfMonthDaysData: async (data) => {
        let result = await axios.get(SalesCalendar.EndOfMonth.ALL_EOM_DAYS_DATA, {
            params: {
                jdeAbNo: data?.jdeAbNo,
                year: data?.year,
                month: data?.month
            }
        });

        return result;
    },

	// Update End of Month Day
	updateEndOfMonthDays: async (rowData) => {
		let result = await axios.put(SalesCalendar.EndOfMonth.UPD_EOM_DAYS_DATA, {
            data: rowData
        });

        return result;
	}
}
