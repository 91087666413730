import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Backdrop } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import styled from '@emotion/styled';


/**
 * Styled components
 */
// Calendar month container div style
const StyledCalendarMonthWeekDiv = styled.div`
	display: flex;
	flex-wrap: nowrap;
    justify-content: center;
`;
// Calendar month item div style
const StyledMonthWeekItemDiv = styled.div`
	display: flex;
	flex-wrap: nowrap;
    width: 7.9%;
    margin: 0 .4em;
    padding: .4em .3em;
    user-select: none;
    justify-content: center;
    border: 1px solid #e0e0e0;
    background-color: #19001f08;
    // &:hover {
    &:hover:not([aria-disabled=true]) {
        cursor: pointer;
        background-color: #9acbfc;
    }
`;
// Table main container
const StyledCalendarContainerDiv = styled.div`
	display: flex;
	flex-wrap: wrap;
    // margin-left: 1%;
    margin-top: 1em;
`;
// Calendar table style
const StyledCalendarTable = styled(Table)`
    // margin: 1%;
    margin: 1.26% 1% 1% 1.26%;
    width: 31%;
    border-radious: 2px;
`;
const StyledCalendarTableHead = styled(TableHead)`
`;
const StyledCalendarTableBody = styled(TableBody)`
`;
const StyledCalendarTableRow = styled(TableRow)`
`;
const StyledCalendarTableCell = styled(TableCell)`
    text-align: center;
    user-select: none;
    padding: .6em;
    border: 1px solid #e0e0e0;
    font-size: inherit;
    &:hover:not([aria-disabled=true]) {
        cursor: pointer;
	    background-color: #9acbfc !important;
    }
`;
// Overlay loader div style
const StyledLoaderDiv = styled.div`
	background-color: #3ea2e5;
	display: flex;
	align-items: center;
	border-radius: 4px;
	padding: 0.8em;
	color: #ffffff;
`;


// Colors defination
const Colors = {
    INHERIT: 'inherit',
    LIGHT_BLUE: '#9acbfc',
    LIGHT_GREY: '#19001f08',
    LEMON_CHIFFON: '#fffacd',
    LIGHT_YELLOW: '#fffbd3ad',
    HOLIDAY_COLOR: '#fa777761'

}

// Calendar types
const CalendarTypes = {
    EndOfMonth: 'EOM',      // End of Month
    NonSellingDay: 'NSD'    // Non Selling Days
}

// US public holidays
/* const USPublicHolidays = [
    {day: '1-1', type: 'New Year'},
    {day: '5-30', type: 'New Year'},
    {day: '7-4', type: 'Independence Day'},
    {day: '12-25', type: 'Christmas'}
] */


// Sales Calendar - End of Month
export const SalesCalendar = (props) => {
    const {
        year: sel_year,
        // month: sel_month,
        calendarDays,
        numberOfMonths,
        weekFormat = 'm', 
        monthFormat = 'f', 
        showLoader = false,
        showNextMonth = false, 
        isCalEventEnabled,
        calType = CalendarTypes.EndOfMonth,
        callbacks: {onCalendarDayClick, onCalendarMonthClick}
    } = props;
    const [calendarData, setCalendarData] = useState([]);
    const [monthSelected, setMonthSelected] = useState(null);
    const [weeksNameSelected, setWeeksNameSelected] = useState([]);
    const [weeksNameSelectedColor, setWeeksNameSelectedColor] = useState({});
    const [selectedMonthWeekDays, setSelectedMonthWeekDays] = useState({});
    const [usPublicHolidays/* , setUsPublicHolidays */] = useState({holiday: [], holiday_d: []});
    // const [isDataOnloaded, setIsDataOnloaded] = useState(false);

    // Weeks array
    const WeekDetailsArr = useMemo(() => [
        {key: 'Sun', label_s: 'S', label_sm: 'Su', label_m: 'Sun', label_f: 'Sunday', value: 1},
        {key: 'Mon', label_s: 'M', label_sm: 'Mo', label_m: 'Mon', label_f: 'Monday', value: 2},
        {key: 'Tue', label_s: 'T', label_sm: 'Tu', label_m: 'Tue', label_f: 'Tuesday', value: 3},
        {key: 'Wed', label_s: 'W', label_sm: 'We', label_m: 'Wed', label_f: 'Wednesday', value: 4},
        {key: 'Thu', label_s: 'T', label_sm: 'Th', label_m: 'Thu', label_f: 'Thursday', value: 5},
        {key: 'Fri', label_s: 'F', label_sm: 'Fr', label_m: 'Fri', label_f: 'Friday', value: 6},
        {key: 'Sat', label_s: 'S', label_sm: 'Sa', label_m: 'Sat', label_f: 'Saturday', value: 0}
    ], []);

    // Months array
    const MonthDetailsArr = useMemo(() => [
        {s: 'Jan', f: 'January'},
        {s: 'Feb', f: 'February'},
        {s: 'Mar', f: 'March'},
        {s: 'Apr', f: 'April'},
        {s: 'May', f: 'May'},
        {s: 'Jun', f: 'June'},
        {s: 'Jul', f: 'July'},
        {s: 'Aug', f: 'August'},
        {s: 'Sep', f: 'September'},
        {s: 'Oct', f: 'October'},
        {s: 'Nov', f: 'November'},
        {s: 'Dec', f: 'December'}
    ], []);

    // Find data from calendar array
    const getRowByDate = useCallback(day => {
        let /* [y, m, d] = date && date.split('-'),
            day = (y && m && d) && `${y}-${('00' + m).slice(-2)}-${d}`, */
            rowData = day && calendarDays?.find(o => o?.DATE1 === day/* o.DATE1.includes(day) */);
        // console.log('$$rowData>>>>', rowData);
        // DLVR_X DIST_FSCL_YR_N CAL_MONTH 

        return rowData;
    }, [calendarDays]);

    // Get US public holidays of a month
    /* const getPublicHolidays = useCallback(year => {
        let usPublicHolidaysOfYear = [],
            usPublicHolidaysOfYearDetails=[];
        USPublicHolidays?.forEach(item => {
            const [month, day] = item.day?.split('-');
            [year, year + 1].forEach(yr => {
                const holi_day = `${yr}-${month}-${day}`,
                    holiDayYear = new Date(yr, (month - 1), day);
                
                usPublicHolidaysOfYear.push(holi_day);
                usPublicHolidaysOfYearDetails.push({
                    ...item,
                    month: (month - 1),
                    day1: holi_day,
                    wday1: holiDayYear.getDay(),
                    wday2: WeekDetailsArr[holiDayYear.getDay()]?.label_f    // holiDayYear.toLocaleDateString('en-US', {weekday: 'long'})
                });
            })
        });

        setUsPublicHolidays({holiday: usPublicHolidaysOfYear, holiday_d: usPublicHolidaysOfYearDetails});
    }, [WeekDetailsArr]); */

    // Get last date of month
    const getLastDateOfMonth = useCallback((month, year=new Date().getFullYear()) => {
        const dateByYearMonth = new Date(year, month + 1, 0),
            lastDateOfMonth = `${dateByYearMonth.getFullYear()}-${dateByYearMonth.getMonth() + 1}-${dateByYearMonth.getDate()}`;
        
        return {
            date1: dateByYearMonth,
            date2: lastDateOfMonth,
            wday3: dateByYearMonth.getDay()
        }
    }, []);

    // Get week days of month
    const getWeekdaysOfMonth = useCallback((days, weekNo) => {
        let weekDaysArr = [],
            weekNoCount = weekNo;
        days?.forEach((day, j) => {
            if (weekNoCount === j) {
                day && weekDaysArr.push(day);
                weekNoCount += 7;
            }
        });

        return weekDaysArr;
    }, []);

    // Get week name selected colors
    const getWeeksNameSelectedColor = useCallback((selectedMonthWeekDaysNew, weeksNameSelectedNew) => {
        let startMonthNo = 0,
            _weeksNameSelected = (weeksNameSelectedNew && [...weeksNameSelectedNew]) || [...weeksNameSelected],
            _weeksNameSelectedColor = {},
            monthNoRange = startMonthNo.range(calendarData.length - 1);
        
        calendarData.forEach((_, i) => {
            const weekNameKeys = Object.keys(selectedMonthWeekDaysNew[i] || {}).map(item => parseInt(item));
            weekNameKeys?.forEach(itm => {
                let weeksNameSelectedCount = 0,
                    weekNameSelectedColor = Colors.LIGHT_YELLOW;
                monthNoRange.forEach(mitm => {
                    if (selectedMonthWeekDaysNew[mitm] && selectedMonthWeekDaysNew[mitm][itm]) {
                        weeksNameSelectedCount++;
                    }
                });
                if (weeksNameSelectedCount === calendarData.length) {
                    weekNameSelectedColor = Colors.LIGHT_BLUE;
                    _weeksNameSelected = [
                        ..._weeksNameSelected,
                        ...[itm]
                    ]
                }
                /* else {
                    console.log('>>>>itm-else', _weeksNameSelected, itm);
                    _weeksNameSelected.splice(_weeksNameSelected?.indexOf(itm), 1);
                    console.log('>>>>itm-else1', _weeksNameSelected);
                } */
                _weeksNameSelectedColor = {
                    ..._weeksNameSelectedColor,
                    [itm]: weekNameSelectedColor
                }
            });
        });
        _weeksNameSelected = [...new Set(_weeksNameSelected)];
        setWeeksNameSelected(_weeksNameSelected);
        setWeeksNameSelectedColor(_weeksNameSelectedColor);

        return _weeksNameSelected;
    }, [calendarData, weeksNameSelected]);

    // Build default selected dates on load
    const getSelectedDateOnLoad = useCallback(() => {
        // console.log('before-getSelectedDateOnLoad-calendarDays>>', isDataOnloaded, calendarDays);
        /* if (calendarDays && isDataOnloaded) {
            return;
        } */

        const startMonth = 0,
            monthCount = (calendarData?.length || 0);
        let selectedMonthWeekDaysNew = {},
            monthRange = startMonth.range(monthCount - 2);
        // console.log('after-getSelectedDateOnLoad-calendarDays>>', isDataOnloaded, calendarDays);
        calendarDays && 
            [sel_year, sel_year + 1].forEach(y => {
                monthRange = (y > sel_year) ? (monthRange.length > 0 ? [12] : []) : monthRange;
                monthRange.forEach(i => {
                    let weekDaysArr = {},
                        _m = i < 12 ? i : 0,
                        yearMonth = `${y}-${_m + 1}-`,
                        rowData = calendarDays?.filter(o => o?.DATE1?.includes(yearMonth));
                    // console.log('#######>', yearMonth, rowData);
                    calendarData[i].days?.forEach((day, j) => {
                        let [y1, m1, d1] = day && day.split('-'),
                            row = day && rowData.find(o => o.DATE1 === day/* o.DATE1.includes(day) */);
                        // console.log('>>', day, row);
                        if (row && (calType === CalendarTypes.EndOfMonth || row.DTE_YN === 'N')) {
                            let _dt = new Date(y1, m1 - 1, d1);
                            weekDaysArr = {
                                ...weekDaysArr,
                                [_dt.getDay()]: [...(weekDaysArr[_dt.getDay()] || []), ...[day]]
                            }
                        }
                    });
                    selectedMonthWeekDaysNew = {
                        ...selectedMonthWeekDaysNew,
                        [i]: {
                            ...selectedMonthWeekDaysNew[i],
                            ...weekDaysArr
                        }
                    }
                })
            })
        selectedMonthWeekDaysNew && 
            setSelectedMonthWeekDays(selectedMonthWeekDaysNew);
        
        // Get week name selected colors
        let startMonthNo = 0,
            _weeksNameSelected = [],
            _weeksNameSelectedColor = {},
            monthNoRange = startMonthNo.range(calendarData.length - 1);
        
        calendarData.forEach((_, i) => {
            const weekNameKeys = Object.keys(selectedMonthWeekDaysNew[i] || {}).map(item => parseInt(item));
            weekNameKeys?.forEach(itm => {
                let weeksNameSelectedCount = 0,
                    weekNameSelectedColor = Colors.LIGHT_YELLOW;
                monthNoRange.forEach(mitm => {
                    if (selectedMonthWeekDaysNew[mitm] && selectedMonthWeekDaysNew[mitm][itm]) {
                        weeksNameSelectedCount++;
                    }
                });
                if (weeksNameSelectedCount === calendarData.length) {
                    weekNameSelectedColor = Colors.LIGHT_BLUE;
                    _weeksNameSelected = [..._weeksNameSelected, ...[itm]]
                }
                else {
                    _weeksNameSelected.splice(_weeksNameSelected?.indexOf(itm), 1);
                }
                _weeksNameSelectedColor = {..._weeksNameSelectedColor, [itm]: weekNameSelectedColor}
            });
        });
        _weeksNameSelected = [...new Set(_weeksNameSelected)];
        setWeeksNameSelected(_weeksNameSelected);
        setWeeksNameSelectedColor(_weeksNameSelectedColor);
        /* calendarDays && 
            setIsDataOnloaded(true); */
    }, [sel_year, calType, /* isDataOnloaded,  */calendarDays, calendarData]);

    // Day click callback
    const onDayClickCallback = useCallback((e, monthNo, day, index) => {
        // Day selected for calendar - 'EOM' - end of month
        props?.calType === CalendarTypes.EndOfMonth 
            && setSelectedMonthWeekDays({[monthNo]: {[index]: [day]}});

        // Days selected for calendar - 'nsd' - Non Selling Days
        if (props?.calType === CalendarTypes.NonSellingDay) {
            let selectedMonthWeekDaysNew = {...selectedMonthWeekDays};
            if (selectedMonthWeekDaysNew[monthNo] 
                && selectedMonthWeekDaysNew[monthNo][index]
                    && selectedMonthWeekDaysNew[monthNo][index].includes(day)) {
                        selectedMonthWeekDaysNew[monthNo][index].splice(
                            selectedMonthWeekDaysNew[monthNo][index]?.indexOf(day), 1
                        );
                        !selectedMonthWeekDaysNew[monthNo][index]?.length 
                            && delete selectedMonthWeekDaysNew[monthNo][index];
            }
            else {
                selectedMonthWeekDaysNew = {
                    ...selectedMonthWeekDaysNew,
                    [monthNo]: {
                        ...selectedMonthWeekDaysNew[monthNo],
                        [index]: [...((selectedMonthWeekDaysNew[monthNo]
                            && selectedMonthWeekDaysNew[monthNo][index]) || []), ...[day]]
                    }
                }
            }
            setSelectedMonthWeekDays(selectedMonthWeekDaysNew);
            // Get week name selected colors
            getWeeksNameSelectedColor(selectedMonthWeekDaysNew);
        }

        // Callbacks if any
        const rowData = getRowByDate(day),
            [s_year, s_month, s_day] = day.split('-'),
            newRowData = [{
                ...rowData,
                N_MONTH: monthNo, 
                N_DAY: day,
                N_DATE: new Date(s_year, (s_month - 1), s_day, 0, 0, 0, 0),
                NT_DATE: {y: (s_year - 0), m: (s_month - 1), d: (s_day - 0)},
                N_WEEK: index,
                N_YEAR: s_year,
                DUI_TYPE: rowData ? 'UPD' : 'INS',
                DLVR_X_NEW: rowData ? (rowData.DTE_YN === 'N' ? 'Y' : 'N') : 'N'
            }];
        onCalendarDayClick 
            && onCalendarDayClick(newRowData);
    }, [props, selectedMonthWeekDays, getWeeksNameSelectedColor, getRowByDate, onCalendarDayClick]);

    // Week Name click callback
    const onWeekNameClickCallback = useCallback((e, weeks, index) => {
        let daysRowData = [],
            weeksNameSelectedNew = [...weeksNameSelected],
            selectedMonthWeekDaysNew = {...selectedMonthWeekDays};
        
        // Set week days selected for a year
        calendarData.forEach((month, i) => {
            if (selectedMonthWeekDaysNew[i] 
                && selectedMonthWeekDaysNew[i][index] 
                    && weeksNameSelectedNew?.includes(index)) {
                        weeksNameSelectedNew.splice(weeksNameSelectedNew.indexOf(index), 1);
                        daysRowData = [...daysRowData, ...selectedMonthWeekDaysNew[i][index]];
                        delete selectedMonthWeekDaysNew[i][index];
            }
            else {
                let weekDaysArr = getWeekdaysOfMonth(month.days, index);
                daysRowData = [...daysRowData, ...weekDaysArr];
                selectedMonthWeekDaysNew = {
                    ...selectedMonthWeekDaysNew,
                    [i]: {
                        ...selectedMonthWeekDaysNew[i],
                        [index]: weekDaysArr
                    }
                }
            }
        });
        setWeeksNameSelected(weeksNameSelectedNew);
        setSelectedMonthWeekDays(selectedMonthWeekDaysNew);

        // Set selected weeks name for a year - have to look
        /* weeksNameSelectedNew = 
            weeksNameSelectedNew.includes(index)
                ? weeksNameSelectedNew.filter(item => item !== index) 
                : [...weeksNameSelectedNew, ...[index]];
        console.log('>weeksNameSelectedNew', weeksNameSelectedNew);
        setWeeksNameSelected(weeksNameSelectedNew); */
        // console.log('>weeksNameSelectedNew-weeksNameSelected', daysRowData, weeksNameSelectedNew, weeksNameSelected);
        
        // Get week name selected colors
        let _weekNameSelectedToProcessDays = getWeeksNameSelectedColor(selectedMonthWeekDaysNew, weeksNameSelectedNew);
        // console.log('>>$$$$', _weekNameSelectedToProcessDays, index);

        /* let _weeksNameSelectedColor = {};
        calendarData.forEach((_, i) => {
            const weekNameKeys = Object.keys(selectedMonthWeekDaysNew[i] || {}).map(item => parseInt(item));
            // console.log('weekNameKeys>', weekNameKeys);
            weekNameKeys.forEach(itm => {
                // console.log('itm>', itm);
                let weeksNameSelectedCount = 0,
                    weekNameSelectedColor = Colors.LIGHT_YELLOW;
                monthNoRange.forEach(mitm => {
                    if (selectedMonthWeekDaysNew[mitm][itm]) {
                        weeksNameSelectedCount++;
                    }
                });
                if (weeksNameSelectedCount === calendarData.length) {
                    weekNameSelectedColor = Colors.LIGHT_BLUE;
                }
                _weeksNameSelectedColor = {
                    ..._weeksNameSelectedColor,
                    [itm]: weekNameSelectedColor
                }
            });
        });
        // console.log('_weeksNameSelectedColor>>', _weeksNameSelectedColor);
        setWeeksNameSelectedColor(_weeksNameSelectedColor); */


        // console.log('>weeksNameSelectedNew>', weeksNameSelectedNew);
        // console.log('>daysRowData', daysRowData, weeksNameSelectedNew, weeksNameSelected, _weekNameSelectedToProcessDays);

        const newRowData = [];
        daysRowData.forEach(day => {
            let rowData = getRowByDate(day);
            const [s_year, s_month, s_day] = day.split('-');
            newRowData.push({
                ...rowData,
                N_MONTH: (s_month - 1), 
                N_DAY: day,
                N_DATE: new Date(s_year, (s_month - 1), s_day),
                N_WEEK: index,
                N_YEAR: s_year,
                DUI_TYPE: rowData ? 'UPD' : 'INS',
                DLVR_X_NEW: _weekNameSelectedToProcessDays.includes(index) ? 'N' : 'Y'
            });
        });

        // console.log('%%%%newRowData>', newRowData);
        // Callbacks if any
        onCalendarDayClick 
            && onCalendarDayClick(newRowData);
    }, [calendarData, weeksNameSelected, selectedMonthWeekDays, onCalendarDayClick, getRowByDate, getWeekdaysOfMonth, getWeeksNameSelectedColor]);

    // Week Header click callback
    const onWeekDayClickCallback = useCallback((e, weeks, index) => {
        const {monthNo, monthDays: {days}} = weeks;
        let daysRowData = [],
            dlvrx_new_state = 'N',
            selectedMonthWeekDaysNew = {...selectedMonthWeekDays};
        if (selectedMonthWeekDaysNew[monthNo] && selectedMonthWeekDaysNew[monthNo][index]) {
            daysRowData = selectedMonthWeekDaysNew[monthNo][index];
            delete selectedMonthWeekDaysNew[monthNo][index];
            dlvrx_new_state = 'Y';
        }
        else {
            let weekDaysArr = getWeekdaysOfMonth(days, index);
            selectedMonthWeekDaysNew = {
                ...selectedMonthWeekDaysNew,
                [monthNo]: {
                    ...selectedMonthWeekDaysNew[monthNo],
                    [index]: weekDaysArr
                }
            }
            daysRowData = selectedMonthWeekDaysNew[monthNo][index];
        }
        setSelectedMonthWeekDays(selectedMonthWeekDaysNew);
        // Get week name selected colors
        getWeeksNameSelectedColor(selectedMonthWeekDaysNew);

        // Callbacks if any
        const newRowData = [];
        daysRowData.forEach(day => {
            const rowData = getRowByDate(day),
                [s_year, s_month, s_day] = day.split('-');
            newRowData.push({
                ...rowData,
                N_MONTH: monthNo, 
                N_DAY: day,
                N_DATE: new Date(s_year, (s_month - 1), s_day),
                N_WEEK: index,
                N_YEAR: s_year,
                DUI_TYPE: rowData ? 'UPD' : 'INS',
                DLVR_X_NEW: dlvrx_new_state /* rowData ? (rowData.DTE_YN === 'N' ? 'Y' : 'N') : 'N' */
            });
        });
        // console.log('here>>>>', newRowData);
        onCalendarDayClick 
            && onCalendarDayClick(newRowData);
    }, [selectedMonthWeekDays, getWeekdaysOfMonth, getWeeksNameSelectedColor, getRowByDate, onCalendarDayClick]);

    // Month click callback
    const onMonthClickCallback = useCallback((e, month, index) => {
        const {calType, year: sel_year} = props;
        const indexNew = (index > 11 ? index - 1 : index);
        setMonthSelected({
            m1: indexNew,
            m2: indexNew + 1
        });
console.log({
    m1: indexNew,
    m2: indexNew + 1
});
        const {date2, wday3} = getLastDateOfMonth(indexNew, sel_year);
        calType === CalendarTypes.EndOfMonth 
            && setSelectedMonthWeekDays({[indexNew]: {[wday3]: [date2]}});
        console.log(index, '-', indexNew, '-', {[indexNew]: {[wday3]: [date2]}});
        onCalendarMonthClick 
            && onCalendarMonthClick({...month, monthIdx: index, monthNo: index + 1, year: sel_year, calType: calType});
    }, [getLastDateOfMonth, onCalendarMonthClick, props]);

    // Create calendar data
    const createAndBuildCalendarData = useCallback(({
            year = new Date().getFullYear(), 
            monthFormat = 'f', 
            showNextMonth = false, 
            numberOfMonths}) => {
        
        let calendarArr = [],
            startIndex = 0,
            endIndex = 12 + (showNextMonth ? 1 : 0);

        if (numberOfMonths) {
			// const currentMonth = new Date().getMonth();
            const currentMonth = new Date(year, 7, 0).getMonth();       // Have to look / check
			startIndex = currentMonth;
			endIndex = currentMonth + numberOfMonths;
		}

        for (let i = startIndex; i < endIndex; i++) {
            const dateArr = [];
			let getWeek = '',
                startDate = new Date(year, i, 1),
                endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);

            while (startDate <= endDate) {
				let date = new Date(startDate);
				!getWeek && (getWeek = date);
				dateArr.push(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`);
				startDate = new Date(date.setDate(date.getDate() + 1));
			}

            let tempArr =
				getWeek.getDay() !== 0
					? getWeek.getDay() - 1 === 0
						? []
						: Array(getWeek.getDay() - 1)
							.join('.')
								.split('.')
					: Array(6).join('.').split('.');

			dateArr.splice(0, 0, ...tempArr);

			let addNElementsToEnd =
				dateArr.length % 7 !== 0
					? Array(7 - (dateArr.length % 7))
						.join('.')
							.split('.')
					: [];
			dateArr.splice(dateArr.length, 0, ...addNElementsToEnd);

			calendarArr.push({
				label: i < 12 ? MonthDetailsArr[i][monthFormat] : MonthDetailsArr[i - 12][monthFormat],
				year: i < 12 ? year : year + 1,
				days: dateArr,
				startDay: getWeek.getDay(),
			});
        }
        setCalendarData(calendarArr);
    }, [MonthDetailsArr]);

    // useEffect hook to set calendar data
    useEffect(() => {
        const {calType, year: sel_year, month: sel_month} = props;

        // Months and days selected on load
        if (calType === CalendarTypes.EndOfMonth) {
            let selectedYearMonth = sel_month ? ((sel_month - 1) > 11 ? (sel_month - 2) : (sel_month - 1)) : new Date().getMonth();
            if (!sel_month && sel_year !== new Date().getFullYear()) {
                selectedYearMonth = 0;
            }
            setMonthSelected({m1: selectedYearMonth, m2: selectedYearMonth + 1});
        }

        // Get US public holidays
        // getPublicHolidays(sel_year);

        // Generate calendar data
        createAndBuildCalendarData(props);
    }, [props, createAndBuildCalendarData, getLastDateOfMonth/* , getPublicHolidays */]);

    // useEffect hook to select non selling days on load
    useEffect(() => {
        // getSelectedDateOnLoad();
    }, [getSelectedDateOnLoad]);

    // console.log('selectedMonthWeekDaysArr>>', selectedMonthWeekDays);
    // console.log('>>>>>>', calendarData, usPublicHolidays);
    // console.log('weeksNameSelectedColor>', weeksNameSelectedColor);

    // Grid properties
	const gridProps = {
        calType: calType,
        showLoader: showLoader,
        calendarDays: calendarDays,
        selectedMonthWeekDays: selectedMonthWeekDays,
        publicHolidays: usPublicHolidays,
        isCalEventEnabled: isCalEventEnabled,
        pMonths: {
            monthFormat: monthFormat,
            monthDetails: MonthDetailsArr,
            monthSelected: monthSelected,
            callbacks: {
                onMonthClick: onMonthClickCallback
            }
        },
        pWeekDays: {
            weekFormat: weekFormat,
            weekDetails: WeekDetailsArr,
            // weekSelected: weekDaySelected,
            // weekNameSelected: weeksNameSelected,
            weeksNameSelectedColor: weeksNameSelectedColor,
            callbacks: {
                onWeekDayClick: onWeekDayClickCallback,
                onWeekNameClick: onWeekNameClickCallback
            }
        },
        pDays: {
            // daysSelected: daysSelected,
            callbacks: {
                onDayClick: onDayClickCallback
            }
        },
        pCalendarData: {
            showNextMonth: showNextMonth,
            numberOfMonths: numberOfMonths,
            calendarData: calendarData
        }
    };

    return (
        <>
            <CalendarMonthWeekContainer {...gridProps}/>
            <CalendarItemsContainer {...gridProps}/>
        </>
    )
}


// Calendar month/week container
const CalendarMonthWeekContainer = React.memo(props => {
	const {
        calType,
        pMonths,
        pWeekDays,
        isCalEventEnabled
    } = props;

	return (
        <>
            {calType === CalendarTypes.EndOfMonth && 
                <CalendarMonthContainer isCalEventEnabled={isCalEventEnabled} {...pMonths}/>}
            
            {calType === CalendarTypes.NonSellingDay && 
                <CalendarWeekContainer isCalEventEnabled={isCalEventEnabled} {...pWeekDays}/>}
        </>
    )
});

// Calendar month container
const CalendarMonthContainer = React.memo(props => {
	const {
        monthFormat, 
        monthDetails, 
        monthSelected, 
        isCalEventEnabled,
        callbacks: {onMonthClick}
    } = props;

	return (
        <StyledCalendarMonthWeekDiv>
            {monthDetails.map((month, i) => (
                <StyledMonthWeekItemDiv 
                    aria-disabled={!isCalEventEnabled} 
                    {...(monthSelected?.m1 === i && {style: {backgroundColor: Colors.LIGHT_BLUE}})} 
                    {...(isCalEventEnabled && {onClick: (e) => onMonthClick(e, month, i)})} key={`${month}-${i}`}>
                        {month[monthFormat]}
                </StyledMonthWeekItemDiv>
            ))}
        </StyledCalendarMonthWeekDiv>
    )
});

// Calendar week container
const CalendarWeekContainer = React.memo(props => {
	const {
        weekDetails,
        // weekNameSelected,
        isCalEventEnabled,
        weeksNameSelectedColor,
        callbacks: {onWeekNameClick}
    } = props;
    // console.log('$>>>weeksNameSelectedColor', weekNameSelected, weeksNameSelectedColor);

	return (
        <StyledCalendarMonthWeekDiv>
            {weekDetails.map((day, i) => (
                <StyledMonthWeekItemDiv 
                    aria-disabled={!isCalEventEnabled} 
                    style={{width: '13.4%', backgroundColor: weeksNameSelectedColor[i]/* weekNameSelected.includes(i) && Colors.LIGHT_BLUE */}}
                    key={`${day.key}-${i}`} {...(isCalEventEnabled && {onClick: (e) => onWeekNameClick(e, day, i)})}>
                        {day.label_f}
                </StyledMonthWeekItemDiv>
            ))}
        </StyledCalendarMonthWeekDiv>
    )
});

// Calendar week container
const CalendarWeekHeaderContainer = React.memo(props => {
	const {
        calType,
        monthNo,
        weekFormat,
        weekDetails,
        // weekSelected,
        daysInMonth,
        selectedWeekDays,
        isCalEventEnabled,
        callbacks: {onWeekDayClick}
    } = props;
    const weekNameKeys = Object.keys(selectedWeekDays).map(item => parseInt(item));
    
	return (
        <StyledCalendarTableRow>
            {weekDetails.map((day, i) => (
                <StyledCalendarTableCell key={`${day.key}-${i}`} 
                    aria-disabled={!isCalEventEnabled || calType === CalendarTypes.EndOfMonth} 
                    {...(weekNameKeys.includes(i)  && {style: {backgroundColor: Colors.LEMON_CHIFFON}})} 
                    {...(isCalEventEnabled && calType !== CalendarTypes.EndOfMonth && {onClick: (e) => onWeekDayClick(e, {...day, monthNo: monthNo, monthDays: daysInMonth}, i)})}>
                        {day[`label_${weekFormat}`]}
                </StyledCalendarTableCell>
            ))}
        </StyledCalendarTableRow>
    )
});

// Calendar days container
const CalendarDaysContainer = React.memo(props => {
	const {
        calDays,
        calType,
        monthNo,
        // daysSelected,
        monthSelected,
        selectedWeekDays,
        isCalEventEnabled,
        publicHolidays: {holiday: public_holiday},
        callbacks: {onDayClick}
    } = props;
    const monthSelectedArr = Object.values(monthSelected || {});
    
	return (
        <>
            {calDays.map((day, i) => {
                const selectedWeekDaysArr = Object.values(selectedWeekDays[i] || []),
                    isDayClickEnabled = monthSelectedArr.includes(monthNo),
                    isPublicHoliday = public_holiday?.includes(day),
                    isWeekDaySelected = selectedWeekDaysArr?.includes(day);
                /* const dayCellColor = (day && daysSelected && daysSelected.includes(day) 
                    ? Colors.LIGHT_BLUE 
                    : [0, 6].includes(i % 7) 
                        ? Colors.LIGHT_GREY
                        : ''); */
                const dayCellColor = (day && (isPublicHoliday || isWeekDaySelected) 
                    ? isWeekDaySelected 
                        ? Colors.LIGHT_BLUE 
                        : Colors.HOLIDAY_COLOR
                    : [0, 6].includes(i % 7)
                        ? Colors.LIGHT_GREY
                        : '');
                        
                return (
                    <StyledCalendarTableCell key={`${day}-${i}-table-cell`} 
                        aria-disabled={!day || !isCalEventEnabled || (!isDayClickEnabled && calType === CalendarTypes.EndOfMonth)} 
                        {...(dayCellColor && {style: {backgroundColor: dayCellColor}})} 
                        {...(day && isCalEventEnabled && (isDayClickEnabled || calType === CalendarTypes.NonSellingDay) && {onClick: (e) => onDayClick(e, monthNo, day, i)})}>
                            {day.split('-')[2]}
                    </StyledCalendarTableCell>
                );
            })}
        </>
    )
});

// Calendar container
const CalendarItemsContainer = React.memo(props => {
	const {
        pDays,
        calType,
        pWeekDays,
        showLoader,
        publicHolidays,
        isCalEventEnabled,
        selectedMonthWeekDays,
        pMonths: {
            monthDetails,
            monthSelected,
            callbacks: {onMonthClick}
        },
        pCalendarData: {calendarData}
    } = props;

	return (
        <StyledCalendarContainerDiv>
            {calendarData.map((month, i) => {
                let selectedWeekDays = selectedMonthWeekDays[i] || {};

                return (
                    <StyledCalendarTable {...([monthSelected?.m1, monthSelected?.m2].includes(i) && {style: {backgroundColor: '#a2eaff70'}})} key={`${month.label}-${i}-table`}>
                        <StyledCalendarTableHead>
                            <StyledCalendarTableRow>
                                <StyledCalendarTableCell aria-disabled={!isCalEventEnabled || calType === CalendarTypes.NonSellingDay} style={{backgroundColor: Colors.LIGHT_GREY}} colSpan={7} 
                                    {...((isCalEventEnabled && calType === CalendarTypes.EndOfMonth) && {onClick: (e) => onMonthClick(e, monthDetails[i > 11 ? 0 : i], i)})}>
                                        {`${month['label']} - ${month.year}`}
                                </StyledCalendarTableCell>
                            </StyledCalendarTableRow>
                            <CalendarWeekHeaderContainer isCalEventEnabled={isCalEventEnabled} selectedWeekDays={selectedWeekDays} calType={calType} monthNo={i} daysInMonth={month} {...pWeekDays}/>
                        </StyledCalendarTableHead>

                        <StyledCalendarTableBody>
                        {month.days
                            // eslint-disable-next-line no-sequences
                            .reduce((accumulator, currentValue, index) => ((index % 7 ? accumulator[accumulator.length - 1].push(currentValue) : accumulator.push([currentValue])), accumulator), [])
                            .map((days, j) => {
                                return (
                                    <StyledCalendarTableRow key={`${month.label}-${i}-${j}-table-row`}>
                                        <CalendarDaysContainer {...pDays} isCalEventEnabled={isCalEventEnabled} publicHolidays={publicHolidays} selectedWeekDays={selectedWeekDays} calType={calType} monthNo={i} monthSelected={monthSelected} calDays={days}/>
                                    </StyledCalendarTableRow>
                                )
                            })
                        }
                        </StyledCalendarTableBody>
                    </StyledCalendarTable>
                )
            })}
			<OverlayLoader loading={showLoader}/>
        </StyledCalendarContainerDiv>
    )
});

// Overlay loader
const OverlayLoader = React.memo(props => {
	const {loading} = props;
	
	return (
		<Backdrop open={loading} sx={{color: '#0e5ead', backgroundColor: 'rgb(0 0 0 / 2%)', zIndex: (theme) => theme.zIndex.drawer + 1}}>
			<StyledLoaderDiv>
				<CircularProgress size={30} color="inherit"/>
				<span style={{fontSize: '0.9rem', marginLeft: '0.8em'}}>Please wait...</span>
			</StyledLoaderDiv>
		</Backdrop>
	)
})