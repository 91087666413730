import React, { useCallback, useEffect, /* useMemo,  */useState } from 'react';
import { useWindowDimensions } from '../hooks/hook.window.dimensions';
import { Container, RowItemContainer, StyledCaptionBar, StyledColumnContainerDiv, StyledColumnResizeDisableDiv, StyledColumnResizeHandler, StyledColumnSortDiv, 
    StyledDropDown, StyledNoRecordDiv, StyledPlaceHolderDiv, StyledRowItemDiv, StyledRowItemText, TableRowContainer, Title } from '../themes/style';
import { Autocomplete, IconButton, TextField } from '@mui/material';
import { List } from 'react-virtualized';
import { env } from '../utils/env.variables';
import { SalesforceRepNameService } from '../services/service.salesforce.rep.names';
import { ConstCfg, GridCfg, SnackbarCfg } from '../utils/utils';
import { OverlayLoader, SnackbarAlert } from '../components/component.snackbar.alert';
// import LoadingButton from '@mui/lab/LoadingButton';
// import CloseIcon from '@mui/icons-material/Close';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
// import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import * as ColumnResizer  from 'react-draggable';
import { PopupDialog } from '../components/component.popup.dialog';


// Sales Force Hierarchy - Sales Rep Names
export const SalesForceRepNames = (props) => {
    const {dimensions: tableDimension/* leftDivRef */, graphData: userInfo} = props;
    const {isUserCanModify: isModifyEnabled} = userInfo;
	/* const {width} = useWindowDimensions();
    const _width = ((width - (leftDivRef?.current?.clientWidth || 182)) - 34);
    const _height = ((leftDivRef?.current?.clientHeight || 500) - 170);
    const tableDimension = {width: _width, height: _height}; */
    const [showLoader, setShowLoader] = useState(false);
    /* const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState({
        severity: SnackbarCfg.severity_level.info, 
        message: ''
    }); */
    const [showSnackbar, setShowSnackbar] = useState();
	const [rowData, setRowData] = useState([]);
    const [jdeAddrBookNo, setJdeAddrBookNo] = useState();
    const [allDistributorsData, setAllDistributorsData] = useState();
    const [sortColumns, setSortColumns] = useState({
		sortBy: '',
		sortOrder: 'asc'
	});
    const [showDialog, setShowDialog] = useState(false);
	const [dialogType, setDialogType] = useState({
        icon: EditOutlinedIcon,
        action: (e, d) => {}
    });
    // const [columnWidths, setColumnWidths] = useState({_ACT_COL: ConstCfg.Widths.ACTION_COLUMN / 1000});
    const [columnWidths, setColumnWidths] = useState({});


    // Table grid input properties - columns/options/actions etc.
    const tableInputProps = {
        // Table columns
        columns: [
            {label: 'Sales Route Number', field: 'SLS_RTE_N', align: 'center', width: 200, filter: true, sorting: true},
            {label: 'Sales Representative Name', field: 'SLS_REP_M', width: 600, filter: true, sorting: true}
        ],

        // Table options
        options: {
            action: true,
            filtering: true
        },

        // Table actions
        actions: [
            {
                // Edit salesforce rep names data
                title: 'Edit',
                icon: EditOutlinedIcon,
                onClick: function(e, result) {
                    if (!isModifyEnabled) {
                        return false;
                    }
                    
                    // Open dialog to confirm deletion
                    setDialogType({
                        type: 'E',
                        title: this.title,
                        icon: SaveIcon,
                        actionText: 'Update',
                        rowItem: result?.rowItem,
                        action: (e, newData) => {
                            // Get Sales Rep data based on distributor
                            (async () => {
                                await updateSfRepresentativeName({...newData, usrid: userInfo?.upn});
                            })();
                        }
                    });
                    setShowDialog(true);
                }
            },
            /* {
                // Delete salesforce rep names data
                title: 'Delete',
                icon: DeleteOutlinedIcon,
                onClick: function(e, result) {
                    if (!isModifyEnabled) {
                        return false;
                    }
                    console.log('>action.delete>', result);
                    console.log('xxx>', this);
                    
                    // Open dialog to confirm deletion
                    setDialogType({
                        type: 'D',
                        title: this.title,
                        icon: this.icon,
                        actionText: 'Confirm',
                        rowItem: result?.rowItem,
                        action: (e, newData) => {
                            console.log('xxxx>>>>', newData);
                            setShowDialog(false);
                            // deleteHistoricalRejectedData(e, {...rowData, usrid: userInfo.upn});
                        }
                    });
                    setShowDialog(true);
                }
            } */
        ]
    }

    // Set state callback for snackbar
    /* SnackbarCfg.setStateFns({
        stateMessage: setSnackbarMessage,
        stateOpen: setOpenSnackbar
    });
    GridCfg.setStateFns({
        gridDim: tableDimension,
        columns: tableInputProps.columns,
        stateRowData: setRowData,
        stateColWidths: setColumnWidths,
        stateSortColumns: setSortColumns
    }); */

    // Snackbar close callback
    const onSnackbarCloseCallback = useCallback(() => {
        // SnackbarCfg.close();
        console.log('>onSnackbarCloseCallback>', SnackbarCfg.open);
        setShowSnackbar(SnackbarCfg.open);
    }, [])

    // Update Salesforce Representative Name Data
    const updateSfRepresentativeName = async (props) => {
        await SalesforceRepNameService.updateSfRepresentativeName(props)
            .then(response => {
                console.log('RES>>>>>>>', response);
                const {status, rows} = response.data;
                
                if (status.isOk) {
                    setRowData(rows || []);
                }
                SnackbarCfg
                    .setSeverity(status.isOk ? SnackbarCfg.severities.success : SnackbarCfg.severities.error)
                        .setMessage(status.isOk ? env.getEnv('REACT_APP_MSG_UPDATE_SUCCESS') : status?.message)
                            .show();
                /* SnackbarCfg.setMessage(status.isOk ? env.getEnv('REACT_APP_MSG_UPDATE_SUCCESS') : status?.message, true, 
                    status.isOk ? SnackbarCfg.severity_level.success : SnackbarCfg.severity_level.error); */
                
                /* SnackbarCfg.setMessage({
                    message: status.isOk ? env.getEnv('REACT_APP_MSG_UPDATE_SUCCESS') : status?.message,
                    severity: status.isOk ? SnackbarCfg.severity_level.success : SnackbarCfg.severity_level.error
                }); */
            })
            .catch(error => {
                SnackbarCfg
                    .setSeverity(SnackbarCfg.severities.error)
                        .setMessage((error?.response?.data?.message || error?.message))
                            .show();
                // SnackbarCfg.setMessage((error?.response?.data?.message || error?.message), true, SnackbarCfg.severity_level.error);

                /* SnackbarCfg.setMessage({
                    message: (error?.response?.data?.message || error?.message),
                    severity: SnackbarCfg.severity_level.error
                }); */
            })
            .finally(() => {
                setShowDialog(false);
            });
    }

    // Grid item row action click functionality
	const onRowActionClickCallback = useCallback((e, rowItem, rowAction) => {
		if (!isModifyEnabled) {
			return false;
		}
        
        console.log('>rowaction.click>', rowItem, rowAction);
		rowAction?.onClick && 
            rowAction?.onClick(e, {rowItem: rowItem});
    }, [isModifyEnabled]);

	// Distributor Change Callback defination
    const onDistributorChangeCallback = useCallback((e, dwValue) => {
        console.log('onDistributorChangeCallback > ', dwValue);
        setJdeAddrBookNo(dwValue);

        // Get Sales Rep data based on distributor
        (async () => {
            setShowLoader(true);
            await SalesforceRepNameService.getSfRepNamesData({jdeAbNo: dwValue?.id})
                .then(response => {
                    const {status, rows} = response.data;
                    setRowData(rows || []);
                    if (!status.isOk) {
                        SnackbarCfg
                            .setSeverity(SnackbarCfg.severities.error)
                                .setMessage(env.getEnv('REACT_APP_MSG_RETRIEVE_FAILED'))
                                    .show();
                        // SnackbarCfg.setMessage(env.getEnv('REACT_APP_MSG_RETRIEVE_FAILED'), true, SnackbarCfg.severity_level.error);
                        /* SnackbarCfg.setMessage({
                            message: env.getEnv('REACT_APP_MSG_RETRIEVE_FAILED'),
                            severity: SnackbarCfg.severity_level.error
                        }); */
                    }
                })
                .catch(error => {
                    SnackbarCfg
                        .setSeverity(SnackbarCfg.severities.error)
                            .setMessage((error?.response?.data?.message || error?.message))
                                .show();
                    // SnackbarCfg.setMessage((error?.response?.data?.message || error?.message), true, SnackbarCfg.severity_level.error);
                    /* SnackbarCfg.setMessage({
                        message: (error?.response?.data?.message || error?.message),
                        severity: SnackbarCfg.severity_level.error
                    }); */
                })
                .finally(() => {
                    setShowLoader(false);
                });
        })();
    }, []);

    // Sort Column callback
    const onSortCallback = useCallback((e, props) => {
        GridCfg.fnSortColumn(e, {...props, sortColumns: sortColumns});
    }, [sortColumns]);

    // Column resize callback
    const onResizeCallback = useCallback((e, props) => {
        GridCfg.fnResizeColumn(e, props);
    }, []);

    // Popup Dialog close callback
    const onDialogCloseCallback = useCallback(e => {
        console.log('onDialogCloseCallback', e);
        setShowDialog(false);
    }, []);

    // userEffect hook to set column widths while resizing
    useEffect(() => {
        // Set column widths
        if (!columnWidths?._ACT_COL) {
            const get_calculated_col_widths = GridCfg.fnSetGridColumnWidths(tableInputProps.columns);
            setColumnWidths(get_calculated_col_widths);
        }
console.log('here1');
        // Column width
		/* tableInputProps.columns.forEach(item => {
			setColumnWidths(prevState => ({
				...prevState,
				[item.field]: item?.width/1000 || 0.01
			}));
		}); */
        setShowSnackbar(SnackbarCfg.open);
        console.log('>>>xxxxxxx>>>SnackbarCfg.open', SnackbarCfg.open);
    }, [columnWidths, tableInputProps.columns]);

	// userEffect hook to load data if changes
    useEffect(() => {
        // Get distributors data
        (async () => {
            setShowLoader(true);
            await SalesforceRepNameService.getAllDistributorsData()
                .then(response => {
                    const {status, rows} = response.data;
                    setAllDistributorsData(rows || []);
                    if (!status.isOk) {
                        SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                            .setMessage(env.getEnv('REACT_APP_MSG_RETRIEVE_FAILED')).show();
                        // SnackbarCfg.setMessage(env.getEnv('REACT_APP_MSG_RETRIEVE_FAILED'), true, SnackbarCfg.severity_level.error);
                        /* SnackbarCfg.setMessage({
                            message: env.getEnv('REACT_APP_MSG_RETRIEVE_FAILED'),
                            severity: SnackbarCfg.severity_level.error
                        }); */
                    }
                })
                .catch(error => {
                    SnackbarCfg.setSeverity(SnackbarCfg.severities.error)
                        .setMessage((error?.response?.data?.message || error?.message)).show();
                    // SnackbarCfg.setMessage((error?.response?.data?.message || error?.message), true, SnackbarCfg.severity_level.error);
                    /* SnackbarCfg.setMessage({
                        message: (error?.response?.data?.message || error?.message),
                        severity: SnackbarCfg.severity_level.error
                    }); */
                })
                .finally(() => {
                    setShowLoader(false);
                });
        })();

        // console.log('openSnackbar>>', openSnackbar);
    }, []);
    

	// Customized Properties
	const customProps = {
		title: 'Sales Force Hierarchy - Sales Rep Names',
		jdeAbNo: jdeAddrBookNo,
		allDistributors: allDistributorsData,
        showLoader: showLoader,
        rowData: rowData,
        tblInputProps: tableInputProps,
        columnWidths: columnWidths,
        isModifyEnabled: isModifyEnabled,
        vgrid: {
            dimensions: tableDimension,
            scrollToIndex: 0,
            sorts: {
                sortColumns: sortColumns,
                onSort: onSortCallback
            },
            onResize: onResizeCallback,
            onRowActionClick: onRowActionClickCallback
        },
		callbacks: {
			onDistributorChange: onDistributorChangeCallback
		},
        snackbar: {
			isOpen: showSnackbar,
            onSnackbarClose: onSnackbarCloseCallback
        },/* 
        snackbar: {
			isOpen: openSnackbar,
			messages: snackbarMessage
		}, */
        pdialog: {
            title: 'Sales Representative',
            showDialog: showDialog,
            dialogType: dialogType,
            callbacks: {
                onDialogClose: onDialogCloseCallback
            }
        }
	}

	return (
		<Container>
			<SfHierarchyContainer {...customProps}/>
			<SnackbarAlert {...customProps.snackbar}/>
        </Container>
	)
}


// Main Sales Force Hierarchy Container
const SfHierarchyContainer = React.memo(props => {
    return (
        <TableRowContainer>
            <TableCaptionBar {...props} />
            <TableColumnContainer {...props} />
            <SfHierarchyPlaceHolder {...props} />
            <PopupDialog {...props.pdialog}/>
        </TableRowContainer>
    )
})

// Table Caption Bar - Title / Search
const TableCaptionBar = React.memo(props => {
    const {
        title,
        jdeAbNo,
        allDistributors,
        callbacks: {onDistributorChange}
    } = props;

    return (
        <StyledCaptionBar>
            <Title>{title}</Title>

            <StyledDropDown variant="standard" style={{marginTop: '-1rem', minWidth: '28%'}}>
                <Autocomplete 
                    options={(allDistributors || []).map(el => ({
                        id: el.JDE_ADDR_BOOK_N,
                        label: `${el.DIST_DESC} (${el.JDE_ADDR_BOOK_N})`
                    }))}
                    id="dist-select-label" value={jdeAbNo || ''}
                    onChange={onDistributorChange}
                    isOptionEqualToValue={(o, v) => (v === undefined || v === '' || o?.id === v?.id || v.id === '')}
                    renderInput={(params) => <TextField {...params} label="Distributors" margin="normal" variant="standard"/>}/>
            </StyledDropDown>
        </StyledCaptionBar>
    )
})

// Table column container
const TableColumnContainer = React.memo(props => {
	const {
        columnWidths,
        vgrid: {
            onResize,
            dimensions: {width: gridWidth},
            sorts: {
                onSort,
                sortColumns: {sortBy, sortOrder}
            }
        },
        tblInputProps: {columns}
    } = props;
    
    // const listGridContainer = document.querySelectorAll('div.ReactVirtualized__Grid__innerScrollContainer')[0] || null;
	// const scrollBarWidth = (gridWidth - (listGridContainer?.clientWidth || (gridWidth - ConstCfg.Widths.SCROLL_BAR)));
    // console.log('xxx', scrollBarWidth);

	return (
		<StyledColumnContainerDiv role="row" style={{fontWeight: 500, /* paddingRight: `${ConstCfg.Widths.SCROLL_BAR}px`,  */width: `${gridWidth}px`}}>
			{columns.map((column, idx) => {
				const columnWidth = (columnWidths[column.field] || 0.01) * gridWidth;

				return (
                    <StyledRowItemDiv role="columnheader" key={`h-${column.field}${idx}`} style={{flex: `0 1 ${columnWidth}px`}}>
                        {column?.sorting 
							? <><StyledColumnSortDiv style={{justifyContent: column.align, textAlign: column.align}} active={sortBy === (column?.sortColumn || column.field)} direction={sortOrder} onClick={(e) => onSort(e, column.field, column?.sortColumn)}>{column.label}</StyledColumnSortDiv>
									<ColumnResizerRenderer column={column} onResize={onResize}/></>
							: <><StyledRowItemText style={{justifyContent: column.align, textAlign: column.align}}>{column.label}</StyledRowItemText>
									<ColumnResizerRenderer column={column} onResize={onResize}/></>
						}
                    </StyledRowItemDiv>
				)
			})}
			<StyledRowItemDiv style={{justifyContent: 'center', flex: '0 1 9.4em'}}>Actions</StyledRowItemDiv>
		</StyledColumnContainerDiv>
	)
})

// Column resizer placeholder
const ColumnResizerRenderer = React.memo((props) => {
	const {column, onResize} = props;

	return (
		<>
			{column.field && (column?.resize === undefined || !!column?.resize) 
				? <ColumnResizer axis="x" onDrag={(e, { deltaX }) => onResize(e, {dataKey: column.field, deltaX: deltaX})} position={{ x: 0 }}>
				    <StyledColumnResizeHandler>|</StyledColumnResizeHandler>
				  </ColumnResizer>
				: <StyledColumnResizeDisableDiv>|</StyledColumnResizeDisableDiv>
			}
		</>
	)
})

// Sales Force Hierarchy Place Holder
const SfHierarchyPlaceHolder = React.memo(props => {
    const {
        rowData,
        showLoader,
        vgrid: {
            scrollToIndex,
            dimensions: {height, width}
        }
    } = props;

    return (
        <StyledPlaceHolderDiv style={{width: `${width}px`}}>
            <List
                height={height}
                rowCount={rowData?.length || 0}
                rowHeight={35}
                width={width}
                style={{
                    backgroundColor: getBackgroundColor(),
                    transition: 'background-color 0.2s ease'
                }}
                rowRenderer={getRowRender(props)}
                scrollToIndex={scrollToIndex}
                overscanRowCount={10}
                scrollToAlignment={'start'}
                noRowsRenderer={() => <NoRowsRenderer/>}/>
            
            {/* <CircularProgressLoader loading={!rowData}/> */}
            <OverlayLoader loading={showLoader || !rowData}/>
        </StyledPlaceHolderDiv>
	)
})

// No rows found placeholder
const NoRowsRenderer = React.memo(() => {
	return (
		<StyledNoRecordDiv>No record(s) found!</StyledNoRecordDiv>
	)
})

// Using a higher order function so that we can look up the rows data to retrieve row from within the rowRender function
const getRowRender = (props) => ({index, style}) => {
    const {
        rowData,
        columnWidths,
        isModifyEnabled,
        vgrid: {
            onRowActionClick,
            dimensions: {width: gridWidth}
        },
        tblInputProps: {columns, options, actions}
    } = props;
    const row = rowData[index] || undefined;
	if (!row) {
		return null;
	}
	// Rendering an extra item for the placeholder by increasing data set size to include one 'fake' item
	const patchedStyle = {
		...style,
		left: style.left,
		top: style.top + 2,
		width: style.width,
		height: style.height - 0.5
	};

	return (
        <RowItemContainer role="row" key={`${row.UUID}-${index}`} style={{...getStyle(patchedStyle)}}>
            {columns?.map(column => {
				const columnWidth = (columnWidths[column.field] || 0.01) * gridWidth;
				const _textValue = column.format && typeof row[column.field] === 'number' 
                    ? column.format(row[column.field]) 
                        : getMarkdownText(row[column.field]);

				return (
                    <StyledRowItemDiv role="gridcell" /* onClick={e => rowClick(e, row, index)} */ key={`${row.UUID}-${column.field}`} 
						style={{justifyContent: column.align, overflow: 'hidden', flex: `0 1 ${columnWidth}px`}}>
							<StyledRowItemText style={{textAlign: column.align}} dangerouslySetInnerHTML={_textValue}/>
					</StyledRowItemDiv>
                )
            })}
            {options.action &&
				<StyledRowItemDiv style={{justifyContent: 'center', width: `calc(10.6em - ${ConstCfg.Widths.SCROLL_BAR}px)`}}>
				{actions?.map((action, idx) => (
					<span key={`_actions_${idx}`} sx={{flexGrow: 1, padding: '.1em'}}><IconButton title={action?.title} disabled={!isModifyEnabled} 
                        onClick={e => onRowActionClick(e, row, action)} /* sx={{flexGrow: 1, padding: '.1em'}} */>
                        <action.icon/>
                    </IconButton></span>
				))}
				</StyledRowItemDiv>
			}
        </RowItemContainer>
	);
}

// Get drag item style while dragging
const getStyle = (styles, bgColors) => {
	const {color1} = bgColors || {color1: 'inherit'};
	
	return {
		...styles,
		color: 'inherit',
		borderTop: '0 none',
		backgroundColor: color1
	};
}

// Backgroud color
const getBackgroundColor = () => {
	return 'inherit';
};

// Get Mark down text
const getMarkdownText = (value) => {
    return {__html: value};
}
