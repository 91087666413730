import { useState, useEffect, useCallback } from 'react';


// useWindowDimensions hook
export const useWindowDimensions = () => {
    const hasWindow = typeof window !== 'undefined';

    const getWindowDimensions = useCallback((e) => {
        const width = hasWindow ? window.innerWidth : 0;
        const height = hasWindow ? window.innerHeight : 0;

        return {width, height};
    }, [hasWindow]);
    
    // Get window dimension on call
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    // useEffect to get/set window dimension
    useEffect(() => {
        if (hasWindow) {
            const handleWindowResize = () => {
                setWindowDimensions(getWindowDimensions());
            }

            window.addEventListener('resize', handleWindowResize);
            return () => window.removeEventListener('resize', handleWindowResize);
        }
    }, [hasWindow, getWindowDimensions]);

    return windowDimensions;
}