import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useWindowDimensions } from '../hooks/hook.window.dimensions';
import { Container, StyledCaptionBar, StyledColumnContainerDiv, StyledColumnResizeDisableDiv, StyledColumnResizeHandler, StyledColumnSortDiv, 
    StyledDropDown, StyledNoRecordDiv, StyledPlaceHolderDiv, StyledRowItemDiv, StyledRowItemText, TableRowContainer, Title } from '../themes/style';
import { Autocomplete, Table, TableBody, TableCell, TableRow, TextField } from '@mui/material';
import { List } from 'react-virtualized';
import { env } from '../utils/env.variables';
import { SalesforceHierarchyService } from '../services/service.salesforce.hierarchy';
import { ConstCfg, GridCfg, SnackbarCfg } from '../utils/utils';
import { OverlayLoader, SnackbarAlert } from '../components/component.snackbar.alert';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined';
import * as ColumnResizer  from 'react-draggable';
import { SfTreeView } from '../components/component.tree.views';


const data = {
    id: 'root',
    name: 'Parent',
    children: [
      {
        id: '1',
        name: 'Child - 1',
      },
      {
        id: '2',
        name: 'Child - 2',
        children: [
          {
            id: '5',
            name: 'Child - 5',
          },
          {
              id: '6',
              name: 'Child - 6',
              children: [
                {
                  id: '7',
                  name: 'Child - 7',
                  children: [
                    {
                      id: '8',
                      name: 'Child - 8',
                    },
                  ],
                },
              ],
            },
        ],
      },
      {
          id: '3',
          name: 'Child - 3',
          children: [
            {
              id: '4',
              name: 'Child - 4',
            },
          ],
        },
    ],
  };


// Sales Force Hierarchy
export const SalesForceHierarchy = (props) => {
    const {dimensions: tableDimension/* leftDivRef */, graphData: userInfo} = props;
    const {isUserCanModify: isModifyEnabled} = userInfo;
	/* const {width} = useWindowDimensions();
    const _width = ((width - (leftDivRef?.current?.clientWidth || 182)) - 34);
    const _height = ((leftDivRef?.current?.clientHeight || 500) - 170);
    const tableDimension = {width: _width, height: _height}; */
    const [showLoader, setShowLoader] = useState(false);
    /* const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState({
        severity: SnackbarCfg.severity_level.info, 
        message: ''
    }); */
	const [rowData, setRowData] = useState([]);
    const [rowData1, setRowData1] = useState([]);
    const [jdeAddrBookNo, setJdeAddrBookNo] = useState();
    const [allDistributorsData, setAllDistributorsData] = useState();
    const [sortColumns, setSortColumns] = useState({
		sortBy: '',
		sortOrder: 'asc'
	});
    const [showDialog, setShowDialog] = useState(false);
	const [dialogType, setDialogType] = useState({
        icon: AssignmentTurnedInOutlinedIcon,
        action: (e, d) => {}
    });
    const [columnWidths, setColumnWidths] = useState({_ACT_COL: ConstCfg.Widths.ACTION_COLUMN / 1000});
    const [columnWidths1, setColumnWidths1] = useState({_ACT_COL: ConstCfg.Widths.ACTION_COLUMN / 1000});

    

    // Table grid input properties - columns/options/actions etc.
    const tableInputProps1 = {
        // Table columns
        columns: [
            {label: 'Sales Route Number', field: 'SLS_RTE_N', align: 'center', width: 200, filter: true, sorting: true},
            {label: 'Sales Representative Name', field: 'SLS_REP_M', width: 600, filter: true, sorting: true}
        ],

        // Table options
        options: {
            action: true,
            filtering: true
        },

        // Table actions
        actions: [
            {
            }
        ]
    };

    const tableInputProps2 = {
        // Table columns
        columns: [
            {label: 'Sales Route Number', field: 'SLS_RTE_N', align: 'center', width: 200, filter: true, sorting: true},
            {label: 'Sales Representative Name', field: 'SLS_REP_M', width: 600, filter: true, sorting: true}
        ],

        // Table options
        options: {
            action: true,
            filtering: true
        },

        // Table actions
        actions: [
            {
            }
        ]
    };


    // Table column defination
	const tableColumns = useMemo(() => [
        {label: 'Sales Route Number', field: 'SLS_RTE_N', align: 'center', width: 150, filter: true, sorting: true},
        {label: 'Sales Representative Name', field: 'SLS_REP_M', width: 600, filter: true, sorting: true}
	], []);


    // Set state callback for snackbar
    /* SnackbarCfg.setStateFns({
        stateMessage: setSnackbarMessage,
        stateOpen: setOpenSnackbar
    }); */
    /* GridCfg.setStateFns({
        / * gridDim: tableDimension,
        columns: tableInputProps.columns, * /
        stateRowData: setRowData,
        // stateColWidths: setColumnWidths,
        stateSortColumns: setSortColumns
    }); */

	// Distributor Change Callback defination
    const onDistributorChangeCallback = useCallback((e, dwValue) => {
        console.log('onDistributorChangeCallback > ', dwValue);
        setJdeAddrBookNo(dwValue);

        // Get Sales Rep data based on distributor
        /* (async () => {
            setShowLoader(true);
            await SalesforceHierarchyService.getSfRepNamesData({jdeAbNo: dwValue?.id})
                .then(response => {
                    console.log('>>>>>>>', response);
                    / * const {status, rows} = response.data;
                    setAllDistributorsData(rows || []);
                    if (!status.isOk) {
                        SnackbarCfg.setMessage({
                            message: env.getEnv('REACT_APP_MSG_RETRIEVE_FAILED'),
                            severity: SnackbarCfg.severity_level.error
                        });
                    } * /
                })
                .catch(error => {
                    SnackbarCfg.setMessage({
                        message: (error?.response?.data?.message || error?.message),
                        severity: SnackbarCfg.severity_level.error
                    });
                })
                .finally(() => {
                    setShowLoader(false);
                });
        })(); */
    }, []);

    // Sort Column callback
    const onSortCallback = useCallback((e, props) => {
        GridCfg.fnSortColumn(e, {...props, sortColumns: sortColumns});
    }, [sortColumns]);

    // Column resize callback
    const onResizeCallback = useCallback((e, props) => {
        // GridCfg.fnResizeColumn(e, props);
    }, []);

    // Popup Dialog close callback
    const onDialogCloseCallback = useCallback(e => {
        console.log('onDialogCloseCallback', e);
        setShowDialog(false);
    }, []);

	// userEffect hook to load data if changes
    useEffect(() => {
        // Get distributors data
        (async () => {
            setShowLoader(true);
            await SalesforceHierarchyService.getAllDistributorsData()
                .then(response => {
                    const {status, rows} = response.data;
                    setAllDistributorsData(rows || []);
                    if (!status.isOk) {
                        SnackbarCfg.setMessage(env.getEnv('REACT_APP_MSG_RETRIEVE_FAILED'), true, SnackbarCfg.severity_level.error);
                    }
                })
                .catch(error => {
                    SnackbarCfg.setMessage((error?.response?.data?.message || error?.message), true, SnackbarCfg.severity_level.error);
                })
                .finally(() => {
                    setShowLoader(false);
                });
        })();
    }, []);

    // userEffect hook to set column widths while resizing
    // useEffect(() => {
        // Column width - grid 1
        let _colWidthGrid1 = {}; //{...columnWidths};
        tableInputProps1.columns.forEach(item => {
            _colWidthGrid1 = {
                ..._colWidthGrid1,
                [item.field]: item?.width/1000 || 0.01
            }
        });
        console.log(_colWidthGrid1);
        // setColumnWidths(prev => _colWidthGrid1);


        // Column width - grid 2
        /* let _colWidthGrid2 = columnWidths1;
        tableInputProps2.columns.forEach(item => {
            _colWidthGrid2 = {
                ..._colWidthGrid2,
                [item.field]: item?.width/1000 || 0.01
            }
        });
        setColumnWidths1(_colWidthGrid2); */

        /* tableInputProps2.columns.forEach(item => {
            setColumnWidths1(prevState => ({
                ...prevState,
                [item.field]: item?.width/1000 || 0.01
            }));
        }); */
        // console.log('here');
    // }, [tableInputProps1, tableInputProps2]);

	// Customized Properties
	const customProps = {
		title: 'Sales Force Hierarchy',
		jdeAbNo: jdeAddrBookNo,
		allDistributors: allDistributorsData,
        showLoader: showLoader,
        dimensions: tableDimension,
        /* rowData: rowData,
        columns: {
            cols1: tableInputProps1.columns,
            cols2: tableInputProps2.columns
        },
        columnWidths: columnWidths, *//* 
        tblInputProps: tableInputProps,
        isModifyEnabled: isModifyEnabled, */
        vgrid: [
            {
                id: 'idAssignRouteCntr',
                rowData: rowData,
                columns: tableInputProps1.columns,
                gridWidth: tableDimension?.width/3 + 50,
                // dimensions: tableDimension,
                columnWidths: columnWidths,
                scrollToIndex: 0,
                sorts: {
                    sortColumns: sortColumns,
                    onSort: onSortCallback
                },
                onResize: onResizeCallback,
                // onRowActionClick: onRowActionClickCallback
            },
            {
                id: 'idUnAssignRouteCntr',
                rowData: rowData1,
                columns: tableInputProps2.columns,
                gridWidth: tableDimension?.width/3 + 50,
                columnWidths: columnWidths1,
                scrollToIndex: 0,
                sorts: {
                    sortColumns: sortColumns,
                    onSort: onSortCallback
                },
                onResize: onResizeCallback,
                // onRowActionClick: onRowActionClickCallback
            }
        ],
		callbacks: {
			onDistributorChange: onDistributorChangeCallback
		},
        pdialog: {
            title: 'Sales Representative',
            showDialog: showDialog,
            dialogType: dialogType,
            callbacks: {
                onDialogClose: onDialogCloseCallback
            }
        }
	}

	return (
		<Container>
			<SfHierarchyContainer {...customProps}/>
			<SnackbarAlert/>
        </Container>
	)
}

// Main Sales Force Hierarchy Container
const SfHierarchyContainer = React.memo(props => {
    return (
        <TableRowContainer>
            <TableCaptionBar {...props} />
            <TableContainer {...props} />

            {/* <TableColumnContainer {...props} />
            <SfHierarchyPlaceHolder {...props} /> */}
        </TableRowContainer>
    )
})

// Table Caption Bar - Title / Search
const TableCaptionBar = React.memo(props => {
    const {
        title,
        jdeAbNo,
        allDistributors,
        callbacks: {onDistributorChange}
    } = props;

    return (
        <StyledCaptionBar>
            <Title>{title}</Title>

            <StyledDropDown variant="standard" style={{marginTop: '-1rem', minWidth: '28%'}}>
                <Autocomplete 
                    options={(allDistributors || []).map(el => ({
                        id: el.JDE_ADDR_BOOK_N,
                        label: `${el.DIST_DESC} (${el.JDE_ADDR_BOOK_N})`
                    }))}
                    id="dist-select-label" value={jdeAbNo || ''}
                    onChange={onDistributorChange}
                    isOptionEqualToValue={(o, v) => (v === undefined || v === '' || o?.id === v?.id || v.id === '')}
                    renderInput={(params) => <TextField {...params} label="Distributors" margin="normal" variant="standard"/>}/>
            </StyledDropDown>
        </StyledCaptionBar>
    )
})

// Table Caption Bar - Title / Search
const TableContainer = React.memo(props => {
    const {
        vgrid,
        dimensions:{width}
    } = props;
    const col1Width = (width - (vgrid || [...vgrid]).reduce((a, v) => a = a + v?.gridWidth, 0));
    // console.log('col1Width', width, col1Width, vgrid);
    
    return (
        <Table border={0} sx={{marginTop: '1.5em'}}>
            <TableBody>
                <TableRow sx={{verticalAlign: 'top'}}>
                    <TableCell sx={{width: `${col1Width}px`, padding: 0, borderBottom: '0 none'}}>
                        <SfTreeView title="Hierarchy Tree View" nodes={data} />
                    </TableCell>
                    {(vgrid || [...vgrid]).map((item, idx) => ((
                        <TableCell key={`tc_${item.id}${idx}`} sx={{width: `${item.gridWidth}px`, padding: '0 0 0 1.5em', borderBottom: '0 none'}}>
                            <TableGridContainer id={item.id} gridProps={item} {...props}/>
                        </TableCell>
                    )))}
                    {/* <TableCell width={'33%'} sx={{padding: '0 1em', borderBottom: '0 none'}}>
                        <TableGridContainer id={'idAssignRouteCntr'} gridProps={props.vgrid[0]} {...props}/>
                    </TableCell>
                    <TableCell width={'33%'} sx={{padding: 0, borderBottom: '0 none'}}>
                        <TableGridContainer id={'idUnAssignRouteCntr idAssignRouteCntr'} gridProps={props.vgrid[1]} {...props}/>
                    </TableCell> */}
                </TableRow>
            </TableBody>
        </Table>
    )
})

// Table Grid Container
const TableGridContainer = React.memo(props => {
    // console.log('>>>', props);
    const {id} = props;

    return (

        <div id={id}>
            <TableColumnContainer {...props} />
        </div>
    )
})

// Table column container
const TableColumnContainer = React.memo(props => {
    const {
        gridProps: {gridWidth, columns, columnWidths}
    } = props;

    // console.log(props);

    return (
		<StyledColumnContainerDiv role="row" style={{fontWeight: 500, width: '100%'}}>
			{columns.map((column, idx) => {
				const columnWidth = (columnWidths[column.field] || 0.01) * gridWidth;


            })}
        </StyledColumnContainerDiv>
    )
})

// Column resizer placeholder
const ColumnResizerRenderer = React.memo((props) => {
	const {column, onResize} = props;

	return (
		<>
			{column.field && (column?.resize === undefined || !!column?.resize) 
				? <ColumnResizer axis="x" onDrag={(e, { deltaX }) => onResize(e, {dataKey: column.field, deltaX: deltaX})} position={{ x: 0 }}>
				    <StyledColumnResizeHandler>|</StyledColumnResizeHandler>
				  </ColumnResizer>
				: <StyledColumnResizeDisableDiv>|</StyledColumnResizeDisableDiv>
			}
		</>
	)
})

// Sales Force Hierarchy Place Holder
const SfHierarchyPlaceHolder = React.memo(props => {
    const {
        rowData,
        showLoader,
        vgrid: {
            scrollToIndex,
            dimensions: {height, width}
        }
    } = props;

    return (
        <StyledPlaceHolderDiv style={{width: `${width}px`/* , backgroundColor: 'red' */}}>
            {/* <CircularProgressLoader loading={!rowData}/> */}
            <SfTreeView nodes={data} />

            <List
                height={height}
                rowCount={rowData?.length || 0}
                rowHeight={35}
                width={width}
                style={{
                    // backgroundColor: getBackgroundColor(),
                    transition: 'background-color 0.2s ease'
                }}
                rowRenderer={getRowRender(/* {rowData, options, columns, actions, rowClick, rowActionClick, gridOptions, tableDimension} */)}
                scrollToIndex={scrollToIndex}
                overscanRowCount={10}
                scrollToAlignment={'start'}
                noRowsRenderer={() => <NoRowsRenderer/>}/>
            <OverlayLoader loading={showLoader || !rowData}/>
        </StyledPlaceHolderDiv>
	)
})

// No rows found placeholder
const NoRowsRenderer = React.memo(() => {
	return (
		<StyledNoRecordDiv>No record(s) found!</StyledNoRecordDiv>
	)
})

// Using a higher order function so that we can look up the rows data to retrieve row from within the rowRender function
const getRowRender = (props) => ({index, style}) => {
	/* const {rowData, options, columns, actions, rowClick, rowActionClick, gridOptions, tableDimension} = props;
	const row = rowData[index] || undefined;
	if (!row) {
		return null;
	} */

	// Rendering an extra item for the placeholder by increasing data set size to include one 'fake' item
	const patchedStyle = {
		...style,
		left: style.left,
		top: style.top + 2,
		width: style.width,
		height: style.height - 0.5
	};

	return (
        <>
		{/* <RowItem key={row.UUID} index={index} gridOptions={gridOptions} rowClick={rowClick} rowActionClick={rowActionClick} 
			row={row} actions={actions} columns={columns} options={options} style={patchedStyle} tableDimension={tableDimension} /> */}
            here
        </>
	);
}