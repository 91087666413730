import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import MuiAlert from '@mui/material/Alert';
import { FormControl, InputLabel, MenuItem, Select, Snackbar, Autocomplete, TextField } from '@mui/material';
import { useWindowDimensions } from '../hooks/hook.window.dimensions';
import { SalesCalendar } from '../components/component.sales.calendar';
import { NonSellingDaysService } from '../services/service.sales.nonsellingdays';
import { env } from '../utils/env.variables';
// include utils module
require('../utils/utils');


/**
 * Styled components
 */
// Table main container
const Container = styled.div`
    display: flex;
    margin-top: 8px;
    // border: 1px solid;
`;
// Table row container
const TableRowContainer = styled.div`
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    display: inline-block;
    margin: 0 auto;
`;
// Caption bar div style
const StyledCaptionBar = styled.div`
    display: flex;
    margin: 0 0 1em;
    padding: 0 .1em;
`;
// Table title style
const Title = styled.h4`
    margin: 0;
    align-items: center;
    display: flex;
    flex-grow: 1;
    font-size: 1rem;
    user-select: none;
`;
// Distributor type div style
const StyledDropDown = styled(FormControl)`
    min-width: 8%;
    margin-left: 1.5em;
    &>label {
        top: -16px;
    }
    &>.MuiInputBase-root {
        margin-top: 0;
        &:before {
            border-bottom: 0px solid rgba(0, 0, 0, 0.22);
        }
    }
    &>.MuiInputBase-root:hover:not(.MUI-disabled):before {
        border-bottom: 0px solid rgba(0, 0, 0, 0.22);
    }
    &>.MuiInputBase-root:after {
        border-bottom: 0px solid #1976d2;
    }
    &>.MuiAutocomplete-root >.MuiTextField-root {
        margin-top: 0;
        margin-bottom: 4px;
        &>:before {
            border-bottom: 0px solid rgba(0, 0, 0, 0.22);
            font-size: 0.775rem;
        }
        &>:after {
            border-bottom: 0px solid #1976d2;
            font-size: 0.775rem;
        }
        &>:hover:not(.MUI-disabled):before {
            border-bottom: 0px solid rgba(0, 0, 0, 0.22);
        }
    }
`;
// Calendar place holder
const CalendarPlaceHolderDiv = styled.div`
	display: inline-block;
    padding: .1em 0;
    width: 100%;
`;


// Severity level - error, info, success, warning
const severity_level = {
    error: 'error',
    info: 'info',
    success: 'success',
    warning: 'warning'
};


// Sales Calendar - Non Selling Days
export const SalesCalNonSellingDays = (props) => {
    const {dimensions: tableDimension/* leftDivRef */, graphData: userInfo} = props;
	/* const {width} = useWindowDimensions();
    const _width = ((width - (leftDivRef?.current?.clientWidth || 182)) - 34);
    const _height = ((leftDivRef?.current?.clientHeight || 500) - 170);
    const tableDimension = {width: _width, height: _height}; */

    const [years, setYears] = useState();
    const [yearSelected, setYearSelected] = useState();
    const [jdeAddrBookNo, setJdeAddrBookNo] = useState();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState({
        severity: severity_level.error, 
        message: ''
    });
    const [nonSellingDaysData, setNonSellingDaysData] = useState();
    const [allDistributorsData, setAllDistributorsData] = useState();
    const [isCalEventEnabled, setIsCalEventEnabled] = useState(false);

    // Show Snackbar message
    const showSnackbarMessage = useCallback((_message, _severity) => {
        setSnackbarMessage({
            severity: _severity ? _severity : snackbarMessage.severity, 
            message: _message
        });
        setOpenSnackbar(true);
    }, [snackbarMessage.severity]);

    // Get Fiscal year list
    const getFiscalYears = useCallback(() => {
        let currentYear = new Date().getFullYear(),
            startYear = currentYear - 2,
            endYear = currentYear + 1;
        const yearRangeArr = startYear.range(endYear);

        return yearRangeArr;
    }, []);

    // Get all distributors data
    const getAllDistributors = useCallback(async () => {
        return await NonSellingDaysService.getAllDistributorsData()
            .then(response => response)
            .catch(error => error?.response);
    }, []);

    // Get all Non Selling Days data
    const getNonSellingDaysData = useCallback(async (jdeAbNo, year) => {
        return await NonSellingDaysService.getNonSellingDays({jdeAbNo: jdeAbNo, year: year})
            .then(response => response)
            .catch(error => error?.response);

            /* await NonSellingDaysService.getNonSellingDays({jdeAbNo: jdeAbNo, year: year})
                .then(response => {
                    console.log('ND', response);
                    const {status, rows} = response.data;
                    setNonSellingDaysData(rows || []);
                    if (!status.isOk) {
                        showSnackbarMessage(env.getEnv('REACT_APP_MSG_RETRIEVE_FAILED'), severity_level.error);
                    }
                })
                .catch(error => {
                    showSnackbarMessage((error?.response?.data?.message || error?.message), severity_level.error);
                });
                .finally(() => {
                    setShowLoader(false);
                }); */
    }, []);

    // Distributor Change Callback defination
    const onDistributorChangeCallback = useCallback((e, dwValue) => {
        setShowLoader(true);
        setJdeAddrBookNo(dwValue);
        setIsCalEventEnabled(!!(dwValue?.id && yearSelected));
        if (!dwValue) {
            setNonSellingDaysData([]);
            setShowLoader(false);
            return;
        }

        // Get Non Selling Days Data
        (async () => {
            await getNonSellingDaysData(dwValue?.id, yearSelected)
                .then(response => {
                    const {status, rows} = response.data;
                    setNonSellingDaysData(rows || []);
                    if (!status?.isOk) {
                        showSnackbarMessage(status?.message || env.getEnv('REACT_APP_MSG_RETRIEVE_FAILED'), severity_level.error);
                    }
                })
                .catch(error => {
                    showSnackbarMessage((error?.data || error?.message), severity_level.error);
                })
                .finally(() => {
                    setShowLoader(false);
                });

            /* await NonSellingDaysService.getNonSellingDays({jdeAbNo: dwValue?.id, year: yearSelected})
                .then(response => {
                    const {
                        non_selling_days_data: {status, rows: nonSellingData}
                    } = response.data;

                    setNonSellingDaysData(nonSellingData || []);
                    if (!status.isOk) {
                        showSnackbarMessage(env.getEnv('REACT_APP_MSG_RETRIEVE_FAILED'), severity_level.error);
                    }
                })
                .catch(error => {
                    showSnackbarMessage((error?.response?.data?.message || error?.message), severity_level.error);
                })
                .finally(() => {
                    setShowLoader(false);
                }); */
        })();
    }, [yearSelected, getNonSellingDaysData, showSnackbarMessage]);

    // Year Change Callback defination
    const onYearChangeCallback = useCallback((e) => {
        setShowLoader(true);
        setYearSelected(e.target.value);
        setIsCalEventEnabled(!!(jdeAddrBookNo?.id && e.target.value));
        if (!e.target.value) {
            setNonSellingDaysData([]);
            setShowLoader(false);
            return;
        }

        // Get Non Selling Days Data
        (async () => {
            await getNonSellingDaysData(jdeAddrBookNo?.id, e.target.value)
                .then(response => {console.log(response);
                    const {status, rows} = response.data;
                    setNonSellingDaysData(rows || []);
                    if (!status?.isOk) {
                        showSnackbarMessage(status?.message || env.getEnv('REACT_APP_MSG_RETRIEVE_FAILED'), severity_level.error);
                    }
                })
                .catch(error => {
                    showSnackbarMessage((error?.data || error?.message), severity_level.error);
                })
                .finally(() => {
                    setShowLoader(false);
                });
        })();

        // Get Non Selling Days Data
        /* (async () => {
            setShowLoader(true);
            await getNonSellingDaysData(jdeAddrBookNo?.id, e.target.value);
            setShowLoader(false); */

            /* await NonSellingDaysService.getNonSellingDays({jdeAbNo: jdeAddrBookNo?.id, year: e.target.value})
                .then(response => {
                    const {
                        non_selling_days_data: {status, rows: nonSellingData}
                    } = response.data;

                    setNonSellingDaysData(nonSellingData || []);
                    if (!status.isOk) {
                        showSnackbarMessage(env.getEnv('REACT_APP_MSG_RETRIEVE_FAILED'), severity_level.error);
                    }
                })
                .catch(error => {
                    showSnackbarMessage((error?.response?.data?.message || error?.message), severity_level.error);
                })
                .finally(() => {
                    setShowLoader(false);
                }); */
        // })();
    }, [jdeAddrBookNo, getNonSellingDaysData, showSnackbarMessage]);

    // Day click callback
    const onCalendarDayClickCallback = useCallback(result => {
        // console.log('onCalendarDayClickCallback>>>>', result);
        setShowLoader(true);
        let newResultData = [];
        result?.forEach(item => {
            newResultData.push({
                ...item,
                usrid: userInfo.upn,
                SELECTED_YEAR: yearSelected,
                N_JDE_ADDR_BOOK_NO: jdeAddrBookNo?.id
            });
        });

        (async () => {
            await NonSellingDaysService.updateNonSellingDays(newResultData)
                .then(response => {
                    const {status, rows: nonSellingData} = response.data;
                    if (status.isOk) {
                        setNonSellingDaysData(nonSellingData || []);
                        showSnackbarMessage(env.getEnv('REACT_APP_MSG_NSD_UPDATE_SUCCESS'), severity_level.success);
                    }
                    else {
                        showSnackbarMessage(env.getEnv('REACT_APP_MSG_RETRIEVE_FAILED'), severity_level.error);
                    }
                })
                .catch(error => {
                    showSnackbarMessage((error?.response?.data?.message || error?.message), severity_level.error);
                })
                .finally(() => {
                    setShowLoader(false);
                });
        })();
    }, [jdeAddrBookNo, yearSelected, userInfo, showSnackbarMessage]);

    // Close Snackbar
	const onCloseSnackbarCallback = useCallback((event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
    	setOpenSnackbar(false);
        setSnackbarMessage({severity: snackbarMessage.severity, message: ''});
    }, [snackbarMessage.severity]);
    
    // userEffect hook to load data if changes
    useEffect(() => {
        setShowLoader(true);
        setYears(getFiscalYears());
        setYearSelected(yearSelected || new Date().getFullYear());

        if (allDistributorsData) {
            setShowLoader(false);
            return;
        }

        // Get distributors data
        (async () => {
            await getAllDistributors()
                .then(response => {
                    const {status, rows} = response.data;
                    setAllDistributorsData(rows || []);
                    if (!status?.isOk) {
                        showSnackbarMessage(env.getEnv('REACT_APP_MSG_RETRIEVE_FAILED'), severity_level.error);
                    }
                })
                .catch(error => {
                    showSnackbarMessage((error?.data || error?.message), severity_level.error);
                })
                .finally(() => {
                    setShowLoader(false);
                });

            /* await NonSellingDaysService.getAllDistributorsData()
                .then(response => {
                    console.log('D3', response);
                    const {status, rows} = response.data;

                    setAllDistributorsData(rows || []);
                    if (!status.isOk) {
                        showSnackbarMessage(env.getEnv('REACT_APP_MSG_RETRIEVE_FAILED'), severity_level.error);
                    }
                })
                .catch(error => {
                    showSnackbarMessage((error?.response?.data?.message || error?.message), severity_level.error);
                })
                .finally(() => {
                    setShowLoader(false);
                }); */
            
            /* await NonSellingDaysService.getNonSellingDays({fthdist: isFetchDistributors, jdeAbNo: jdeAddrBookNo?.id, year: yearSelected})
                .then(response => {
                    const {
                        distributors_data: {rows: distributorsData},
                        non_selling_days_data: {status, rows: nonSellingData}
                    } = response.data;

                    isFetchDistributors && 
                        setAllDistributorsData(distributorsData || []);
                    setNonSellingDaysData(nonSellingData || []);
                    if (!status.isOk) {
                        showSnackbarMessage(env.getEnv('REACT_APP_MSG_RETRIEVE_FAILED'), severity_level.error);
                    }
                })
                .catch(error => {
                    showSnackbarMessage((error?.response?.data?.message || error?.message), severity_level.error);
                })
                .finally(() => {
                    setShowLoader(false);
                    setIsFetchDistributors(false);
                }); */
        })();
    }, [yearSelected, allDistributorsData, getAllDistributors, getFiscalYears, showSnackbarMessage]);

    // Customized Properties
    const customProps = {
        title: 'Sales Calendar - Non Selling Days',
        dimensions: tableDimension,
        dwYears: years,
        showLoader: showLoader,
        dwYear: yearSelected,
        jdeAbNo: jdeAddrBookNo,
        nonSellingDays: nonSellingDaysData,
        isCalEventEnabled: isCalEventEnabled,
        allDistributors: allDistributorsData,
        callbacks: {
            onYearChange: onYearChangeCallback,
            onDistributorChange: onDistributorChangeCallback,
            onCalendarDayClick: onCalendarDayClickCallback
        },
        snackbar: {
            duration: 5000,
            isOpen: openSnackbar,
            messages: snackbarMessage,
            callbacks: {
                onCloseSnackbar: onCloseSnackbarCallback
            }
        }
    }

    return (
        <Container>
            <CalendarContainer {...customProps} />
            <SnackbarAlert {...customProps.snackbar} />
        </Container>
    )
}

// Main Calendar Container
const CalendarContainer = React.memo(props => {
    return (
        <TableRowContainer>
            <TableCaptionBar {...props} />
            <CalendarPlaceHolder {...props} />
        </TableRowContainer>
    )
})

// Table Caption Bar - Title / Search
const TableCaptionBar = React.memo(props => {
    const {
        title,
        dwYear,
        dwYears,
        jdeAbNo,
        allDistributors,
        callbacks: {onYearChange, onDistributorChange}
    } = props;

    return (
        <StyledCaptionBar>
            <Title>{title} (Year - {dwYear})</Title>

            <StyledDropDown variant="standard" style={{marginTop: '-1rem', minWidth: '28%'}}>
                <Autocomplete 
                    options={(allDistributors || []).map(el => ({
                        id: el.JDE_ADDR_BOOK_N,
                        label: `${el.DIST_DESC} (${el.JDE_ADDR_BOOK_N})`
                    }))}
                    id="dist-select-label" value={jdeAbNo || ''}
                    onChange={onDistributorChange}
                    isOptionEqualToValue={(o, v) => (v === undefined || v === '' || o?.id === v?.id || v.id === '')}
                    renderInput={(params) => <TextField {...params} label="Distributors" margin="normal" variant="standard"/>}/>
            </StyledDropDown>

            {/* <StyledDropDown variant="standard" style={{minWidth: '20%'}}>
                <InputLabel id="distributor-input-label">Distributor</InputLabel>
                <Select id="distributor-select-label" labelId="distributor-select-label" defaultValue={''} 
                    value={jdeAbNo || ''} name="DW_DISTRIBUTOR" label="Distributor" onChange={onDistributorChange}>
                    <MenuItem value={''}>None</MenuItem>
                    {(allDistributors || []).map((item, i) => (
                        <MenuItem key={`dist-${item.JDE_ADDR_BOOK_N}-${i}`} value={item.JDE_ADDR_BOOK_N}>{item.DIST_DESC} ({item.JDE_ADDR_BOOK_N})</MenuItem>
                    ))}
                </Select>
            </StyledDropDown> */}

            <StyledDropDown variant="standard">
                <InputLabel id="year-input-label">Year</InputLabel>
                <Select id="year-select-label" labelId="year-select-label" defaultValue={''} 
                    value={dwYear || ''} name="DW_YEAR" label="Year" onChange={onYearChange}>
                    {(dwYears || []).map((year, i) => (
                        <MenuItem key={`dwy-${year}-${i}`} value={year}>{year}</MenuItem>
                    ))}
                </Select>
            </StyledDropDown>
        </StyledCaptionBar>
    )
})

// Calendar Place Holder
const CalendarPlaceHolder = React.memo(props => {
    const {
        dwYear,
        showLoader,
        nonSellingDays,
        isCalEventEnabled,
        callbacks: {onCalendarDayClick}
    } = props;
    const calendarCallbacks = {
        onCalendarDayClick: onCalendarDayClick
    }

    return (
        <CalendarPlaceHolderDiv>
            <SalesCalendar 
                isCalEventEnabled={isCalEventEnabled} 
                calendarDays={nonSellingDays} 
                showLoader={showLoader} 
                callbacks={calendarCallbacks} 
                year={dwYear} 
                showNextMonth={true}
                calType="NSD"/>
        </CalendarPlaceHolderDiv>
    )
})

// Snackbar Alert Container
const SnackbarAlert = React.memo(props => {
    const {
        isOpen,
        duration,
        messages: {severity='info', message=''},
        callbacks: {onCloseSnackbar}
    } = props;
    
    // MUI Alert - for Snackbar
    const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant={'filled'} {...props} />);

    return (
        <Snackbar open={isOpen} autoHideDuration={duration} onClose={onCloseSnackbar}>
            <Alert onClose={onCloseSnackbar} severity={severity} sx={{width: '100%'}}>{message}</Alert>
        </Snackbar>
    )
})
