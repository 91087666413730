import React from 'react';
import TreeView from '@mui/lab/TreeView';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import { SvgIcon, alpha } from '@mui/material';
import styled from '@emotion/styled';


// Styled components
const StyledTreeItem = styled(props => (
        <TreeItem {...props} /* TransitionComponent={TransitionComponent} */ />
    ))(({ theme }) => ({
        [`& .${treeItemClasses.iconContainer}`]: {
            '& .close': {
                opacity: 0.3
            }
        },
        [`& .${treeItemClasses.group}`]: {
            marginLeft: 18,
            paddingLeft: 18,
            borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`
        }
    })
);
// Treeview Container
const StyledTreeViewContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
// Styled Tree view title
const StyledTitle = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #f2f2f2;
    border-top: 1px solid #cbcbcb;
    border-bottom: 1px solid #cbcbcb;
    height: 3em;
    padding: 0 .8em;
    margin-bottom: 1em;
    user-select: none;
    font-weight: 500;
`;

// Tree view component
export const SfTreeView = (props) => {
    const {
        nodes,
        title,
        titleHeight,
        height='auto',
        width='auto'
    } = props;

    if (typeof nodes !== 'object') {
        return false;
    }
    const treeWidth = width !== 'auto' ? `${width}px` : width,
        treeHeight = height !== 'auto' ? `${height}px` : height,
        treeMaxWidth = width === 'auto' ? `99%` : treeWidth
    
    return (
        <StyledTreeViewContainer>
            {title && <StyledTitle {...(titleHeight && {style: {height: titleHeight + 'px'}})}>{title}</StyledTitle>}
            <TreeView
                aria-label="SF Tree View"
                defaultExpanded={[`${nodes?.id}`]}
                defaultCollapseIcon={<MinusSquareIcon />}
                defaultExpandIcon={<PlusSquareIcon />}
                defaultEndIcon={<CloseSquareIcon />}
                sx={{flexGrow: 1, padding: '0 1em 0 .5em', overflowY: 'auto', height: `${treeHeight}`, width: `${treeWidth}`, maxWidth: `${treeMaxWidth}`}}>
                    <TreeItemRenderer items={nodes} />
            </TreeView>
        </StyledTreeViewContainer>
    );
}

// Render tree items
const TreeItemRenderer = React.memo(props => {
	const { items } = props;

    // Build and render tree
    return (
        <StyledTreeItem key={items.id} nodeId={items.id} label={items.name}>
            {items?.children?.map((child, index) => <TreeItemRenderer key={`${index}${child}_${(new Date()).getTime()}`} items={child} />)}
        </StyledTreeItem>
    )
})

// Custom Minus Icon
const MinusSquareIcon = React.memo(props => {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
        </SvgIcon>
    )
})

// Custom Plus Icon
const PlusSquareIcon = React.memo(props => {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
        </SvgIcon>
    )
})

// Custom Close Icon
const CloseSquareIcon = React.memo(props => {
    return (
        <SvgIcon className="close" fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
        </SvgIcon>
    )
})
