import React from 'react';
import axios from 'axios';
import { tokenRequest } from './azure.auth.config';
import { useMsal, useAccount } from '@azure/msal-react';

// Axios request interceptor to wrap all request to server and add token to header
export const RequestInterceptor = ({ children }) => {
    const { instance, accounts } = useMsal();
    const activeAccount = useAccount(accounts[0]);

    /* eslint-disable no-param-reassign */
    axios.interceptors.request.use(async (config) => {
        if (!activeAccount) {
            return;
        }
        const response = await instance.acquireTokenSilent({
            ...tokenRequest,
            activeAccount
        });
        const bearerToken = `${response.tokenType} ${response.accessToken}`;
        config.headers.Authorization = bearerToken;
        
        return config;
    });
    /* eslint-enable no-param-reassign */

    /* eslint-disable no-param-reassign */
    axios.interceptors.response.use(
        response => response,
        error => {
            if (!error.response) {
                error.message = 'ERROR: please check your internet connection / make sure backend server is running.';
            }
            return Promise.reject(error);
        }
    );
    /* eslint-enable no-param-reassign */
    
    return (
        <>
            {children}
        </>
    );
}
