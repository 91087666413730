import axios from 'axios';
import { v4 as uuid_v4 } from 'uuid';
import { ProductXref } from '../utils/urls';


// Distributor Product Xref Validation service
export const ProductXrefValidationService = {
    // Get all distributor product x-ref data
    getAllProdXrefData: async () => {
        let result = await axios.get(ProductXref.Validation.ALL_XREF_DATA);
        rebuildResult(result);

        return result;
    },

	// Update distributor product x-ref data
	updateProdXrefData: async (rowData) => {
		let result = await axios.put(ProductXref.Validation.UPD_XREF_DATA, {
            data: rowData
        });
        // rebuildResult(result);
        
        return result;
	}

}

// Re-build resultset to add uuid
const rebuildResult = (result) => {
    let _rows_data_prod_xref = [];
    (result?.data?.rows || []).forEach(item => {
        _rows_data_prod_xref.push({...item, UUID: uuid_v4()});
    });
    if (result?.data?.rows) {
        result.data = {
            ...result?.data, 
            rows: _rows_data_prod_xref
        }
    }
}
