// Product Rollup Service
import axios from 'axios';
import { v4 as uuid_v4 } from 'uuid';
import { Rollups } from '../utils/urls';

export const ProductRollupService = {
	// Get all product rollups
    getAllProductRollups: async () => {
        let result = await axios.get(Rollups.Product.ALL_PRODUCT_ROLLUPS);
        rebuildResult(result);

        return result;
    },

	// Update rollup position while dragging item
	updateProductRollupPosition: async (rowData) => {
		let result = await axios.put(Rollups.Product.UPD_ROLLUP_POSITION, {
            data: rowData
        });
        rebuildResult(result);

        return result;
	},

    // Add product rollup
    addProductRollup: async (rowData) => {
        let result = await axios.post(Rollups.Product.ADD_PRODUCT_ROLLUPS, {
            data: rowData
        });
        rebuildResult(result);

        return result;
    },

    // Update product rollup
    updateProductRollup: async (rowData) => {
        let result = await axios.put(Rollups.Product.UPD_PRODUCT_ROLLUPS, {
            data: rowData
        });
        rebuildResult(result);

        return result;
    },

    // Delete product rollup
    deleteProductRollup: async (rowData) => {
        let result = await axios.delete(Rollups.Product.DEL_PRODUCT_ROLLUPS, {
            data: {
                data: rowData
            }
        });
        rebuildResult(result);

        return result;
    },

	// Copy / move Product Rollup(s) into another position
	cutPasteProductRollups: async (rowData) => {
		let result = await axios.put(Rollups.Product.CUT_PASTE_ROLLUPS, {
            data: rowData
        });
        rebuildResult(result);

        return result;
	}
}

// Re-build resultset
const rebuildResult = (result) => {
    // Product rollups re-build to add uuid
    let _rows_data_product_rollups = [];
    (result?.data?.data_product_rollups?.rows || []).forEach(item => {
        _rows_data_product_rollups.push({...item, UUID: uuid_v4()});
    });
    if (result?.data?.data_product_rollups) {
        result.data.data_product_rollups = {
            ...result?.data?.data_product_rollups,
            rows: _rows_data_product_rollups
        }
    }
}