import { makeStyles } from '@mui/styles';
import styled from '@emotion/styled';
import { FormControl, Input, MenuItem, TableSortLabel } from '@mui/material';


// Styles
export const useStyles = makeStyles(theme => ({
    error: {
        margin: theme.spacing(25, 'auto', 0)
    },
    frmctldiv: {
        marginBottom: '2.5em !important'
    },
    frmctldiv1: {
        marginBottom: '1em !important'
    },
	w10em: {
        minWidth: '10em !important'
    },
	colors: {
		red: {
			color: '#ff0000'
		}
	}

	/*link: {
	    textDecoration: 'none',
		alignItems: 'flex-start',
		paddingLeft: '1em',
	    '&:hover': {
			borderLeft: '1em solid #1976d2'
	    },
		[`& .MuiTouchRipple-root`]: {
			color: 'red'
		}
	}*/
}));


/**
 * Styled components
 */
// Overlay loader div style
export const StyledLoaderDiv = styled.div`
	background-color: #3ea2e5;
	display: flex;
	align-items: center;
	border-radius: 4px;
	padding: 0.8em;
	color: #ffffff;
`;

// Loading div style
export const StyledLoadingDiv = styled.div`
	display: flex;
	align-items: center;
	padding: 1em 0.6em;
	color: #979797;
	font-size: 0.9rem;
	&>span {
		margin-right: 0.6em;
	}
`;

// Table main container
export const Container = styled.div`
	display: flex;
	margin-top: 8px;
    // border: 1px solid;
`;

// Table row container
export const TableRowContainer = styled.div`
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    // display: inline-block;
    // margin: 0 auto;
	// width: 100%;
	flex-grow: 1;
	// background-color: red;
`;

// Item row container style
export const RowItemContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	user-select: none;
	border-bottom: 1px solid #dfdfdf;
`;
/* 
// Item row container style
export const StyledRowItemContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	user-select: none;
	border-bottom: 1px solid #dfdfdf;
`;
 */
// Caption bar div style
export const StyledCaptionBar = styled.div`
	display: flex;
	margin: 0 0 1em;
	padding: 0 .1em;
	// border: 1px solid;
`;

// Table title style
export const Title = styled.h4`
	margin: 0;
	align-items: center;
    display: flex;
	flex-grow: 1;
	font-size: 0.92rem;
	user-select: none;
`;

// Dropdown
export const StyledDropDown = styled(FormControl)`
    min-width: 8%;
    margin-left: 1.5em;
    &>label {
        top: -16px;
    }
    &>.MuiInputBase-root {
        margin-top: 0;
        &:before {
            border-bottom: 0 solid rgba(0, 0, 0, 0.22);
        }
    }
    &>.MuiInputBase-root:hover:not(.MUI-disabled):before {
        border-bottom: 0 solid rgba(0, 0, 0, 0.22);
    }
    &>.MuiInputBase-root:after {
        border-bottom: 0 solid #1976d2;
    }
    &>.MuiAutocomplete-root >.MuiTextField-root {
        margin-top: 0;
        margin-bottom: 4px;
        &>:before {
            border-bottom: 0 solid rgba(0, 0, 0, 0.22);
            font-size: 0.775rem;
        }
        &>:after {
            border-bottom: 0 solid #1976d2;
            font-size: 0.775rem;
        }
        &>:hover:not(.MUI-disabled):before {
            border-bottom: 0 solid rgba(0, 0, 0, 0.22);
        }
    }
`;
// Extended dropdown
export const StyledDropDownExd = styled(StyledDropDown)`
	margin-left: 0;
	&>.MuiInputBase-root {
		&>.MuiSelect-select {
			padding: 0;
			font-size: 0.675rem !important;
		}
	}
`;

// Place holder inner content div
export const StyledPlaceHolderContainerDiv = styled.div`
	overflow-x: auto;
	// border: 1px solid blue;
`;

// Place holder inner content div
export const StyledPlaceHolderInnerContainerDiv = styled.div`
    width: 100%;
`;
// Place holder inner content div
export const StyledPlaceHolderDiv = styled.div`
	// display: inline-block;
    padding: .1em 0;
    // width: 100%;
	// border: 1px solid blue;
`;

// No records found div
export const StyledNoRecordDiv = styled.div`
	white-space: nowrap;
	display: grid;
	font-size: 0.875rem;
	padding: 6em;
	text-align: center;
	color: #979797;
`;

// Column field name / data div style
export const StyledColumnContainerDiv = styled.div`
	display: flex;
  	flex-direction: row;
  	align-items: center;
	background-color: #f2f2f2;
	border-top: 1px solid #cbcbcb;
	border-bottom: 1px solid #cbcbcb;
    height: 3em;
	// padding-right: 1.2em;
	padding-right: 10px;
	user-select: none;
`;

// Item row container style
export const StyledRowItemContainer = styled(StyledColumnContainerDiv)`
	/* display: flex;
	flex-direction: row;
	align-items: center;
	user-select: none; */
	// border-top: 1px solid #cbcbcb;
	border-bottom: 1px solid #dfdfdf;
`;

// Row item / data div style
export const StyledRowItemDiv = styled.div`
	display: flex;
	align-items: center;
	text-overflow: ellipsis;
    white-space: nowrap;
	// margin-right: .5em;
	margin-left: 0.7em;
    min-width: 0;
    height: 100%;
	/* &:first-of-type {
		margin-left: .5em;
	}
	&:last-of-type {
		margin-right: 0;
	} */
`;

// Column filter div style
export const StyledFilterContainerDiv = styled(StyledColumnContainerDiv)`
	/* display: flex;
	flex-direction: row;
	align-items: center; */
	border-top: 0 none;
	border-bottom: 1px solid #cbcbcb7a;
	// padding: 0.3em 10px 0.3em 0 !important;
	background-color: #f1f1f159;
	// user-select: none;
`;

// Filter row field div style
export const StyledFilterItemDiv = styled(StyledRowItemDiv)`
// margin-right: .5em; ???
// flex-direction: row; ??

	/* align-items: center;
	text-overflow: ellipsis;
	white-space: nowrap;
	min-width: 0px;
	height: 100%;
	display: flex; */

	/* &:first-of-type {
		margin-left: .4em;
	} */
	/* &:last-of-type {
		margin-right: 0;
	} */
	&>.MuiInputBase-root {
		/* width: 100%;
		max-width: 96%; */
		font-size: inherit;
		&>.MuiInputBase-input {
			// border: 1px solid #1976d2;
			padding: 3px 2px;
		}
		&>.MuiInputAdornment-root {
			margin-right: 2px;
			/* &>.MuiSvgIcon-root {
				width: 0.65em;
				height: auto;
			} */
		}
	}
	&>.MuiInputBase-root:before {
		border-bottom: 1px solid rgba(0, 0, 0, 0.22);
	}
	&>.MuiInputBase-root:after {
		border-bottom: 1px solid #1976d2;
	}
	&>.MuiInputBase-root:hover:not(.MUI-disabled):before {
		border-bottom: 1px solid rgba(0, 0, 0, 0.22);
	}
`;

// Column filter div style
export const StyledFilterInput = styled(Input)`
	background-color: #ffffff;
`;

// Column sort div style
export const StyledColumnSortDiv = styled(TableSortLabel)`
	flex: auto;
	display: inline-flex;
	align-items: center;
	max-width: 100%;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	background-color: inherit !important;
	&>.MuiTableSortLabel-icon {
		width: 0.6em;
		margin-left: 2px;
		margin-right: 2px;
	}
`;

// Row item text style div
export const StyledRowItemText = styled.div`
	flex: auto;
	display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
	& >span {
		background-color: #ffff66;
	}
`;

// Column resizer div style
export const StyledColumnResizeHandler = styled.span`
	flex: 0 0 .2em;
    display: flex;
	cursor: col-resize;
	color: #cbcbcb;
	z-index: 2;
	background-color: inherit !important;
	&:hover, &:active {
		color: #646464;
		z-index: 3;
		background-color: #cbcbcbba !important;
	}
`;

// Column resizer disable div style
export const StyledColumnResizeDisableDiv = styled.span`
	flex: 0 0 .2em;
	display: flex;
	color: #cbcbcb;
	z-index: 2;
`;

// Search field container style
export const StyledSearchContainer = styled.div`
	position: relative;
	margin-left: 1.5em;
	&>.MuiInputBase-root:before {
		border-bottom: 0px solid rgba(0, 0, 0, 0.22);
	}
	&>.MuiInputBase-root:hover:not(.MUI-disabled):before {
		border-bottom: 0px solid rgba(0, 0, 0, 0.22);
	}
	&>.MuiInputBase-root:after {
		border-bottom: 0px solid #1976d2;
	}
`;

// Search input field style
export const StyledSearchInput = styled(Input)`
	color: inherit;
	width: 14em;
`;

// Search Prev/Next Container
export const StyledSearchPrevNextContainer = styled.div`
	border-top: 1px solid rgba(0, 0, 0, 0.22);
	border-bottom: 1px solid rgba(0, 0, 0, 0.22);
	display: flex;
	position: absolute;
	width: 100%;
	background: #ffffaf;
	padding: 8px;
	z-index: 999;
	&>button {
		padding: 1px;
	}
`;

export const StyledListItem = styled(MenuItem)`
	font-size: 1.1em !important;
    padding-top: 4px;
    padding-bottom: 4px;
	&>.MuiCheckbox-root {
		padding: 2px;
		margin-right: 4px;
	}
`;