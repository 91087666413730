import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import MuiAlert from '@mui/material/Alert';
import { FormControl, InputLabel, MenuItem, Select, Snackbar, Autocomplete, TextField } from '@mui/material';
import { useWindowDimensions } from '../hooks/hook.window.dimensions';
import { SalesCalendar } from '../components/component.sales.calendar';
import { EndOfMonthDaysService } from '../services/service.sales.endofmonth';
import { env } from '../utils/env.variables';
// include utils module
require('../utils/utils');


/**
 * Styled components
 */
// Table main container
const Container = styled.div`
	display: flex;
	margin-top: 8px;
    // border: 1px solid;
`;
// Table row container
const TableRowContainer = styled.div`
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    display: inline-block;
    margin: 0 auto;
`;
// Caption bar div style
const StyledCaptionBar = styled.div`
	display: flex;
	margin: 0 0 1em;
	padding: 0 .1em;
`;
// Table title style
const Title = styled.h4`
	margin: 0;
	align-items: center;
    display: flex;
	flex-grow: 1;
	font-size: 1rem;
	user-select: none;
`;
// Distributor type div style
/* const StyledDropDown = styled(FormControl)`
	min-width: 8%;
	margin-left: 1.5em;
	&>label {
		top: -16px;
	}
	&>.MuiInputBase-root {
		margin-top: 0;
		&:before {
			border-bottom: 0px solid rgba(0, 0, 0, 0.22);
		}
	}
	&>.MuiInputBase-root:hover:not(.MUI-disabled):before {
		border-bottom: 0px solid rgba(0, 0, 0, 0.22);
	}
	&>.MuiInputBase-root:after {
		border-bottom: 0px solid #1976d2;
	}
`; */
const StyledDropDown = styled(FormControl)`
    min-width: 8%;
    margin-left: 1.5em;
    &>label {
        top: -16px;
    }
    &>.MuiInputBase-root {
        margin-top: 0;
        &:before {
            border-bottom: 0px solid rgba(0, 0, 0, 0.22);
        }
    }
    &>.MuiInputBase-root:hover:not(.MUI-disabled):before {
        border-bottom: 0px solid rgba(0, 0, 0, 0.22);
    }
    &>.MuiInputBase-root:after {
        border-bottom: 0px solid #1976d2;
    }
    &>.MuiAutocomplete-root >.MuiTextField-root {
        margin-top: 0;
        margin-bottom: 4px;
        &>:before {
            border-bottom: 0px solid rgba(0, 0, 0, 0.22);
            font-size: 0.775rem;
        }
        &>:after {
            border-bottom: 0px solid #1976d2;
            font-size: 0.775rem;
        }
        &>:hover:not(.MUI-disabled):before {
            border-bottom: 0px solid rgba(0, 0, 0, 0.22);
        }
    }
`;
// Calendar place holder
const CalendarPlaceHolderDiv = styled.div`
	display: inline-block;
    padding: .1em 0;
    width: 100%;
`;


// Severity level - error, info, success, warning
const severity_level = {
    error: 'error',
    info: 'info',
    success: 'success',
    warning: 'warning'
};


// Sales Calendar - End of Month
export const SalesCalEndOfMonth = (props) => {
    const {dimensions: tableDimension/* leftDivRef */, graphData: userInfo} = props;
	/* const {width} = useWindowDimensions();
    const _width = ((width - (leftDivRef?.current?.clientWidth || 182)) - 34);
    const _height = ((leftDivRef?.current?.clientHeight || 500) - 170);
    const tableDimension = {width: _width, height: _height}; */

    const [years, setYears] = useState();
    const [yearSelected, setYearSelected] = useState();
    const [monthSelected, setMonthSelected] = useState();
    const [jdeAddrBookNo, setJdeAddrBookNo] = useState();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState({
        severity: severity_level.error, 
        message: ''
    });
    const [endOfMonthDaysData, setEndOfMonthDaysData] = useState();
    const [endOfMonthDate, setEndOfMonthDate] = useState({d1:'', d2: ''});
    const [allDistributorsData, setAllDistributorsData] = useState();
    const [isCalEventEnabled, setIsCalEventEnabled] = useState(false);

    // Show Snackbar message
    const showSnackbarMessage = useCallback((_message, _severity) => {
        setSnackbarMessage({
            severity: _severity ? _severity : snackbarMessage.severity, 
            message: _message
        });
        setOpenSnackbar(true);
    }, [snackbarMessage.severity]);

    // Get Fiscal year list
    const getFiscalYears = useCallback(() => {
        let currentYear = new Date().getFullYear(),
            startYear = currentYear - 2,
            endYear = currentYear + 1;
        const yearRangeArr = startYear.range(endYear);

        return yearRangeArr;
    }, []);

    // Get all End Of Month Days data
    const getEndOfMonthDaysData = useCallback(async (jdeAbNo, year, month) => {
        let currDate = new Date(),
            currYear = currDate.getFullYear(),
            _month = month || (currYear === year ? currDate.getMonth() + 1 : 1);
        // console.log('getEndOfMonthDaysData>>>', jdeAbNo, year, _month);
        (jdeAbNo && year && _month) && 
            await EndOfMonthDaysService.getEndOfMonthDaysData({jdeAbNo: jdeAbNo, year: year, month: _month})
                .then(response => {
                    // console.log('getEndOfMonthDaysData-response>>>', response);
                    const {status, rows} = response.data;
                    if (rows[0]?.DTE_T) {
                        const _dtEom = new Date(rows[0]?.DTE_T);
                        // console.log(_dtEom, _dtEom.getFullYear(), _dtEom.getMonth(), _dtEom.getDate());
                        setEndOfMonthDate({
                            d1: _dtEom,
                            d2: `${_dtEom.getFullYear()}-${('00' + (_dtEom.getMonth() + 1)).slice(-2)}-${('00' + _dtEom.getDate()).slice(-2)}`
                        });
                    }
                    setEndOfMonthDaysData(rows || []);
                    if (!status.isOk) {
                        showSnackbarMessage(env.getEnv('REACT_APP_MSG_RETRIEVE_FAILED'), severity_level.error);
                    }
                })
                .catch(error => {
                    showSnackbarMessage((error?.response?.data?.message || error?.message), severity_level.error);
                });
    }, [showSnackbarMessage]);

    // Distributor Change Callback defination
    const onDistributorChangeCallback = useCallback((e, dwValue) => {
        // console.log('onDistributorChangeCallback > ', dwValue);
        setJdeAddrBookNo(dwValue);
        setIsCalEventEnabled(!!(dwValue?.id && yearSelected));

        // Get End of Month Days Data
        (async () => {
            setShowLoader(true);
            await getEndOfMonthDaysData(dwValue?.id, yearSelected, monthSelected);
            setShowLoader(false);
        })();
    }, [yearSelected, monthSelected, getEndOfMonthDaysData]);

    // Year Change Callback defination
    const onYearChangeCallback = useCallback((e) => {
        // console.log('onYearChangeCallback > ', e);
        setYearSelected(e.target.value);
        setIsCalEventEnabled(!!(jdeAddrBookNo?.id && e.target.value));

        // Get End of Month Days Data
        (async () => {
            setShowLoader(true);
            await getEndOfMonthDaysData(jdeAddrBookNo?.id, e.target.value, monthSelected);
            setShowLoader(false);
        })();
    }, [jdeAddrBookNo, monthSelected, getEndOfMonthDaysData]);

    // Day click callback
    const onCalendarDayClickCallback = useCallback((result) => {
        setShowLoader(true);
        let newResultData = [];
        result?.forEach(item => {
            const _dtEom = new Date(item.N_DATE),
                // targetEndDate = `${_dtEom.getFullYear()}-${('00' + (_dtEom.getMonth() + 1)).slice(-2)}-${('00' + _dtEom.getDate()).slice(-2)}`,
                targetEndDate = `${item.NT_DATE.y}-${('00' + (item.NT_DATE.m + 1)).slice(-2)}-${('00' + item.NT_DATE.d).slice(-2)}`;
            
            newResultData.push({
                ...item,
                usrid: userInfo.upn,
                SELECTED_YEAR: yearSelected,
                SELECTED_MONTH: monthSelected,
                N_JDE_ADDR_BOOK_NO: jdeAddrBookNo?.id,
                P_START_DATE: endOfMonthDate.d1 > _dtEom ? targetEndDate : endOfMonthDate.d2,
                P_END_DATE: endOfMonthDate.d1 > _dtEom ? endOfMonthDate.d2 : targetEndDate
            });
        });
        
        (async () => {
            await EndOfMonthDaysService.updateEndOfMonthDays(newResultData)
                .then(response => {
                    const {status, rows} = response.data;
                    if (status.isOk) {
                        if (rows[0]?.DTE_T) {
                            const _dtEom = new Date(rows[0]?.DTE_T);
                            setEndOfMonthDate({
                                d1: _dtEom,
                                d2: `${_dtEom.getFullYear()}-${('00' + (_dtEom.getMonth() + 1)).slice(-2)}-${('00' + _dtEom.getDate()).slice(-2)}`
                            });
                        }
                        setEndOfMonthDaysData(rows || []);
                        showSnackbarMessage(env.getEnv('REACT_APP_MSG_EOM_UPDATE_SUCCESS'), severity_level.success);
                    }
                    else {
                        showSnackbarMessage(env.getEnv('REACT_APP_MSG_RETRIEVE_FAILED'), severity_level.error);
                    }
                })
                .catch(error => {
                    showSnackbarMessage((error?.response?.data?.message || error?.message), severity_level.error);
                })
                .finally(() => {
                    setShowLoader(false);
                });
        })();
    }, [jdeAddrBookNo, yearSelected, monthSelected, userInfo, endOfMonthDate, showSnackbarMessage]);

    // Month click callback
    const onCalendarMonthClickCallback = useCallback((result) => {
        // console.log('onCalendarMonthClickCallback>>>>', result);
        const {monthNo} = result;
        setMonthSelected(monthNo);

        // Get End of Month Days Data
        (async () => {
            setShowLoader(true);
            await getEndOfMonthDaysData(jdeAddrBookNo?.id, yearSelected, monthNo);
            setShowLoader(false);
        })();
    }, [jdeAddrBookNo, yearSelected, getEndOfMonthDaysData]);

    // Close Snackbar
	const onCloseSnackbarCallback = useCallback((event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
    	setOpenSnackbar(false);
        setSnackbarMessage({severity: snackbarMessage.severity, message: ''});
    }, [snackbarMessage.severity]);

    // userEffect hook to load data if changes
    useEffect(() => {
        setYears(getFiscalYears());
        setYearSelected(new Date().getFullYear());

        // Get distributors data
        (async () => {
            setShowLoader(true);
            await EndOfMonthDaysService.getAllDistributorsData()
                .then(response => {
                    const {status, rows} = response.data;
                    setAllDistributorsData(rows || []);
                    if (!status.isOk) {
                        showSnackbarMessage(env.getEnv('REACT_APP_MSG_RETRIEVE_FAILED'), severity_level.error);
                    }
                })
                .catch(error => {
                    showSnackbarMessage((error?.response?.data?.message || error?.message), severity_level.error);
                })
                .finally(() => {
                    setShowLoader(false);
                });
        })();
    }, [getFiscalYears, showSnackbarMessage]);

    // Customized Properties
    const customProps = {
        title: 'Sales Calendar - End of Month',
        dimensions: tableDimension,
        dwYears: years,
        showLoader: showLoader,
        dwYear: yearSelected,
        dwMonth: monthSelected,
        jdeAbNo: jdeAddrBookNo,
        endOfMonthDays: endOfMonthDaysData,
        isCalEventEnabled: isCalEventEnabled,
        allDistributors: allDistributorsData,
        callbacks: {
            onYearChange: onYearChangeCallback,
            onDistributorChange: onDistributorChangeCallback,
            onCalendarDayClick: onCalendarDayClickCallback,
            onCalendarMonthClick: onCalendarMonthClickCallback
        },
        snackbar: {
            duration: 5000,
            isOpen: openSnackbar,
            messages: snackbarMessage,
            callbacks: {
                onCloseSnackbar: onCloseSnackbarCallback
            }
        }
    }

    return (
        <Container>
            <CalendarContainer {...customProps} />
            <SnackbarAlert {...customProps.snackbar} />
        </Container>
    )
}

// Main Calendar Container
const CalendarContainer = React.memo(props => {
    return (
        <TableRowContainer /* role="grid" */>
            <TableCaptionBar {...props} />
            <CalendarPlaceHolder {...props} />
        </TableRowContainer>
    )
})

// Table Caption Bar - Title / Search
const TableCaptionBar = React.memo(props => {
    const {
        title,
        dwYear,
        dwYears,
        jdeAbNo,
        allDistributors,
        callbacks: {onYearChange, onDistributorChange}
    } = props;

    return (
        <StyledCaptionBar>
            <Title>{title} (Year - {dwYear})</Title>

            <StyledDropDown variant="standard" style={{marginTop: '-1rem', minWidth: '28%'}}>
                <Autocomplete 
                    options={(allDistributors || []).map(el => ({
                        id: el.JDE_ADDR_BOOK_N,
                        label: `${el.DIST_DESC} (${el.JDE_ADDR_BOOK_N})`
                    }))}
                    id="dist-select-label" value={jdeAbNo || ''}
                    onChange={onDistributorChange}
                    isOptionEqualToValue={(o, v) => (v === undefined || v === '' || o?.id === v?.id || v.id === '')}
                    renderInput={(params) => <TextField {...params} label="Distributors" margin="normal" variant="standard"/>}/>
            </StyledDropDown>

            <StyledDropDown variant="standard">
                <InputLabel id="year-input-label">Year</InputLabel>
                <Select id="year-select-label" labelId="year-select-label" defaultValue={''} 
                    value={dwYear || ''} name="DW_YEAR" label="Year" onChange={onYearChange}>
                    {(dwYears || []).map((year, i) => (
                        <MenuItem key={`dwy-${year}-${i}`} value={year}>{year}</MenuItem>
                    ))}
                </Select>
            </StyledDropDown>
        </StyledCaptionBar>
    )
})

// Calendar Place Holder
const CalendarPlaceHolder = React.memo(props => {
    const {
        dwYear,
        dwMonth,
        showLoader,
        endOfMonthDays,
        isCalEventEnabled,
        callbacks: {onCalendarDayClick, onCalendarMonthClick}
    } = props;
    const calendarCallbacks = {
        onCalendarDayClick: onCalendarDayClick,
        onCalendarMonthClick: onCalendarMonthClick
    }
    
    return (
        <CalendarPlaceHolderDiv>
            <SalesCalendar 
                isCalEventEnabled={isCalEventEnabled} 
                calendarDays={endOfMonthDays} 
                showLoader={showLoader} 
                callbacks={calendarCallbacks} 
                year={dwYear} 
                month={dwMonth}
                showNextMonth={true} 
                calType="EOM"/>
        </CalendarPlaceHolderDiv>
    )
})

// Snackbar Alert Container
const SnackbarAlert = React.memo(props => {
    const {
        isOpen,
        duration,
        messages: {severity='info', message=''},
        callbacks: {onCloseSnackbar}
    } = props;
    
    // MUI Alert - for Snackbar
    const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant={'filled'} {...props} />);

    return (
        <Snackbar open={isOpen} autoHideDuration={duration} onClose={onCloseSnackbar}>
            <Alert onClose={onCloseSnackbar} severity={severity} sx={{width: '100%'}}>{message}</Alert>
        </Snackbar>
    )
})
