import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './apps.main';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './auth/azure.auth.config';
import { PublicClientApplication, EventType } from '@azure/msal-browser';


// Initiate msal instance
const pca = new PublicClientApplication(msalConfig);

// Event callback
pca.addEventCallback(event => {
	if (event.eventType === EventType.LOGIN_SUCCESS) {
		pca.setActiveAccount(event.payload.account);
	}
});

// Get root container
const _root = ReactDOM.createRoot(
	document.getElementById('root')
);

// Render App - StrictMode drag/drop not working - <React.StrictMode>
_root.render(
	<MsalProvider instance={pca}>
		<App />
	</MsalProvider>
);
