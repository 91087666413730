// JS format string - ES6 (EcmaScript2015)
// Example - 'The {0} is dead. Don\'t code {0}. Code {1} that is open source!'.format('ASP', 'nodejs');
// eslint-disable-next-line no-extend-native
String.prototype.format = function() {
    return [...arguments].reduce((p, c, i) => p.replace(new RegExp('\\{' + i + '\\}', 'gi'), c), this);
};

// Convert to array of numbers
// Example - '41, 42, 43, 44, 45, 46, 47, 49, 50, 51, 52, 53'.convertArray();
// eslint-disable-next-line no-extend-native
String.prototype.convertNumberArray = function() {
	return this.split(',').map(Number);
}

// Capitalize first letter of a given string
// Example - 'test'.capitalize(); // Test
// eslint-disable-next-line no-extend-native
String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
}

// Compute an arbitrary color for user avatar starting from their username
// Example - 'Test'.toHslColor(); / 'Test'.toHslColor(50, 50);
// eslint-disable-next-line no-extend-native
String.prototype.toHslColor = function(saturation, lightness) {
    let hash = 0,
        s = saturation ? saturation : '50',
        l = lightness ? lightness : '50';
    
    for (var i = 0; i < this.length; i++) {
        hash = this.charCodeAt(i) + ((hash << 5) - hash);
    }

    return `hsl(${hash % 360}, ${s}%, ${l}%)`;
}

// Create arrays with range - start and end
// Example - 2021.range(2023);  [2021, 2022, 2023]
// eslint-disable-next-line no-extend-native
Number.prototype.range = function(end) {
    return Array.from({length: end - this + 1}, (_, i) => this + i);
}


// Snackbar Config
export const SnackbarCfg = {
    duration: 5000,
    message: null,
    open: false,
    severity: '',
    state: null,

    // Severity level - error, info, success, warning
    severities: {
        error: 'error',
        info: 'info',
        success: 'success',
        warning: 'warning'
    },
    setState: function({state}) {
        this.state = state;
    },
    setSeverity: function(severity) {
        this.severity = severity;
        return this;
    },
    setMessage: function(message) {
        this.message = message;
        return this;
    },
    show: function() {
        this.open = true;
        this.state && 
            this.state(this.open);
    },
    close: function() {
        this.open = false;
        this.state && 
            this.state(this.open);
    }

    // Set message
    /* setMessage: function(message, open, severity) {
        this.severity = severity || this.severity_level.info;
        this.message = message;
        this.open = open;
    }, */
}

// Constants Config
export const ConstCfg = {
    Colors: {
        LIGHT_GREY: '#9e9e9e',
        LIGHT_YELLOW: '#ffff3230'

    },
    Widths: {
        ACTION_COLUMN: 96,
        SCROLL_BAR: 15,
        
    },
    _FLD_ALL: 'FLD_ALL',
    _FLD_PREFIX: 'NV_'
}

// Popup Dialog Config
export const DialogCfg = {
    // Disable / enable button
    onToggleButtonState: function(btnRefs) {
        console.log(btnRefs);
        btnRefs?.forEach(btnRef => {console.log('btnRef>', btnRef);
            if (!btnRef.ref?.current) {
                return;
            }

            // Disable buttons
            btnRef.ref.current.disabled = btnRef.state || false;
            if (btnRef.state) {
                btnRef.ref.current.classList.add('Mui-disabled');
            }
            else {
                btnRef.ref.current.classList.remove('Mui-disabled');
            }
        });
    }
}

// Grid configuration
export const GridCfg = {
    _ACT_COL: '_ACT_COL',
    _ACT_COL_WIDTH: 1,

    // Set action column name/width
    setActionColumnProps: function({column, width}) {
        this._ACT_COL = column || this._ACT_COL;
        this._ACT_COL_WIDTH = width || this._ACT_COL_WIDTH;
    },

    // State function type
    /* stateFnTypes: {
        gridDim: null,
        columns: [],
        stateRowData: null,
        stateColWidths: null,
        stateSortColumns: null
    }, */

    // Set state function to set states
    /* setStateFns: function(props) {
        this.stateFnTypes = {
            gridDim: props?.gridDim,
            columns: props?.columns,
            stateRowData: props?.stateRowData,
            stateColWidths: props?.stateColWidths,
            stateSortColumns: props?.stateSortColumns
        }
    }, */

    // Resize colums
    fnResizeColumn: function(e, props) {
        let nextDataKey = '';
        const {dataKey, deltaX, columns, gridWidth, setColWidths} = props;
        const percentDelta = (deltaX / gridWidth) * 1000;
        columns.forEach((item, i) => {
            if (item.field === dataKey) {
                nextDataKey = columns[i + 1]?.field || this._ACT_COL;
            }
        });
        setColWidths(prevWidths => ({
            ...prevWidths,
            [dataKey]: prevWidths[dataKey] + percentDelta,
            [nextDataKey]: prevWidths[nextDataKey] - percentDelta
        }));
    },

    // Sort row by column
    fnSortColumn: function(e, props) {
        const {
            sortColumns, 
            setRowData, 
            setSortColumns,
            sortCol: sortColumnName, 
            altSortCol: altSortColumnName
        } = props;

        let {sortBy, sortOrder} = sortColumns,
            sortColumnField = altSortColumnName || sortColumnName;
        
        if (sortBy === sortColumnField) {
            sortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        }
        else {
            sortBy = sortColumnField;
            sortOrder = 'asc';
        }
        // Set column ordering
        setSortColumns(prev => ({
            ...prev,
            sortBy: sortBy,
            sortOrder: sortOrder
        }));
        // Set sorted data
        setRowData(prev => ({
            ...prev,
            cdata: prev.cdata?.sort((i, j) => {
                if (i[sortColumnField] < j[sortColumnField]) {
                    return sortOrder === 'asc' ? -1 : 1;
                }
                if (i[sortColumnField] > j[sortColumnField]) {
                    return sortOrder === 'asc' ? 1 : -1;
                }
                return 0;
            })
        }));
        
        
        /* 
        this.stateFnTypes.stateSortColumns(prev => ({
            ...prev,
            sortBy: sortBy,
            sortOrder: sortOrder
        })); */

        
        /* this.stateFnTypes.stateRowData(prev => prev.sort((i, j) => {
            if (i[sortColumnField] < j[sortColumnField]) {
                return sortOrder === 'asc' ? -1 : 1;
            }
            if (i[sortColumnField] > j[sortColumnField]) {
                return sortOrder === 'asc' ? 1 : -1;
            }
            return 0;
        })); */
    },

    // Calculate and set columns width
    fnSetGridColumnWidths: function({columns, options}) {
        // const _gridWidth = (gridWidth - this._ACT_COL_WIDTH);
        // console.log(_gridWidth, gridWidth);

        // Column width
        let newGridColumnWidths = {
            // [this._ACT_COL]: this._ACT_COL_WIDTH / 1000
            [this._ACT_COL]: options?.action ? this._ACT_COL_WIDTH : 1
        };

        columns.forEach(item => {
            newGridColumnWidths = {
                ...newGridColumnWidths,
                // [item.field]: (EQUAL_COL_WITH + (item?.visible && item?.width || 0))/1000 || 0.01
                // [item.field]: item?.visible ? ((EQUAL_COL_WITH + (item?.width || 0)) / 1000 || 0.01) * gridWidth : 10
                [item.field]: item?.visible ? (item?.width || 0) : 0
            }
		});
        // console.log('>newGridColumnWidths>', newGridColumnWidths);


        // Total visible columns in the grid
        // const totalVisibleCols = columns.reduce((prev, curr) => curr?.visible ? prev + 1 : prev, 0) || 1;

        // Total visible columns width in the grid
        // const totalColsWidth = columns.reduce((prev, curr) => curr?.visible && curr?.width ? prev + curr?.width : prev, 0);
        // console.log('totalColsWidth/totalVisibleCols>', this._ACT_COL_WIDTH, totalColsWidth, totalVisibleCols);

        // Calculate equal width for each column
        // const EQUAL_COL_WITH = (_gridWidth - totalColsWidth) / totalVisibleCols;
        // console.log('EQUAL_COL_WITH>', EQUAL_COL_WITH);

		/* columns.forEach(item => {
            console.log('XXX->', ((EQUAL_COL_WITH + (item?.width || 0)) / 1000 || 0.01));
            newGridColumnWidths = {
                ...newGridColumnWidths,
                // [item.field]: (EQUAL_COL_WITH + (item?.visible && item?.width || 0))/1000 || 0.01
                // [item.field]: item?.visible ? ((EQUAL_COL_WITH + (item?.width || 0)) / 1000 || 0.01) * gridWidth : 10
                [item.field]: item?.visible ? EQUAL_COL_WITH + (item?.width || 0) : 0
            }
		});
        console.log('>newGridColumnWidths>', newGridColumnWidths); */
        return newGridColumnWidths;
    }

}
