import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { List } from 'react-virtualized';
import styled from '@emotion/styled';
import { Input, Select, InputAdornment, FormControl, InputLabel, MenuItem, Backdrop, Menu, ListItemText, ListItemIcon, TableSortLabel } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragHandleRoundedIcon from '@mui/icons-material/DragHandleRounded';
import CircularProgress from '@mui/material/CircularProgress';
import SearchIcon from '@mui/icons-material/Search';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import IconButton from '@mui/material/IconButton'
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ClearIcon from '@mui/icons-material/Clear';
import { env } from '../utils/env.variables';
import * as ColumnResizer  from 'react-draggable';


// Table mail container
const Container = styled.div`
	display: flex;
	margin-top: 8px;
`;
// Table row container
const TableRowContainer = styled.div`
	border-top-left-radius: 2px;
	border-top-right-radius: 2px;
	display: inline-block;
`;
// Table title style
const Title = styled.h4`
	margin: 0;
	flex-grow: 1;
	user-select: none;
	align-items: center;
    display: flex;
	font-size: 1rem;
`;
// Item row container style
const RowContainer = styled.div`
	display: flex;
	align-items: center;
	user-select: none;
	border-bottom: 1px solid #dfdfdf;
	flex-direction: row;
`;
// Loading div style
const StyledLoadingDiv = styled.div`
	display: flex;
	align-items: center;
	padding: 1em 0.6em;
	color: #979797;
	font-size: 0.9rem;
	&>span {
		margin-right: 0.6em;
	}
`;
// Search field container style
const Search = styled.div`
	position: relative;
	margin-left: 1.5em;
	&>.MuiInputBase-root:before {
		border-bottom: 0px solid rgba(0, 0, 0, 0.22);
	}
	&>.MuiInputBase-root:hover:not(.MUI-disabled):before {
		border-bottom: 0px solid rgba(0, 0, 0, 0.22);
	}
	&>.MuiInputBase-root:after {
		border-bottom: 0px solid #1976d2;
	}
`;
const StyledSearchInput = styled(Input)`
	color: inherit;
	width: 14em;
`;
// Search Prev/Next Container
const SearchPrevNextContainer = styled.div`
	border-top: 1px solid rgba(0, 0, 0, 0.22);
	border-bottom: 1px solid rgba(0, 0, 0, 0.22);
	display: flex;
	position: absolute;
	width: 100%;
	background: #ffffaf;
	padding: 8px;
	z-index: 999;
	&>button {
		padding: 1px;
	}
`;
// Column field name / data div style
const ColumnContainerDiv = styled.div`
	display: flex;
  	flex-direction: row;
  	align-items: center;
	background-color: #f2f2f2;
	border-top: 1px solid #cbcbcb;
	border-bottom: 1px solid #cbcbcb;
    height: 3em;
	padding-right: 1.2em;
	user-select: none;
`;
// Column resizer div style
const StyledColumnResizeHandler = styled.span`
	flex: 0 0 .2em;
    display: flex;
	cursor: col-resize;
	color: #cbcbcb;
	z-index: 2;
	background-color: inherit !important;
	&:hover, &:active {
		color: #646464;
		z-index: 3;
		background-color: #cbcbcbba !important;
	}
`;
// Column resizer disable div style
const StyledColumnResizeDisableDiv = styled.span`
	flex: 0 0 .2em;
	display: flex;
	color: #cbcbcb;
	z-index: 2;
`;
// Column filter div style
const FilterContainerDiv = styled.div`
	display: flex;
  	flex-direction: row;
  	align-items: center;
	padding: 0.3em 1.2em 0.3em 0;
	border-bottom: 1px solid #cbcbcb7a;
	background-color: #f1f1f159;
	user-select: none;
`;
// Body row field data div style
const FilterItemDiv = styled.div`
	align-items: center;
	text-overflow: ellipsis;
	white-space: nowrap;
	margin-right: .5em;
	min-width: 0px;
	height: 100%;
	display: flex;
	flex-direction: row;
	&:last-of-type {
		margin-right: 0;
	}
	&>.MuiInputBase-root {
		width: 100%;
		max-width: 96%;
	}
	&>.MuiInputBase-root:before {
		border-bottom: 1px solid rgba(0, 0, 0, 0.22);
	}
	&>.MuiInputBase-root:hover:not(.MUI-disabled):before {
		border-bottom: 1px solid rgba(0, 0, 0, 0.22);
	}
	&>.MuiInputBase-root:after {
		border-bottom: 1px solid #1976d2;
	}
`;
// Column filter div style
const FilterInput = styled(Input)`
	background-color: #ffffff;
`;
// Body row field data div style
const RowItemDiv = styled.div`
	display: flex;
	align-items: center;
	text-overflow: ellipsis;
    white-space: nowrap;
	margin-right: .5em;
    min-width: 0px;
    height: 100%;
	&:last-of-type {
		margin-right: 0;
	}
`;
// No records found div
const StyledNoRecordDiv = styled.div`
	white-space: nowrap;
	display: grid;
	font-size: 0.875rem;
	padding: 6em;
	text-align: center;
	color: #979797;
`;
// Report type div style
const StyledReportType = styled(FormControl)`
	min-width: 140px;
	margin-left: 1.5em;
	&>label {
		top: -16px;
	}
	&>.MuiInputBase-root {
		margin-top: 0;
		&:before {
			border-bottom: 0px solid rgba(0, 0, 0, 0.22);
		}
	}
	&>.MuiInputBase-root:hover:not(.MUI-disabled):before {
		border-bottom: 0px solid rgba(0, 0, 0, 0.22);
	}
	&>.MuiInputBase-root:after {
		border-bottom: 0px solid #1976d2;
	}
`;
// Caption bar div style
const StyledCaptionBar = styled.div`
	width: 100%;
	display: flex;
	margin: 0 0 1em;
	padding: 0 .1em;
	max-width: 100%;
`;
// Drag handler div style
const StyledDragHandler = styled.div`
	display: flex;
	flex: 0 1 69px;
    height: 100%;
	align-items: center;
	justify-content: center;
	margin-right: .5em;
`;
// Action Menu - cut/copy/paste handler div style
const StyledActionMenuDiv = styled.div`
	display: flex;
	margin-left: 1.5em;
	align-items: center;
	& .MuiIconButton-root {
		padding: 0 5px;
	}
	& .MuiIconButton-root:last-child {
		padding: 0 0 0 5px;
		& .MuiSvgIcon-root {
			width: 0.94em !important;
			height: 0.94em !important;
		}
	}
`;
// Overlay loader div style
const StyledLoaderDiv = styled.div`
	background-color: #3ea2e5;
	display: flex;
	align-items: center;
	border-radius: 4px;
	padding: 0.8em;
	color: #ffffff;
`;
// Context Menu div style
const StyledContextMenuDiv = styled.div`
	cursor: context-menu;
`;
// Column sort div style
const StyledColumnSortDiv = styled(TableSortLabel)`
	flex: auto;
	display: inline-flex;
	align-items: center;
	max-width: 100%;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	background-color: inherit !important;
`;
// Row item text style div
const StyledRowItemText = styled.div`
	flex: auto;
	display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
	& >span {
		background-color: #ffff66;
	}
`;


// Table Row Cut Copy background colors
const tableRowCutCopyColors = {
	_cutCopyColor1: '#71b8ff', 
	_cutCopyColor2: '#ffe18e', 
	_cutCopyColor3: '#d8ecff',
	_COLOR4: '#fbff0033',
	_COLOR5: '#afafaf33'
};

// Grid row scroll to alignment
const ScrollToAlignmentCfg = {
	AUTO: 'auto',
	CENTER: 'center',
	START: 'start',
	END: 'end'
};

// Default action column with
const ACTION_COLUMN_WITH = 116;


// SMIS Material data table component
export const SMISDataTableGeo = (props) => {
	const {rows, columns, miscActions: {onDragRow, onCutPasteRow}, alertMessage, extras, contextMenuItems, searchColumns, tableDimension} = props;
	const {isModifyEnabled, rowPositionId} = extras;
	const tableRef = useRef(null);
	const searchInputRef = useRef(null);

	const [rowData, setRowData] = useState();
	const [propRows, setPropRows] = useState();
	const [propRptRows, setPropRptRows] = useState();
	const [scrollToIndex, setScrollToIndex] = useState(-1);
	const [scrollToAlignment, setScrollToAlignment] = useState(ScrollToAlignmentCfg.AUTO);
	const [matchedSearchIds, setMatchedSearchIds] = useState([]);
	const [matchedSearchCount, setMatchedSearchCount] = useState({
		searchPrevNextCount: 0, 
		searchTotalCount: 0, 
		searchPrevBtnDisable: true, 
		searchNextBtnDisable: true
	});
	const [searchSelectedIndex, setSearchSelectedIndex] = useState();
	const [searchInputText, setSearchInputText] = useState();
	const [rptTypeValue, setRptTypeValue] = useState('');
	const [cutPasteActionState, setCutPasteActionState] = useState({
		copy: {disable: true, clicked: false},
		cut: {disable: true, clicked: false},
		paste: {disable: true, clicked: false},
		cancel: {disable: true, clicked: false}
	});
	const [cutPasteItems, setCutPasteItems] = useState({
		lineNos: {
			startLnNo: 0,
			endLnNo: 0
		},
		itemIndexes: [],
		indexItems: [],
		sourceItems: [],
		destinationItems: {
			destinationItemPosition: 0, 
			destinationItem: null
		},
		cutPasteActionType: ''
	});
	const [cutPasteActionColors, setCutPasteActionColors] = useState({color1: '', color2: ''});
	const [filterObj, setFilterObj] = useState(columns.reduce((item, {field}) => {item[field] = ''; return item;}, {}));
	const [contextMenu, setContextMenu] = useState(null);
	const [sortColumns, setSortColumns] = useState({
		sortBy: '',
		sortOrder: 'asc'
	});
	const [columnWidths, setColumnWidths] = useState({_ACT_COL: ACTION_COLUMN_WITH / 1000});

	// Get filtered row data
	const getFilteredRowData = useCallback((rptType, fltrObj) => {
		const _reportType = rptType?.length >= 0 ? rptType : rptTypeValue;
		const _fltrObj = fltrObj || filterObj;

		// Report type - if selected
		let propRowsNew = [];
		(propRows || []).forEach(item => {
			if (!_reportType || item.ROLL_UP_I === _reportType) {
				propRowsNew.push(item);
			}
		});

		// Filter value - if any
		const _propRowsNew = propRowsNew.filter(props => 
			Object
				.entries(_fltrObj)
					.every(([key, val]) => 
						!val?.length || (props[key] || '')?.toString().toLowerCase().includes((val || '').toLowerCase())
					)
		);

		return _propRowsNew;
	}, [propRows, rptTypeValue, filterObj]);

	// Handle report types
	const handleRptTypes = useCallback(({target: {_, value}}) => {
		// Clear search
		setScrollToIndex(-1);
		setSearchInputText('');
		setSearchSelectedIndex(0);
		setMatchedSearchCount({
			searchPrevNextCount: 0, 
			searchTotalCount: 0, 
			searchPrevBtnDisable: true, 
			searchNextBtnDisable: true
		});
		searchInputRef.current.value = '';
		
		setRptTypeValue(value);
		const _filteredRowData = getFilteredRowData(value, undefined);

		if (!(value || '').length) {
			setRptTypeValue('');
			// setRowData(propRows);
			setRowData(_filteredRowData);
			return;
		}

		let propRowsNew = [];
		/* propRows */_filteredRowData.forEach(item => {
			if (item.ROLL_UP_I === value) {
				propRowsNew.push(item);
			}
		});
		setRowData(propRowsNew);
	}, [/* propRows */getFilteredRowData]);

	// Handle filter change
	const handleFilterChange = useCallback(({target: {name, value}}) => {
		const newFilterObj = {...filterObj, [name]: value};
		setFilterObj(newFilterObj);
		
		const _filteredRowData = getFilteredRowData(undefined, newFilterObj);
		setRowData(/* propRows */_filteredRowData.filter(props => 
			Object
				.entries(newFilterObj)
					.every(([key, val]) => 
						!val?.length || (props[key] || '')?.toString().toLowerCase().includes((val || '').toLowerCase())
					)
		));
	}, [/* propRows,  */filterObj, getFilteredRowData]);

	// DragDropContext callbacks defination
	const handleDragEnd = useCallback((result) => {
		if (!isModifyEnabled) {
			return false;
		}
		const {source, destination} = result;

		// return if dropped outside the list or content loading
		if (!destination) {
			return;
		}

		// Did not move anywhere - can bail early
	    if (source.droppableId === destination.droppableId
			&& source.index === destination.index) {
				return;
	    }

		// Determine the movement direction
		let _index1 = destination.index,
			_index2 = 0;
		if (source.index < destination.index) {
			_index2 = _index1 + 1;
			_index2 = _index2 > rowData.length ? rowData.length : _index2;
			result = {...result, direction: 'DOWN', move: {index1: _index1, index2: _index2}};
		}
		else {
			_index2 = _index1 - 1;
			_index2 = _index2 > 0 ? _index2 : 0;
			result = {...result, direction: 'UP', move: {index1: _index2, index2: _index1}};
		}

		// Callback
		if (onDragRow) {
			onDragRow({...result, rowData: rowData, onReorderItemsCallback: reorderItems});
		}
	}, [rowData, isModifyEnabled, onDragRow]);

	// Handle clear search field
	const handleClearSearch = useCallback((e) => {
		const _filteredRowData = getFilteredRowData();
		setScrollToIndex(-1);
		// setRowData(propRows);
		setRowData(_filteredRowData);
		setSearchInputText('');
		setSearchSelectedIndex(0);
		setMatchedSearchCount({
			searchPrevNextCount: 0, 
			searchTotalCount: 0, 
			searchPrevBtnDisable: true, 
			searchNextBtnDisable: true
		});
		searchInputRef.current.value = '';
	}, [/* propRows */getFilteredRowData]);

	// Handle search functionality
	const handleSearch = useCallback((e) => {
		let matched_count = 0,
			_foundFirstIndex = -1,
			_copyData = [],
			_matchedSearchIds = [],
			_searchColumns = searchColumns,
			searchText = e.target.value || '',
			_filteredRowData = getFilteredRowData();
		
		// setRptTypeValue('');
		setScrollToAlignment(ScrollToAlignmentCfg.START);
		setSearchInputText(searchText);
		// Prevent search if phrase length < 1
		if (searchText.length < 1) {
			matched_count = 0;
			_foundFirstIndex = -1;
			// setRowData(propRows);
			setRowData(_filteredRowData);
			setSearchInputText('');
			setSearchSelectedIndex(0);
			setScrollToIndex(_foundFirstIndex);
			setMatchedSearchCount({
				searchPrevNextCount: 0, 
				searchTotalCount: matched_count, 
				searchPrevBtnDisable: true, 
				searchNextBtnDisable: true
			});
			return;
		}

		// Re-build search field
		if (!_searchColumns?.length) {
			_searchColumns = [];
			columns.forEach(clmn => _searchColumns.push(clmn.field));
		}

		let replacedSearchText = searchText.replace(/\\/g, '\\\\');
		const regExpText = new RegExp(`(${replacedSearchText})`, 'gi');
		/* propRows */_filteredRowData.forEach((item, i) => {
			let _item = item,
				_isMatchedText = false;

			// Find search string on fields
			(_searchColumns || []).forEach(k => {
				const rollup_searched_parts = (item[k]?.toString().split(regExpText) || []);
				rollup_searched_parts.forEach(part => {
					const isMatched = part.toLowerCase() === searchText.toLowerCase();
					if (isMatched) {
						const _searchedText = item[k]?.toString().replace(regExpText, '<span>$1</span>');
						_item = {..._item, [k]: _searchedText};
						_isMatchedText = true;
					}
				});
			});

			/* searchInTextArr.forEach(({k, v}) => {
				const rollup_searched_parts = (v.split(regExpText) || []);
				rollup_searched_parts.forEach(part => {
					const isMatched = part.toLowerCase() === searchText.toLowerCase();
					if (isMatched) {
						const _searchedText = v.replace(regExpText, '<span>$1</span>');
						_item = {..._item, [k]: _searchedText};
						_isMatchedText = true;
					}
				});
			}); */

			if (_isMatchedText) {
				matched_count++;
				_foundFirstIndex = _foundFirstIndex < 0 ? i : _foundFirstIndex;
				_matchedSearchIds.push({k: i, v: item.UUID});
			}
			_copyData[i] = _item;
		});

		let _isMatchedCount = matched_count > 0;
		setRowData(_copyData);
		setMatchedSearchCount({
			searchPrevNextCount: _isMatchedCount ? 1: 0, 
			searchTotalCount: matched_count, 
			searchPrevBtnDisable: true, 
			searchNextBtnDisable: !_isMatchedCount
		});
		setMatchedSearchIds(_matchedSearchIds);
		setSearchSelectedIndex(_foundFirstIndex);
		setScrollToIndex(_foundFirstIndex);
	}, [/* propRows,  */columns, searchColumns, getFilteredRowData]);

	// Toggle search functionality - move to first item
	const handleFirstSearch = useCallback((e) => {
		let _searchSelectedIndex = matchedSearchIds.slice(0, 1),
			_searchSelectedIndexUpdated = _searchSelectedIndex[0] ? _searchSelectedIndex[0].k : 0;
		
		setSearchSelectedIndex(_searchSelectedIndexUpdated);
		setScrollToIndex(_searchSelectedIndexUpdated);
		setMatchedSearchCount({
			...matchedSearchCount, 
			searchPrevNextCount: 1, 
			searchPrevBtnDisable: true, 
			searchNextBtnDisable: false
		});
	}, [matchedSearchIds, matchedSearchCount]);

	// Toggle search functionality - move to last item
	const handleLastSearch = useCallback((e) => {
		let _searchSelectedIndex = matchedSearchIds.slice(-1),
			_searchSelectedIndexUpdated = _searchSelectedIndex[0] ? _searchSelectedIndex[0].k : 0;
		
		setSearchSelectedIndex(_searchSelectedIndexUpdated);
		setScrollToIndex(_searchSelectedIndexUpdated);
		setMatchedSearchCount({
			...matchedSearchCount, 
			searchPrevNextCount: matchedSearchIds.length, 
			searchPrevBtnDisable: false, 
			searchNextBtnDisable: true
		});
	}, [matchedSearchIds, matchedSearchCount]);

	// Toggle Next search functionality
	const handleNextSearch = useCallback((e) => {
		let _searchSelectedIndex = matchedSearchIds.find(item => {
			return item.k > (searchSelectedIndex || 0);
		});

		let _searchSelectedIndexUpdated = _searchSelectedIndex ? _searchSelectedIndex.k : searchSelectedIndex,
			_search_count_pnc = (matchedSearchCount.searchPrevNextCount >= matchedSearchCount.searchTotalCount 
				? matchedSearchCount.searchTotalCount 
					: ++matchedSearchCount.searchPrevNextCount),
			_isNextDisabled = _search_count_pnc >= matchedSearchCount.searchTotalCount;

		setSearchSelectedIndex(_searchSelectedIndexUpdated);
		setScrollToIndex(_searchSelectedIndexUpdated);
		setMatchedSearchCount({
			...matchedSearchCount, 
			searchPrevNextCount: _search_count_pnc, 
			searchPrevBtnDisable: false, 
			searchNextBtnDisable: _isNextDisabled
		});
    }, [matchedSearchIds, searchSelectedIndex, matchedSearchCount]);

	// Toggle previous search functionality
	const handlePrevSearch = useCallback((e) => {
		let _firstSearchIndexCount = 1,
			_matchedSearchIds = [...matchedSearchIds];
		_matchedSearchIds.reverse();

		let _searchSelectedIndex = _matchedSearchIds.find(item => {
			return (searchSelectedIndex || 0) > item.k;
		});

		let _search_count_pnc = (matchedSearchCount.searchPrevNextCount <= _firstSearchIndexCount 
			? _firstSearchIndexCount 
				: --matchedSearchCount.searchPrevNextCount),
			_searchSelectedIndexUpdated = _searchSelectedIndex ? _searchSelectedIndex.k : searchSelectedIndex,
			_isPrevDisabled = _search_count_pnc <= _firstSearchIndexCount;
		
		setSearchSelectedIndex(_searchSelectedIndexUpdated);
		setScrollToIndex(_searchSelectedIndexUpdated);
		setMatchedSearchCount({
			...matchedSearchCount, 
			searchPrevNextCount: _search_count_pnc, 
			searchPrevBtnDisable: _isPrevDisabled, 
			searchNextBtnDisable: false
		});
	}, [matchedSearchIds, searchSelectedIndex, matchedSearchCount]);

	// Toggle cancel - cut/paste functionality
	const handleCancel = useCallback((e, isMiscCancel) => {
		if (!isModifyEnabled) {
			return false;
		}
		// let _isMiscCancel = isMiscCancel || false;
		// const {_cutCopyColor1} = tableRowCutCopyColors;

		/* setCutPasteActionState({
			cut: {disable: !_isMiscCancel, clicked: false},
			copy: {disable: !_isMiscCancel, clicked: false},
			paste: {disable: true, clicked: false},
			cancel: {disable: !_isMiscCancel, clicked: false}
		}); */
		setCutPasteActionState({
			cut: {disable: true, clicked: false},
			copy: {disable: true, clicked: false},
			paste: {disable: true, clicked: false},
			cancel: {disable: true, clicked: false}
		});

		/* if (_isMiscCancel) {
			setCutPasteItems(prevState => ({
				...prevState,
				destinationItems: {
					destinationItemPosition: 0, 
					destinationItem: null
				},
				cutPasteActionType: ''
			}));
			// Set color
			setCutPasteActionColors({color1: _cutCopyColor1, color2: ''});
		}
		else { */
			setCutPasteItems({
				lineNos: {
					startLnNo: 0,
					endLnNo: 0
				},
				itemIndexes: [],
				indexItems: [],
				sourceItems: [],
				destinationItems: {
					destinationItemPosition: 0, 
					destinationItem: null
				},
				cutPasteActionType: ''
			});
			// Set color
			setCutPasteActionColors({color1: '', color2: ''});
		// }
	}, [isModifyEnabled]);

	// Toggle copy functionality
	const handleCopy = useCallback((e) => {
		if (!isModifyEnabled) {
			return false;
		}
		const {_cutCopyColor3} = tableRowCutCopyColors;

		setCutPasteActionState({
			cut: {clicked: false, disable: true},
			copy: {clicked: true, disable: true},
			paste: {clicked: false, disable: true},
			cancel: {clicked: false, disable: false}
		});
		setCutPasteActionColors(prevState => ({
			...prevState,
			color1: _cutCopyColor3
		}));
		setCutPasteItems(prevState => ({
			...prevState,
			cutPasteActionType: 'COPY'
		}));

		// Callback if there is any
		// let ctxMenuItem = (contextMenuItems || []).filter(ctmItem => ctmItem.id === 'copy')[0] || null;
		// ctxMenuItem?.callback && ctxMenuItem.callback(e, {...histDataProcessItems, histDataProcessType: ctxMenuItem?.id, onClearRowSelection: handleCancel});

	}, [isModifyEnabled]);

	// Toggle cut functionality
	const handleCut = useCallback((e) => {
		if (!isModifyEnabled) {
			return false;
		}
		const {_cutCopyColor3} = tableRowCutCopyColors;

		setCutPasteActionState({
			cut: {clicked: true, disable: true},
			copy: {clicked: false, disable: true},
			paste: {clicked: false, disable: true},
			cancel: {clicked: false, disable: false}
		});
		setCutPasteActionColors(prevState => ({
			...prevState,
			color1: _cutCopyColor3
		}));
		setCutPasteItems(prevState => ({
			...prevState,
			cutPasteActionType: 'CUT'
		}));
	}, [isModifyEnabled]);

	// Toggle paste functionality
	const handlePaste = useCallback((e) => {
		if (!isModifyEnabled) {
			return false;
		}
		// Set action states
		setCutPasteActionState({
			cut: {clicked: false, disable: true},
			copy: {clicked: false, disable: true},
			paste: {clicked: true, disable: true},
			cancel: {clicked: false, disable: true}
		});

		// Send data to server - callback
		if (onCutPasteRow) {
			onCutPasteRow({...cutPasteItems, rollupRowsData: rowData, onClearRowSelection: handleCancel});
		}
	}, [rowData, isModifyEnabled, cutPasteItems, handleCancel, onCutPasteRow]);

	// Grid item click functionality
	const handleRowClick = useCallback((e, row) => {
		if (!isModifyEnabled) {
			return false;
		}
		const {cut, copy, paste} = cutPasteActionState;
		const {_cutCopyColor1, _cutCopyColor2} = tableRowCutCopyColors;
		const {
			indexItems, 
			sourceItems, 
			itemIndexes, 
			lineNos: {startLnNo, endLnNo},
			destinationItems: {destinationItem}
		} = cutPasteItems;

		// Prevent row selection for multiple report type
		if (!(copy.clicked || cut.clicked) && sourceItems.length 
			&& sourceItems[0].ROLL_UP_I !== row.ROLL_UP_I) {
			if (alertMessage) {
				alertMessage(env.getEnv('REACT_APP_MSG_ERROR_RPT_TYPE_SELECTION'), 'error');
			}
			return;
		}

		// If copy/cut clicked - select row where to paste
		if ((copy.clicked || cut.clicked) && !paste.clicked 
			&& !indexItems.includes(row.UUID)) {
			let _pasteColor2 = '',
				_isPasteDisabled = true,
				_destinationItemPosition = 0,
				_destinationItem = null;
			
			if (destinationItem === null || (destinationItem !== null && destinationItem.UUID !== row.UUID)) {
				_destinationItem = {...row, position_m: row.ROLL_UP_LN_SEQ_N, is_multi: false};
				_destinationItemPosition = row.ROLL_UP_LN_SEQ_N;
				_pasteColor2 = _cutCopyColor2;
				_isPasteDisabled = false;
			}

			setCutPasteItems(prevState => ({
				...prevState,
				destinationItems: {
					destinationItemPosition: _destinationItemPosition, 
					destinationItem: _destinationItem
				}
			}));
			setCutPasteActionState(prevState => ({
				...prevState,
				paste: {clicked: false, disable: _isPasteDisabled}
			}));
			setCutPasteActionColors(prevState => ({
				...prevState,
				color2: _pasteColor2
			}));

			return;
		}

		// Prevent row click if already cut/copy clicked
		if ((cut.clicked || copy.clicked || paste.clicked)
			&& (destinationItem !== null || indexItems.includes(row.UUID))) {
			return;
		}

		let	_indexItems = [],
			_itemIndexes = [],
			_startLnNo = startLnNo,
			_endLnNo = endLnNo,
			_sourceItems = [];
		if (e.shiftKey) {
			_startLnNo = _startLnNo > row.ROLL_UP_LN_SEQ_N ? row.ROLL_UP_LN_SEQ_N : _startLnNo;
			_endLnNo = _endLnNo < row.ROLL_UP_LN_SEQ_N ? row.ROLL_UP_LN_SEQ_N : _endLnNo;
			_sourceItems = rowData.filter(item => (item.ROLL_UP_I === row.ROLL_UP_I && item.ROLL_UP_LN_SEQ_N >= _startLnNo && item.ROLL_UP_LN_SEQ_N <= _endLnNo));
			_sourceItems.forEach(item => {
				_indexItems.push(item.UUID);
				_itemIndexes.push(item.ROLL_UP_LN_SEQ_N);
			});
		}
		else {
			_sourceItems = rowData.filter(item => (item.ROLL_UP_I === row.ROLL_UP_I && item.ROLL_UP_LN_SEQ_N === row.ROLL_UP_LN_SEQ_N));
			_sourceItems.forEach(item => {
				if (!(item.ROLL_UP_I === row.ROLL_UP_I && itemIndexes.includes(row.ROLL_UP_LN_SEQ_N))) {
					_indexItems.push(item.UUID);
					_itemIndexes.push(item.ROLL_UP_LN_SEQ_N);
				}
			});
			_startLnNo = _endLnNo = _indexItems.length > 0 ? row.ROLL_UP_LN_SEQ_N : 0;
		}
		
		setCutPasteItems(prev => ({
			...prev,
			indexItems: _indexItems,
			sourceItems: _sourceItems,
			lineNos: {
				startLnNo: _startLnNo,
				endLnNo: _endLnNo
			},
			itemIndexes: _itemIndexes
		}));
		setCutPasteActionState(prevState => ({
			...prevState,
			cut: {clicked: false, disable: _indexItems.length ? false : true},
			copy: {clicked: false, disable: _indexItems.length ? false : true},
			cancel: {clicked: false, disable: _indexItems.length ? false : true}
		}));
		setCutPasteActionColors(prevState => ({
			...prevState,
			color1: _cutCopyColor1
		}));

		/* 
		// Calculate start/end line number and create array of lines
		let _itemIndexes = [...itemIndexes],
			_startLnNo = (startLnNo < 1 || startLnNo > row.ROLL_UP_LN_SEQ_N) ? row.ROLL_UP_LN_SEQ_N : startLnNo,
			_endLnNo = (endLnNo < 1 || endLnNo < row.ROLL_UP_LN_SEQ_N) ? row.ROLL_UP_LN_SEQ_N : endLnNo;
		if (_itemIndexes.includes(row.ROLL_UP_LN_SEQ_N)) {
			let _uniqueLnNos = [...new Set(_itemIndexes)];

			let _slicedLnNos = [],
				_indexPosition = _uniqueLnNos.indexOf(row.ROLL_UP_LN_SEQ_N),
				_midIndexesCount = Math.floor(_itemIndexes.length / 2),
				_midIndexLnNo = _itemIndexes[_midIndexesCount];
			
			if (row.ROLL_UP_LN_SEQ_N < _midIndexLnNo) {
				_slicedLnNos = _uniqueLnNos.slice(_indexPosition + 1);
			}
			if (row.ROLL_UP_LN_SEQ_N > _midIndexLnNo) {
				_slicedLnNos = _uniqueLnNos.slice(0, _indexPosition);
			}
			if (row.ROLL_UP_LN_SEQ_N === _midIndexLnNo) {
				_slicedLnNos = (_indexPosition === 0) 
					? _uniqueLnNos.slice(1) 
						: (((_uniqueLnNos.length - 1) === _indexPosition) 
							?  _uniqueLnNos.slice(0, _indexPosition) 
								: _uniqueLnNos.slice(_indexPosition + 1));
			}
			_startLnNo = _slicedLnNos.length ? Math.min(..._slicedLnNos) : 0;
			_endLnNo = _slicedLnNos.length ? Math.max(..._slicedLnNos) : 0;
		}
		
		// Rollup row selection - select all rows from start/end line number
		let _selectedRollupRows = rowData.filter(item => (item.ROLL_UP_I === row.ROLL_UP_I 
			&& _startLnNo <= item.ROLL_UP_LN_SEQ_N 
				&& item.ROLL_UP_LN_SEQ_N <= _endLnNo));
		
		// Prepare new items for row selection
		let _lineNoIdItems = [],
			_LineNoIndexes = [],
			_lineNoItems = _selectedRollupRows;
		_selectedRollupRows.forEach(item => {
			_lineNoIdItems.push(item.UUID);
			_LineNoIndexes.push(item.ROLL_UP_LN_SEQ_N);
		});
		
		setCutPasteItems(prevState => ({
			...prevState,
			indexItems: _lineNoIdItems,
			sourceItems: _lineNoItems,
			lineNos: {
				startLnNo: _startLnNo,
				endLnNo: _endLnNo
			},
			itemIndexes: _LineNoIndexes
		}));
		setCutPasteActionState(prevState => ({
			...prevState,
			cut: {clicked: false, disable: _lineNoIdItems.length ? false : true},
			copy: {clicked: false, disable: _lineNoIdItems.length ? false : true},
			cancel: {clicked: false, disable: _lineNoIdItems.length ? false : true}
		}));
		setCutPasteActionColors(prevState => ({
			...prevState,
			color1: _cutCopyColor1
		})); */
	}, [rowData, isModifyEnabled, cutPasteActionState, cutPasteItems, alertMessage]);
	
	// Grid item row action click functionality
	const handleRowActionClick = useCallback((e, rowItem, rowAction) => {
		if (!isModifyEnabled) {
			return false;
		}

		setCutPasteItems(prev => ({
			...prev,
			indexItems: [rowItem.UUID],
			sourceItems: [rowItem]
		}));
		setCutPasteActionColors(prevState => ({
			...prevState,
			color1: tableRowCutCopyColors._COLOR4
		}));
		setCutPasteActionState({
			cut: {clicked: false, disable: true},
			copy: {clicked: false, disable: true},
			paste: {clicked: false, disable: true},
			cancel: {clicked: false, disable: true}
		});

		rowAction.onClick && 
			rowAction.onClick(e, {rowItem: rowItem, onClearRowSelection: handleCancel});
	}, [isModifyEnabled, handleCancel]);

	// Column sort functionality
	const handleSortActionClick = useCallback((e, sortColumn, altSortColumn) => {
		let {sortBy, sortOrder} = sortColumns,
			sortColumnField = altSortColumn || sortColumn;
		
		if (sortBy === sortColumnField) {
			sortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
		}
		else {
			sortBy = sortColumnField;
			sortOrder = 'asc';
		}
		// Set column ordering
		setSortColumns(prev => ({
			...prev,
			sortBy: sortBy,
			sortOrder: sortOrder
		}));

		// Set sorted data
		setRowData(prev => prev.sort((i, j) => {
			if (i[sortColumnField] < j[sortColumnField]) {
				return sortOrder === 'asc' ? -1 : 1;
			}
			if (i[sortColumnField] > j[sortColumnField]) {
				return sortOrder === 'asc' ? 1 : -1;
			}
			return 0;
		}));
	}, [sortColumns]);

	// Define context menu callbacks
	const _fnCallbacks = useMemo(() => {
		return {
			handlecopy: handleCopy,
			handlecut: handleCut,
			handlepaste: handlePaste,
			handlecancel: handleCancel
		};
	}, [handleCopy, handleCut, handlePaste, handleCancel]);

	// Handle context menu on right click
	const handleContextMenu = useCallback((e) => {
		if (!isModifyEnabled) {
			return false;
		}

		// Prevent default context menu
		e.preventDefault();
		e.stopPropagation();

		// Set context menu position
		setContextMenu(
			contextMenu === null 
				? {mouseX: e.clientX + 4, mouseY: e.clientY + 4}
				: null
		);
	}, [isModifyEnabled, contextMenu]);

	// Handle context menu on right click
	const handleContextMenuItem = useCallback((e, type) => {
		if (!isModifyEnabled) {
			return false;
		}

		// Prevent default context menu
		e.preventDefault();
		e.stopPropagation();

		// Set context menu to null to disappear
		setContextMenu(null);
		if (type === 'backdropClick') {
			return;
		}

		// Callback if there is any
		let ctxMenuItem = (contextMenuItems || []).filter(ctmItem => ctmItem.id === type)[0] || null;
		const _methodCallback = _fnCallbacks[`handle${ctxMenuItem?.id}`];
		if (typeof _methodCallback === 'function') {
			_methodCallback();
		}
		// Callback if there is any
		ctxMenuItem?.callback && ctxMenuItem.callback(e, {
			...cutPasteItems, 
			ctxMenuActionType: ctxMenuItem?.id, 
			processActionType: ctxMenuItem?.id, 
			onClearRowSelection: _fnCallbacks.handlecancel});
	}, [isModifyEnabled, contextMenuItems, cutPasteItems, _fnCallbacks]);

	// Resize column
	const resizeColumn = (e, dataKey, deltaX) => {
		let nextDataKey = '';
		const {width: gridWidth} = tableDimension;
		const percentDelta = deltaX / gridWidth;
		columns.forEach((item, i) => {
			if (item.field === dataKey) {
				nextDataKey = columns[i + 1]?.field || '_ACT_COL';
			}
		});
		setColumnWidths(prevWidths => ({
			...prevWidths,
			[dataKey]: prevWidths[dataKey] + percentDelta,
			[nextDataKey]: prevWidths[nextDataKey] - percentDelta
		}));
	}

	// userEffect hook to load data if changes
    useEffect(() => {
		const {rollup_rpt_types} = extras;
		setPropRptRows(rollup_rpt_types);
		(!propRows ||  propRows !== rows) && setPropRows(rows);

		!rowData && setRowData(propRows);
		if (rowData && propRows !== rows) {
			setRowData(rows);
			setMatchedSearchIds({});
		}

		// Column width
		columns.forEach(item => {
			setColumnWidths(prevState => ({
				...prevState,
				[item.field]: item?.width/1000 || 0.01
			}));
		});
	}, [rowData, rows, columns, propRows, extras]);

	// userEffect hook to load data if changes
    useEffect(() => {
		const _filteredRowData = getFilteredRowData(rptTypeValue, filterObj);
		_filteredRowData?.length > 0 && setRowData(_filteredRowData);

		const rowPositionIndex = (_filteredRowData || []).findIndex(o => o.ROW_ID === rowPositionId);
		setScrollToAlignment(ScrollToAlignmentCfg.AUTO);
		setScrollToIndex(rowPositionIndex);
	}, [rowPositionId, rptTypeValue, filterObj, getFilteredRowData]);

	// Grid actions object
	const gridActions = {
		searchActions: {
			toggleSearch: handleSearch,
			togglePrev: handlePrevSearch,
			toggleNext: handleNextSearch,
			toggleFirst: handleFirstSearch,
			toggleLast: handleLastSearch,
			toggleClear: handleClearSearch
		},
		filterActions: {
			toggleFilter: handleFilterChange
		},
		rptTypesActions: {
			toggleRptTypes: handleRptTypes
		},
		cutPasteActions: {
			toggleCopy: handleCopy,
			toggleCut: handleCut,
			togglePaste: handlePaste,
			toggleCancel: handleCancel
		},
		rowClick: handleRowClick,
		contextMenuItemClick: handleContextMenuItem,
		contextMenuClick: handleContextMenu,
		rowActionClick: handleRowActionClick,
		resizeColumn: resizeColumn,
		sortAction: {
			sortColumns: sortColumns,
			sortActionClick: handleSortActionClick
		}
	};

	// Grid options object
	const gridOptions = {
		cutPasteOptions: {
			cutPasteItems: cutPasteItems,
			cutPasteActionState: cutPasteActionState,
			cutPasteActionColors: cutPasteActionColors
		},
		rptTypeOptions: {
			rptTypeValue: rptTypeValue
		},
		searchOptions: {
			searchText: searchInputText,
			searchPrevNextCount: matchedSearchCount.searchPrevNextCount, 
			searchTotalCount: matchedSearchCount.searchTotalCount,
			searchPrevBtnDisable: matchedSearchCount.searchPrevBtnDisable,
			searchNextBtnDisable: matchedSearchCount.searchNextBtnDisable
		},
		isModifyEnabled: isModifyEnabled,
		contextMenu: contextMenu,
		contextMenuItems: contextMenuItems,
		columnWidths: columnWidths,
		rowPositionId: rowPositionId,
		scrollToAlignment: scrollToAlignment
	};

	// Grid reference object
	const gridRefs = {
		tableRef: tableRef,
		searchInputRef: searchInputRef/* ,
		listContainerRef: listContainerRef */
	};

	return (
		<DragDropContext onDragEnd={handleDragEnd}>
			<Container>
				<TableContainer {...props} 
					rowData={rowData} 
					rptTypeRowData={propRptRows} 
					scrollToIndex={scrollToIndex} 
					gridRefs={gridRefs} 
					gridOptions={gridOptions} 
					gridActions={gridActions}
					tableDimension={tableDimension}/>
			</Container>
  		</DragDropContext>
	);
}

// Main Table container
const TableContainer = React.memo(props => {
	const {title, columns, options, actions, rowData, rptTypeRowData, scrollToIndex, isLoading, gridRefs, gridOptions, gridActions, tableDimension} = props;
	const {rowClick, contextMenuClick, contextMenuItemClick, rowActionClick, sortAction, resizeColumn, filterActions: {toggleFilter}} = gridActions;
	const {contextMenu, contextMenuItems, isModifyEnabled, columnWidths, cutPasteOptions: {cutPasteActionState}, scrollToAlignment} = gridOptions;
	const {width: gridWidth, height: gridHeight} = tableDimension;
	const {tableRef/* , listContainerRef */} = gridRefs;

	return (
		<TableRowContainer role="grid" ref={tableRef}>
			<TableCaptionBar title={title} totalCount={(rowData && rowData.length) || 0} rptTypeRowData={rptTypeRowData} 
				gridRefs={gridRefs} gridOptions={gridOptions} gridActions={gridActions} options={options} tableDimension={tableDimension}/>
			<TableColumnContainer gridRefs={gridRefs} columns={columns} options={options} sortAction={sortAction} columnWidths={columnWidths} resizeColumn={resizeColumn} tableDimension={tableDimension}/>
			{options.filtering && <TableFilterContainer columns={columns} options={options} handleFilter={toggleFilter} columnWidths={columnWidths} tableDimension={tableDimension}/>}
			{!rowData && <StyledLoadingDiv><CircularProgress disableShrink size={16}/><span style={{paddingTop: '3px'}}>Please wait...</span></StyledLoadingDiv>}
			<Droppable droppableId={'DroppableContainer'} mode={'virtual'} 
				renderClone={(provided, snapshot, rubric) => (
					<RowItem gridOptions={gridOptions} rowClick={rowClick} rowActionClick={rowActionClick} provided={provided} isDragging={snapshot.isDragging} columns={columns} row={rowData[rubric.source.index]} 
						actions={actions} options={options} style={getStyle(provided, snapshot.isDragging, {margin: 0})} tableDimension={tableDimension}/>
				)}>
				{(droppableProvided, snapshot) => {
					const itemCount = rowData ? (snapshot.isUsingPlaceholder ? rowData.length + 1 : rowData.length) : 0;
					return (
						<StyledContextMenuDiv /* ref={listContainerRef}  */onContextMenu={contextMenuClick}>
							<List
								height={gridHeight}
								rowCount={itemCount}
								rowHeight={35}
								width={gridWidth}
								ref={ref => { // react-virtualized has no way to get the list's ref, so use the `ReactDOM.findDOMNode(ref) to get the ref
									if (ref) {
										// eslint-disable-next-line react/no-find-dom-node
										const reactDomNode = ReactDOM.findDOMNode(ref);
										if (reactDomNode instanceof HTMLElement) {
											droppableProvided.innerRef(reactDomNode);
										}
									}
								}}
								style={{
									backgroundColor: getBackgroundColor(snapshot.isDraggingOver, snapshot.draggingFromThisWith),
									transition: 'background-color 0.2s ease'
								}}
								rowRenderer={getRowRender({rowData, options, columns, actions, rowClick, rowActionClick, gridOptions, tableDimension})}
								scrollToIndex={scrollToIndex}
								overscanRowCount={10}
								scrollToAlignment={scrollToAlignment} // auto/center/start/end
								noRowsRenderer={() => <NoRowsRenderer/>}/>
							<MenuContext contextMenu={contextMenu} contextMenuItemClick={contextMenuItemClick} isModifyEnabled={isModifyEnabled} cutPasteActionState={cutPasteActionState} contextMenuItems={contextMenuItems} />
						</StyledContextMenuDiv>
					)
				}}
			</Droppable>
			<OverlayLoader loading={isLoading}/>
		</TableRowContainer>
	)
})

// Context menu
const MenuContext = React.memo(props => {
	const {contextMenu, contextMenuItems, contextMenuItemClick, isModifyEnabled, cutPasteActionState} = props;
	
	return (
		<Menu open={contextMenu !== null} onClose={contextMenuItemClick} anchorReference="anchorPosition" 
			anchorPosition={contextMenu !== null ? {top: contextMenu.mouseY, left: contextMenu.mouseX} : undefined}>
			{(contextMenuItems || []).map((ctxMenuItem, idx) => (
				<MenuItem key={`${idx}${ctxMenuItem}_${(new Date()).getTime()}`} onClick={(e) => contextMenuItemClick(e, ctxMenuItem.id)} disabled={cutPasteActionState[ctxMenuItem.id]?.disable || !isModifyEnabled}>
					<ListItemIcon><ctxMenuItem.icon fontSize="small"/></ListItemIcon>
					<ListItemText>{ctxMenuItem.label}</ListItemText>
				</MenuItem>
			))}
		</Menu>
	)
})

// Table Caption Bar - Title / Search
const TableCaptionBar = React.memo(props => {
	const {title, totalCount, rptTypeRowData, gridRefs: {searchInputRef}, gridOptions, gridActions, options, tableDimension: {width: gridWidth}} = props;
	const {
		contextMenuItemClick,
		rptTypesActions: {toggleRptTypes},
		searchActions: {toggleClear, toggleSearch, toggleFirst, togglePrev, toggleNext, toggleLast}
	} = gridActions;
	const {
		isModifyEnabled,
		contextMenuItems,
		rptTypeOptions: {rptTypeValue},
		searchOptions: {searchText, searchPrevNextCount, searchTotalCount, searchPrevBtnDisable, searchNextBtnDisable},
		cutPasteOptions: {cutPasteActionState}
	} = gridOptions;

	return (
		<StyledCaptionBar style={{width: `${gridWidth}px`}}>
			<Title>{title} ({totalCount})</Title>
			<StyledReportType variant="standard">
				<InputLabel id="rollup-rpttype-input-label">Report Type</InputLabel>
				<Select id="rollup-rpttype-select-label" labelId="rollup-rpttype-select-label" defaultValue={rptTypeValue} 
					value={rptTypeValue} name="ROLL_UP_I" label="Report Type" onChange={toggleRptTypes}>
					<MenuItem value="">None</MenuItem>
					{(rptTypeRowData || []).map((item, i) => (
						<MenuItem key={`rpt-${item.ROLL_UP_I}-${i}`} value={item.ROLL_UP_I}>{item.ROLL_UP_D} ({item.ROLL_UP_I})</MenuItem>
					))}
				</Select>
			</StyledReportType>
			{options?.searching && <Search>
				<StyledSearchInput inputRef={searchInputRef} startAdornment={<InputAdornment position="start"><SearchIcon/></InputAdornment>} placeholder="Search" onKeyUp={toggleSearch} aria-label="search" 
					endAdornment={<IconButton sx={{visibility: searchText ? 'visible' : 'hidden'}} size="small" onClick={toggleClear}><ClearIcon sx={{width: '0.6em', height: '0.6em'}}/></IconButton>}/>
				{searchTotalCount > 0 && 
					<SearchPrevNextContainer>
						<IconButton onClick={toggleFirst} disabled={searchPrevBtnDisable} size="small"><FirstPageIcon/></IconButton>
						<IconButton onClick={togglePrev} disabled={searchPrevBtnDisable} size="small"><NavigateBeforeIcon/></IconButton>
						<span style={{flexGrow: 1, textAlign: 'center', paddingTop: '3px', fontWeight: 500, userSelect: 'none'}}>{searchPrevNextCount} / {searchTotalCount}</span>
						<IconButton onClick={toggleNext} disabled={searchNextBtnDisable} size="small"><NavigateNextIcon/></IconButton>
						<IconButton onClick={toggleLast} disabled={searchNextBtnDisable} size="small"><LastPageIcon/></IconButton>
					</SearchPrevNextContainer>
				}
			</Search>}

			<StyledActionMenuDiv>
			{(contextMenuItems || []).map((ctxMenuItem, idx) => (
				<IconButton title={ctxMenuItem?.label} key={`${idx}${ctxMenuItem}_${(new Date()).getTime()}`} onClick={(e) => contextMenuItemClick(e, ctxMenuItem.id)} disabled={cutPasteActionState[ctxMenuItem.id]?.disable || !isModifyEnabled}>
					<ctxMenuItem.icon style={{width: '.8em', height: '0.8em'}}/>
				</IconButton>
			))}
			</StyledActionMenuDiv>
		</StyledCaptionBar>
	)
})

// Table column container
const TableColumnContainer = React.memo(props => {
	const {columns, options, columnWidths, /* gridRefs: {listContainerRef},  */resizeColumn, tableDimension: {width: gridWidth}, sortAction: {sortColumns: {sortBy, sortOrder}, sortActionClick}} = props;
	const listGridContainer = document.querySelectorAll('div.ReactVirtualized__Grid__innerScrollContainer')[0] || null;
	const scrollBarWidth = (gridWidth - (listGridContainer?.clientWidth || gridWidth));
	
	return (
		<ColumnContainerDiv role="row" style={{fontWeight: 500, paddingRight: `${scrollBarWidth}px`, width: `${gridWidth}px`}}>
			<StyledDragHandler role="columnheader">
				<DragHandleRoundedIcon style={{flex: 'auto', width: '0.77em', height: '0.77em'}} {...(!options.draggable && {sx: {color: '#c5c5c5'}})}/>
				<StyledColumnResizeDisableDiv>|</StyledColumnResizeDisableDiv>
			</StyledDragHandler>
			{columns.map((column, idx) => {
				const columnWidth = (columnWidths[column.field] || 0.01) * gridWidth;

				return (
					<RowItemDiv role="columnheader" key={`h-${column.field}${idx}`} style={{flex: `0 1 ${columnWidth}px`}}>
						{column?.sorting 
							? <><StyledColumnSortDiv active={sortBy === (column?.sortColumn || column.field)} direction={sortOrder} onClick={(e) => sortActionClick(e, column.field, column?.sortColumn)}>{column.label}</StyledColumnSortDiv>
									<ColumnResizerRenderer column={column} resizeColumn={resizeColumn}/></>
							: <><StyledRowItemText style={{textAlign: column.align}}>{column.label}</StyledRowItemText>
									<ColumnResizerRenderer column={column} resizeColumn={resizeColumn}/></>
						}
					</RowItemDiv>
				)
			})}
			{options.action && <RowItemDiv style={{justifyContent: 'center', flex: '0 1 9.4em'}}>Actions</RowItemDiv>}
		</ColumnContainerDiv>
	)
})

// Column resizer placeholder
const ColumnResizerRenderer = React.memo((props) => {
	const {column, resizeColumn} = props;

	return (
		<>
			{(column?.resize === undefined || !!column?.resize) 
				? <ColumnResizer axis="x" onDrag={(e, { deltaX }) => resizeColumn(e, column.field, deltaX) } position={{ x: 0 }}>
					<StyledColumnResizeHandler>|</StyledColumnResizeHandler>
				</ColumnResizer>
				: <StyledColumnResizeDisableDiv>|</StyledColumnResizeDisableDiv>
			}
		</>
	)
})

// Table filter container
const TableFilterContainer = React.memo(props => {
	const {columns, options, handleFilter, columnWidths, tableDimension: {width: gridWidth}} = props;

	return (
		<FilterContainerDiv style={{fontWeight: 500, width: `${gridWidth}px`}}>
			<StyledDragHandler role="columnheader"/>
			{columns.map((column, idx) => {
				const columnWidth = (columnWidths[column.field] || 0.01) * gridWidth;
				return (
					column.filter 
						? <FilterItemDiv role="columnheader" key={`f-${column.field}${idx}`} style={{flex: `0 1 ${columnWidth}px`}}>
								<FilterInput aria-label="description" id={`filter-${column.field}-input`} onKeyUp={handleFilter} name={column.field} 
									startAdornment={<InputAdornment position="start"><FilterListOutlinedIcon sx={{width: '0.7em', height: '0.7em'}}/></InputAdornment>}>
								</FilterInput>
							</FilterItemDiv> 
						: <FilterItemDiv role="columnheader" key={`f-${column.field}${idx}`} style={{flex: `0 1 ${columnWidth}px`}}/>
				)
			})}
			{options.action && <FilterItemDiv style={{justifyContent: 'center', flex: '0 1 9.4em'}}/>}
		</FilterContainerDiv>
	)
})

// Table row item
const RowItem = React.memo(props => {
	const {
		provided, isDragging, columns, row, actions, index,
		options, style, rowClick, rowActionClick, 
		tableDimension: {width: gridWidth}, 
		gridOptions: {isModifyEnabled, cutPasteOptions, columnWidths, rowPositionId}
	} = props;
	const {
		cutPasteActionColors: {color1, color2}, 
		cutPasteItems: {indexItems, destinationItems: {destinationItem}}
	} = cutPasteOptions;
	let bgColors = {
		color1: indexItems.includes(row.UUID) ? color1 : (row.RPT_SHAD_X === 'Y' ? tableRowCutCopyColors._COLOR5 : 'inherit'),
		color2: destinationItem !== null && destinationItem.UUID === row.UUID ? color2 : 'inherit'
	};
	const isRowHightLighted = rowPositionId === row.ROW_ID;
	
	return (
		<RowContainer 
			isDragging={isDragging} 
			ref={provided.innerRef} 
			role="row"
	        {...provided.draggableProps} 
			{...(isRowHightLighted && {className: 'rw-highlight'})}
			style={getStyle(provided, isDragging, style, bgColors)}>
				{options.draggable && <StyledDragHandler role="gridcell" style={{justifyContent: 'center'}}>
					<span style={{display: 'flex', padding: '0px 0.3em 0px 0'}} {...provided.dragHandleProps}>
						<DragHandleRoundedIcon style={{flexGrow: 1, width: '0.77em', height: '0.77em'}} {...(!options.draggable && {sx: {color: '#c5c5c5'}})}/>
					</span>
				</StyledDragHandler>}
				{columns.map(column => {
					const columnWidth = (columnWidths[column.field] || 0.01) * gridWidth;
					const _textValue = column.format && typeof row[column.field] === 'number' ? column.format(row[column.field]) : getMarkdownText(row[column.field]);

					return (
						<RowItemDiv role="gridcell" onClick={e => rowClick(e, row, index)} key={`${row.UUID}-${column.field}`} 
							style={{justifyContent: column.align, overflow: 'hidden', flex: `0 1 ${columnWidth}px`}}>
								<StyledRowItemText style={{textAlign: column.align}} dangerouslySetInnerHTML={_textValue}/>
						</RowItemDiv>
					)
				})}
				{options.action &&
					<RowItemDiv style={{width: `calc(10.6em - 17px)`}}>
					{(actions || []).map((action, idx) => (
						<IconButton key={`_actions_${idx}`} title={action?.title} disabled={!isModifyEnabled} onClick={e => rowActionClick(e, row, action)} sx={{flexGrow: 1, padding: '.1em'}}><action.icon sx={{width: '0.67em', height: '0.67em'}}/></IconButton>
					))}
					</RowItemDiv>
				}
		</RowContainer>
	)
})

// No rows found placeholder
const NoRowsRenderer = React.memo(() => {
	return (
		<StyledNoRecordDiv>No record(s) found!</StyledNoRecordDiv>
	)
})

// Overlay loader
const OverlayLoader = React.memo(props => {
	const {loading} = props;

	return (
		<Backdrop open={loading} sx={{color: '#0e5ead', backgroundColor: 'rgb(0 0 0 / 2%)', zIndex: (theme) => theme.zIndex.drawer + 1}}>
			<StyledLoaderDiv>
				<CircularProgress size={30} color="inherit"/>
				<span style={{fontSize: '0.9rem', marginLeft: '0.8em'}}>Please wait...</span>
			</StyledLoaderDiv>
		</Backdrop>
	)
})

// Using a higher order function so that we can look up the rows data to retrieve row from within the rowRender function
const getRowRender = (props) => ({index, style}) => {
	const {rowData, options, columns, actions, rowClick, rowActionClick, gridOptions, tableDimension} = props;
	const {isModifyEnabled, cutPasteOptions: {cutPasteActionState: {cut, copy, paste}}} = gridOptions;
	const row = rowData[index] || undefined;
	if (!row) {
		return null;
	}

	// Rendering an extra item for the placeholder by increasing data set size to include one 'fake' item
	const patchedStyle = {
		...style,
		left: style.left,
		top: style.top + 2,
		width: style.width,
		height: style.height - 0.5
	};
	
	return (
		<Draggable draggableId={row.UUID} index={index} key={row.UUID} isDragDisabled={cut.clicked || copy.clicked || paste.clicked || !isModifyEnabled} style={patchedStyle}>
		{(provided, snapshot) => (
			<RowItem index={index} provided={provided} isDragging={snapshot.isDragging} gridOptions={gridOptions} rowClick={rowClick} row={row} 
				rowActionClick={rowActionClick} actions={actions} columns={columns} options={options} style={patchedStyle} tableDimension={tableDimension}/>
		)}
		</Draggable>
	);
}

// Re-order items after drag/drop
const reorderItems = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
}

// Get dragg item style while dragging
const getStyle = (provided, isDragging, styles, bgColors) => {
	const {color1, color2} = bgColors || {color1: 'inherit', color2: 'inherit'};
	
	return {
		...provided.draggableProps.style,
		...styles,
		color: isDragging ? '#ff6347' : 'inherit',
		borderTop: isDragging ? '1px solid #dfdfdf' : '0 none',
		backgroundColor: isDragging ? '#fdffbc' : (color2 !== 'inherit' ? color2 : color1)
	};
}

// Backgroud color
const getBackgroundColor = (isDraggingOver, isDraggingFrom) => {
	if (isDraggingOver) {
		// return '#FFEBE6';
	}

	if (isDraggingFrom) {
		// return '#E6FCFF';
	}
	return 'inherit';
};

// Get Mark down text
const getMarkdownText = (value) => {
    return {__html: value};
}
