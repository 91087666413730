import axios from 'axios';
import { SalesCalendar } from '../utils/urls';

// Sales Calendar - Non Selling Days
export const NonSellingDaysService = {
    // Get all distributors data
    getAllDistributorsData: async () => {
        let result = await axios.get(SalesCalendar.NonSellingDays.ALL_DISTRIBUTORS_DATA);

        return result;
    },

	// Get all Sales Calendar Non Selling Days Data
    getNonSellingDays: async (data) => {
        let result = await axios.get(SalesCalendar.NonSellingDays.ALL_NSD_DAYS_DATA, {
            params: {
                jdeAbNo: data?.jdeAbNo,
                year: data?.year
            }
        });

        return result;
    },

	// Update Non Selling Days
	updateNonSellingDays: async (rowData) => {
		let result = await axios.put(SalesCalendar.NonSellingDays.UPD_NSD_DAYS_DATA, {
            data: rowData
        });

        return result;
	}
}
