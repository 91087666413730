import React, { useEffect, useRef, useState } from 'react';
import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, 
    /* Table, TableBody, TableCell, TableHead, TableRow,  */TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { ConstCfg, DialogCfg } from '../utils/utils';


// Popup Dialog
export const PopupDialog = (props) => {
    const closeBtnRef = useRef();
    const closeXBtnRef = useRef();
    const {
        title,
        showDialog,
        dialogType,
        callbacks: {onDialogClose}

    } = props;
    const [selectedRowItem, setSelectedRowItem] = useState({});
    const [isOpenDialog, setIsOpenDialog] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState(false);

    // Close dialog callback
    const onCloseDialogCallback = (e) => {
        onDialogClose && 
            onDialogClose(e);
        
        setBtnLoading(false);
        setBtnDisabled(false);
        setIsOpenDialog(false);
    };

    // Close dialog callback
    const onActionDialogCallback = (e) => {
        setBtnLoading(true);
        setBtnDisabled(true);
        DialogCfg.onToggleButtonState([
            {ref: closeBtnRef, state: true},
            {ref: closeXBtnRef, state: true}
        ]);
        
        dialogType?.action && 
            dialogType?.action(e, selectedRowItem);
        
        // console.log('After-process-callback---->>>');
        setBtnLoading(false);
        setBtnDisabled(false);
        setIsOpenDialog(false);
    };

    // Input/Textarea/Dropdown value change callback
    const onChangeCallback = (e) => {
        const name = e.target.name;
        const value = e.target.type === 'checkbox' 
            ? e.target.checked : e.target.value;
        const ofld_name = `O_${name}`;

        setSelectedRowItem(prev => ({
            ...prev, 
            [name]: value,
            [ofld_name]: !prev[ofld_name] ? prev[name] : prev[ofld_name]
        }));
    };

    // Load data and show dialog
    useEffect(() => {
        /* setBtnLoading(!showDialog);
        setBtnDisabled(!showDialog); */
        setIsOpenDialog(showDialog);
        setSelectedRowItem(dialogType?.rowItem || {});
    }, [showDialog, dialogType]);

    
    return (
        <Dialog open={isOpenDialog} style={{pointerEvent: 'auto'}} aria-labelledby="dialog-title" aria-describedby="dialog-description">
            <DialogTitle id="dialog-title">
                <Typography align="left">{dialogType.title} {title}</Typography>
                <IconButton ref={closeXBtnRef} aria-label="close" onClick={onCloseDialogCallback} sx={{position: 'absolute', right: 8, top: 8, color: ConstCfg.Colors.LIGHT_GREY}}>
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            
            <DialogContent dividers={true}>
                <DialogContent id="dialog-description">
                    {dialogType.type === 'E' && 
                        <Container>
                            <TextField fullWidth disabled sx={{marginBottom: '2.5em !important'}} id="route-number-input-disabled" 
                                label="Route Number" defaultValue={selectedRowItem.SLS_RTE_N} variant="standard"/>
                            
                            <TextField fullWidth variant="standard" sx={{marginBottom: '2.5em !important'}} id="representative-name-input" label="Representative Name" 
                                onChange={onChangeCallback} name="SLS_REP_M" value={selectedRowItem.SLS_REP_M || ''}/>
                        </Container>
                    }



                    {/* {dialogType.type === 'D' && 
                        <Table border={0}>
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={2} style={{padding: '0 16px 20px'}}>
                                        {dialogType.type === 'D' && <Typography style={{color: '#ff0000'}} align="left">Please confirm to delete the selected record permanently.</Typography>}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell width={'45%'}>Route Number</TableCell>
                                    <TableCell>{selectedRowItem.SLS_RTE_N}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Representative Name</TableCell>
                                    <TableCell>{selectedRowItem.SLS_REP_M}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    } */}
                </DialogContent>
                <DialogActions>
                    <Button loadingposition="start" startIcon={<CloseIcon/>} ref={closeBtnRef} onClick={onCloseDialogCallback} variant="outlined">Close</Button>
                    <LoadingButton variant="contained" color="primary" loadingPosition="start" startIcon={<NavigateNextIcon/>}
                        {...(dialogType.icon && {startIcon: <dialogType.icon/>})} loading={btnLoading} disabled={btnDisabled}
                            onClick={onActionDialogCallback}>{dialogType.actionText}</LoadingButton>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
} 
