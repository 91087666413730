import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Typography } from '@mui/material';
import { Table, TableHead, TableBody, TableRow, TableCell, Button } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { HistSalesRejectedDataService } from '../services/service.hist.sales.rejected';
import { useWindowDimensions } from '../hooks/hook.window.dimensions';
import { SMISHistSalesRejectedDataTable } from '../components/component.table.virtualized.histsalesrej';
import { env } from '../utils/env.variables';
import { VirtualizedDataGrid1 } from '../components/component.table1.virtualized';
// include utils module
require('../utils/utils');


// Severity level - error, info, success, warning
const severity_level = {
    error: 'error',
    info: 'info',
    success: 'success',
    warning: 'warning'
};


// Historical Rejected Data module
export const HistSalesRejectedData = (props) => {
    const btnCancelRef = useRef();
    const btnCloseRef = useRef();
    

    const {dimensions: gridDimension/* leftDivRef */, graphData: userInfo} = props;
    const {isUserCanModify: isModifyEnabled} = userInfo;

	/* const {width} = useWindowDimensions();
    const _width = ((width - (leftDivRef?.current?.clientWidth || 182)) - 34);
    const _height = (leftDivRef?.current?.clientHeight || window.screen.availHeight) - 210;
    const tableDimension = {width: _width, height: _height};
    const gridDimension = {width: _width, height: _height}; */
    // const tableDimension = dimensions;
    // const gridDimension = dimensions;
    // console.log('>dimensions>#>', dimensions);

    const [showLoader, setShowLoader] = useState(false);
    const [showOverlayLoader, setShowOverlayLoader] = useState(false);
    const [rowData, setRowData] = useState({
        odata: undefined,
        fdata: undefined,
        cdata: undefined
    });




    const [tableData, setTableData] = useState();
    const [showDialog, setShowDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [btnLoading, setBtnLoading] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState(true);
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState({
        severity: severity_level.error, 
        message: ''
    });
	const [dialogType, setDialogType] = useState({
        icon: ArrowCircleRightOutlinedIcon,
        action: (e, d) => {}
    });
    const [showLoading] = useState(false);
    const [callbacks, setCallbacks] = useState(null);
    /* const [, setShowSnackbar] = useState();
    SnackbarCfg.setState({
        state: setShowSnackbar
    }) */



    // Grid columns
    /* const [xGridColumns, setXGridColumns] = useState([
        {label: 'State', field: 'ST_C', width: 80, visible: true, filter: true, sorting: true, searching: true},
        {label: 'Market', field: 'MKT_D', width: 80, visible: true, filter: true, sorting: true, searching: true},
        {label: 'Distributor Name', field: 'DIST_D', width: 80, visible: true, filter: true, sorting: true, searching: true},
        {label: 'Dist Nbr', field: 'JDE_ADDR_BOOK_N', width: 80, visible: true, filter: true, sorting: true, searching: true},
        {label: 'File Name', field: 'FILE_M', width: 80, visible: true, filter: true, sorting: true, searching: true},
        {label: 'File Date', field: 'FILE_DATE', sortColumn: 'FILE_DATE_T', width: 80, visible: true, filter: true, sorting: true, searching: true},
        {label: 'From Date', field: 'FROM_INVC_DATE', width: 80, visible: true, filter: true, sorting: true, searching: true},
        {label: 'To Date', field: 'TO_INVC_DATE', width: 80, visible: true, filter: true, sorting: true, searching: true},
        {label: 'Rec Count', field: 'RECORD_COUNT', width: 80, visible: true, filter: true, sorting: true, searching: true}
    ]); */

    // Table grid input properties - columns/options/actions etc.
    const tableInputProps = {
        // Columns
        columns: [
            {label: 'State', field: 'ST_C', width: 60, visible: true, filter: true, sorting: true, searching: true},
            {label: 'Market', field: 'MKT_D', width: 180, visible: true, filter: true, sorting: true, searching: true},
            {label: 'Distributor Name', field: 'DIST_D', width: 300, visible: true, filter: true, sorting: true, searching: true},
            {label: 'Dist Nbr', field: 'JDE_ADDR_BOOK_N', width: 100, visible: true, filter: true, sorting: true, searching: true},
            {label: 'File Name', field: 'FILE_M', width: 140, visible: true, filter: true, sorting: true, searching: true},
            {label: 'File Date', field: 'FILE_DATE', sortColumn: 'FILE_DATE_T', width: 150, visible: true, filter: true, sorting: true, searching: true},
            {label: 'From Date', field: 'FROM_INVC_DATE', width: 120, visible: true, filter: true, sorting: true, searching: true},
            {label: 'To Date', field: 'TO_INVC_DATE', width: 120, visible: true, filter: true, sorting: true, searching: true},
            {label: 'Rec Count', field: 'RECORD_COUNT', width: 100, visible: true, filter: true, sorting: true, searching: true}
        ],
        // Context menu - mouse right button click
        contextMenu: [
            {
                // Process sales rejected data
                id: 'process',
                label: 'Process',
                icon: ArrowCircleRightOutlinedIcon,
                callback: (e, result) => {
                    if (!isModifyEnabled) {
                        return;
                    }
                    console.log('>actions>', result);
                }
            }

        ],
        // Row actions
        actions: [
            {
                // Process rejected data
                title: 'Process',
                icon: ArrowCircleRightOutlinedIcon,
                onClick: (e, result) => {console.log('>actions-process>', result);
                    if (!isModifyEnabled) {
                        return;
                    }
                    const {rowItem: rowData, onClearRowSelection} = result;
                    setCallbacks(prev => ({...prev, onClearRowSelection: onClearRowSelection}));
                    setSelectedRow(rowData);
                    setBtnDisabled(false);
                    // Open dialog to confirm process data
                    setDialogType({
                        type: 'P',
                        title: 'Process',
                        icon: ArrowCircleRightOutlinedIcon,
                        actionText: 'Process',
                        action: (e, newData) => {
                            processHistoricalRejectedData(e, {...rowData, usrid: userInfo.upn});
                        }
                    });
                    setShowDialog(true);
                }
            },
            {
                // Delete rejected data
                title: 'Delete',
                icon: DeleteOutlinedIcon,
                onClick: (e, result) => {console.log('>actions-delete>', result);
                    if (!isModifyEnabled) {
                        return;
                    }
                    const {rowItem: rowData, onClearRowSelection} = result;
    
                    setCallbacks(prev => ({...prev, onClearRowSelection: onClearRowSelection}));
                    setSelectedRow(rowData);
                    setBtnDisabled(false);
                    console.log(rowData);
                    // Open dialog to confirm deletion
                    setDialogType({
                        type: 'D',
                        title: 'Delete',
                        icon: DeleteOutlinedIcon,
                        actionText: 'Confirm',
                        action: (e, newData) => {
                            deleteHistoricalRejectedData(e, {...rowData, usrid: userInfo.upn});
                        }
                    });
                    setShowDialog(true);
                }
            }
        ],
        // Table options
        options: {
            action: true,
            filtering: true,
            searching: true,
            contextmenu: true
        },
    }



    // Table column defination
	const tableColumns = [
        {label: 'State', field: 'ST_C', align: 'center', width: 100, filter: true, sorting: true},
        {label: 'Market', field: 'MKT_D', width: 280, filter: true, sorting: true},
        {label: 'Distributor Name', field: 'DIST_D', width: 450, filter: true, sorting: true},
        {label: 'Dist Nbr', field: 'JDE_ADDR_BOOK_N', width: 150, filter: true, sorting: true},
        {label: 'File Name', field: 'FILE_M', width: 260, filter: true, sorting: true},
        {label: 'File Date', field: 'FILE_DATE', width: 200, sorting: true, filter: true, sortColumn: 'FILE_DATE_T'},
        {label: 'From Date', field: 'FROM_INVC_DATE', width: 200, sorting: true},
        {label: 'To Date', field: 'TO_INVC_DATE', width: 180, sorting: true},
        {label: 'Rec Count', field: 'RECORD_COUNT', align: 'right', width: 150, sorting: true}
	];

	// Table options
	const tableOptions = {
		action: true,
        filtering: true,
        searching: true
	};

    // Table column search
    const tableColumnSearch = [
        'MKT_D', 
        'DIST_D', 
        'FILE_M'
    ];
    
    // Context Menu Items
    const contextMenuItems = [
        {
            id: 'process',
            label: 'Process',
            icon: ArrowCircleRightOutlinedIcon,
            callback: (e, result) => {
                const {sourceItems, onClearRowSelection} = result;

                setCallbacks(prev => ({...prev, onClearRowSelection: onClearRowSelection}));
                // Open dialog to confirm deletion
                setDialogType({
                    type: 'PM',
                    title: 'Process',
                    icon: ArrowCircleRightOutlinedIcon,
                    actionText: 'Process',
                    action: (e, newData) => {
                        let _sourceItems = sourceItems?.map(item => ({...item, usrid: userInfo.upn}));
                        processHistoricalRejectedData(e, _sourceItems, onClearRowSelection);
                    }
                });
                setBtnDisabled(false);
                setShowDialog(true);
            }
        },
        {
            id: 'deletei',
            label: 'Delete',
            icon: DeleteOutlinedIcon,
            callback: (e, result) => {
                const {sourceItems, onClearRowSelection} = result;

                setCallbacks(prev => ({...prev, onClearRowSelection: onClearRowSelection}));
                // Open dialog to confirm deletion
                setDialogType({
                    type: 'DM',
                    title: 'Delete',
                    icon: DeleteOutlinedIcon,
                    actionText: 'Confirm',
                    action: (e, newData) => {
                        let _sourceItems = sourceItems?.map(item => ({...item, usrid: userInfo.upn}));
                        deleteHistoricalRejectedData(e, _sourceItems, onClearRowSelection);
                    }
                });
                setBtnDisabled(false);
                setShowDialog(true);
            }
        },
        {
            id: 'cancel',
            label: 'Cancel',
            icon: CancelOutlinedIcon
        }
    ];
    
    // Callback for row actions
	const tableColumnActions = [
		{
            // Process sales rejected data
            title: 'Process',
			icon: ArrowCircleRightOutlinedIcon,
            onClick: (e, result) => {
                if (!isModifyEnabled) {
                    return false;
                }
                const {rowItem: rowData, onClearRowSelection} = result;

                setCallbacks(prev => ({...prev, onClearRowSelection: onClearRowSelection}));
                setSelectedRow(rowData);
                setBtnDisabled(false);
                // Open dialog to confirm process data
                setDialogType({
                    type: 'P',
                    title: 'Process',
                    icon: ArrowCircleRightOutlinedIcon,
                    actionText: 'Process',
                    action: (e, newData) => {
                        processHistoricalRejectedData(e, {...rowData, usrid: userInfo.upn});
                    }
                });
                setShowDialog(true);
            }
		},
		{
			// Delete rejected data
            title: 'Delete',
			icon: DeleteOutlinedIcon,
            onClick: (e, result) => {
                if (!isModifyEnabled) {
                    return false;
                }
                const {rowItem: rowData, onClearRowSelection} = result;

                setCallbacks(prev => ({...prev, onClearRowSelection: onClearRowSelection}));
                setSelectedRow(rowData);
                setBtnDisabled(false);
                console.log(rowData);
                // Open dialog to confirm deletion
                setDialogType({
                    type: 'D',
                    title: 'Delete',
                    icon: DeleteOutlinedIcon,
                    actionText: 'Confirm',
                    action: (e, newData) => {
                        deleteHistoricalRejectedData(e, {...rowData, usrid: userInfo.upn});
                    }
                });
                setShowDialog(true);
            }
		}
	];

    // Close dialog
    const dialogClose = (e) => {
        setShowDialog(false);
        setTimeout(() => {
            setDialogType({});
            setBtnLoading(false);
            callbacks?.onClearRowSelection 
                && callbacks?.onClearRowSelection();
            setCallbacks(repackCallbacks('onClearRowSelection'));
        }, 1000);
    }

    // Rebuild / repack callbacks
    const repackCallbacks = (skips) => {
        if (!(skips instanceof Array)) {
            skips = [skips];
        }

        let _new_callbacks = null;
        Object.entries(callbacks || {}).forEach(([key, value]) => {
            if (!skips.includes(key)) {
                _new_callbacks = {..._new_callbacks, [key]: value};
            }
        });
        return _new_callbacks;
    }

    // Disable / enable button
    const toggleButtonState = (btnRef, state) => {
        if (!btnRef.current) {
            return;
        }
        // Disable buttons
        btnRef.current.disabled = state || false;
        if (state) {
            btnRef.current.classList.add('Mui-disabled');
        }
        else {
            btnRef.current.classList.remove('Mui-disabled');
        }
    }

    // Close Snackbar
	const handleCloseSnackbar = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
    	setOpenSnackbar(false);
        setSnackbarMessage({severity: snackbarMessage.severity, message: ''});
    }

    // Show Snackbar message
    const showSnackbarMessage = useCallback((_message, _severity) => {
        setSnackbarMessage({
            severity: _severity ? _severity : snackbarMessage.severity, 
            message: _message
        });
        setOpenSnackbar(true);
    }, [snackbarMessage.severity])

    // MUI Alert - for Snackbar
    const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant={'filled'} {...props} />);


    // Delete Sales Rejected Data
    const deleteHistoricalRejectedData = (e, rowData, onClearRowSelection) => {
        // Disable buttons
        toggleButtonState(btnCloseRef, true);
        toggleButtonState(btnCancelRef, true);
        setBtnLoading(true);

        // Delete Historical Sales Rejected Data
        HistSalesRejectedDataService.deleteHistoricalRejectedData(rowData)
            .then(response => {
                console.log('>>>>>>>>>>>>>>>', response);
                const {status, data_hist_sales_rejected} = response.data;
                if (status.isOk) {
                    setTableData(data_hist_sales_rejected.rows);
                    showSnackbarMessage(env.getEnv('REACT_APP_MSG_HIST_SLS_REJDATA_DELETE_SUCCESS'), severity_level.success);
                }
                else {
                    showSnackbarMessage(env.getEnv('REACT_APP_MSG_PROCESS_FAILED').format(status.message), severity_level.error);
                }
            })
            .catch(error => {
                showSnackbarMessage((error?.response?.data?.message || error?.message), severity_level.error);
			})
            .finally(() => {
                setSelectedRow({});
                setBtnLoading(false);
                setShowDialog(false);
                // setShowLoading(false);
                toggleButtonState(btnCloseRef, false);
                toggleButtonState(btnCancelRef, false);
                // onClearRowSelection - callback function to clear row selection
                onClearRowSelection && onClearRowSelection();
            });
    }

    // Process Sales Rejected Data
    const processHistoricalRejectedData = (e, rowData, onClearRowSelection) => {
        // Disable buttons
        toggleButtonState(btnCloseRef, true);
        toggleButtonState(btnCancelRef, true);
        setBtnLoading(true);

        // Process Historical Sales Rejected Data
        HistSalesRejectedDataService.processHistoricalRejectedData(rowData)
            .then(response => {
                const {status, data_hist_sales_rejected} = response.data;
                if (status.isOk) {
                    setTableData(data_hist_sales_rejected.rows);
                    showSnackbarMessage(env.getEnv('REACT_APP_MSG_HIST_SLS_REJDATA_PROCESS_SUCCESS'), severity_level.success);
                }
                else {
                    showSnackbarMessage(env.getEnv('REACT_APP_MSG_PROCESS_FAILED').format(status.message), severity_level.error);
                }
            })
            .catch(error => {
                showSnackbarMessage((error?.response?.data?.message || error?.message), severity_level.error);
			})
            .finally(() => {
                setSelectedRow({});
                setBtnLoading(false);
                setShowDialog(false);
                // setShowLoading(false);
                toggleButtonState(btnCloseRef, false);
                toggleButtonState(btnCancelRef, false);
                // onClearRowSelection - callback function to clear row selection
                onClearRowSelection && onClearRowSelection();
            });
    }

    // userEffect hook to load historical sales rejected data during page load
    useEffect(() => {
        /* setTableData([
            {UUID: 'derkfs234m23423-natka3-dsf', ST_C: 'AK', MKT_D: 'Anchorage-SGWS', DIST_D: 'SOUTHERN GLAZER\'S WINE & SP-PUYALLUP, WA', JDE_ADDR_BOOK_N: 431821, FILE_DTE_E: '20170406', FILE_TIME_E: '180100', FILE_M: 'N20170406WK.SLS', FILE_DATE_T: '2017-04-06T12:31:00.000Z', FILE_DATE: '04/06/2017 06:01 PM', FROM_INVC_DATE: '05-JAN-2016', TO_INVC_DATE: '30-DEC-2016', RECORD_COUNT: 117324},
            {UUID: 'derkfasdsm23423-naka3-dsf', ST_C: 'AK', MKT_D: 'Anchorage-SGWS', DIST_D: 'SOUTHERN GLAZER\'S WINE & SP-PUYALLUP, WA', JDE_ADDR_BOOK_N: 431821, FILE_DTE_E: '20180308', FILE_TIME_E: '140300', FILE_M: 'N20180308DA.SLS', FILE_DATE_T: '2018-03-08T08:33:00.000Z', FILE_DATE: '03/08/2018 02:03 PM', FROM_INVC_DATE: '06-DEC-2017', TO_INVC_DATE: '29-DEC-2017', RECORD_COUNT: 6828},
            {UUID: 'derkf442344m23423-ngaka3-dsf', ST_C: 'AK', MKT_D: 'Fairbanks-SGWS', DIST_D: 'SD-SOUTHERN-ODOM WINE-FAIR-Fairbanks, WA', JDE_ADDR_BOOK_N: 491436, FILE_DTE_E: '20170406', FILE_TIME_E: '180100', FILE_M: 'N20170406WK.SLS', FILE_DATE_T: '2017-04-06T12:31:00.000Z', FILE_DATE: '04/06/2017 06:01 PM', FROM_INVC_DATE: '05-JAN-2016', TO_INVC_DATE: '30-DEC-2016', RECORD_COUNT: 33079},
            {UUID: 'derkfsrr4m23423-nak5a3-dsf', ST_C: 'AK', MKT_D: 'Fairbanks-SGWS', DIST_D: 'SD-SOUTHERN-ODOM WINE-FAIR-Fairbanks, WA', JDE_ADDR_BOOK_N: 491436, FILE_DTE_E: '20180308', FILE_TIME_E: '140300', FILE_M: 'N20180308DA.SLS', FILE_DATE_T: '2018-03-08T08:33:00.000Z', FILE_DATE: '03/08/2018 02:03 PM', FROM_INVC_DATE: '06-DEC-2017', TO_INVC_DATE: '29-DEC-2017', RECORD_COUNT: 2014},
            {UUID: 'derkrwer234m23423-naerka3-dsf', ST_C: 'AK', MKT_D: 'Juneau-SGWS', DIST_D: 'SD-SOUTHERN-ODOM WINE-JUNE-FAIRBANKS, AK', JDE_ADDR_BOOK_N: 491437, FILE_DTE_E: '20170406', FILE_TIME_E: '180100', FILE_M: 'N20170406WK.SLS', FILE_DATE_T: '2017-04-06T12:31:00.000Z', FILE_DATE: '04/06/2017 06:01 PM', FROM_INVC_DATE: '05-JAN-2016', TO_INVC_DATE: '30-DEC-2016', RECORD_COUNT: 15295},
            {UUID: 'derkfhrh4m23423-nakfha3-dsf', ST_C: 'AK', MKT_D: 'Juneau-SGWS', DIST_D: 'SD-SOUTHERN-ODOM WINE-JUNE-FAIRBANKS, AK', JDE_ADDR_BOOK_N: 491437, FILE_DTE_E: '20180308', FILE_TIME_E: '140300', FILE_M: 'N20180308DA.SLS', FILE_DATE_T: '2018-03-08T08:33:00.000Z', FILE_DATE: '03/08/2018 02:03 PM', FROM_INVC_DATE: '06-DEC-2017', TO_INVC_DATE: '29-DEC-2017', RECORD_COUNT: 763},
            {UUID: 'derkf4234m23423-nak56a3-dsf', ST_C: 'AL', MKT_D: 'Bessemer United-JB', DIST_D: 'UNITED-JOHNSON BROS OF ALAB-BESSEMER, AL', JDE_ADDR_BOOK_N: 442739, FILE_DTE_E: '20170720', FILE_TIME_E: '130100', FILE_M: 'N20170720MN.SLS', FILE_DATE_T: '2017-07-20T07:31:00.000Z', FILE_DATE: '07/20/2017 01:01 PM', FROM_INVC_DATE: '06-FEB-2017', TO_INVC_DATE: '14-APR-2017', RECORD_COUNT: 24382},
            {UUID: 'derkfs233g53423-nak9a3-dsf', ST_C: 'AL', MKT_D: 'Bessemer United-JB', DIST_D: 'UNITED-JOHNSON BROS OF ALAB-BESSEMER, AL', JDE_ADDR_BOOK_N: 442739, FILE_DTE_E: '20170811', FILE_TIME_E: '080500', FILE_M: 'N20170811DA.SLS', FILE_DATE_T: '2017-08-11T02:35:00.000Z', FILE_DATE: '08/11/2017 08:05 AM', FROM_INVC_DATE: '08-MAY-2015', TO_INVC_DATE: '31-MAY-2017', RECORD_COUNT: 1347800}
        ]); */


        // Get historical sales rejected data
        (async () => {
            await HistSalesRejectedDataService.getAllHistoricalRejectedData()
                .then(response => {
                    const {data_hist_sales_rejected} = response.data;
                    setTableData(data_hist_sales_rejected.rows || []);
                    if (!data_hist_sales_rejected.status.isOk) {
                        showSnackbarMessage(env.getEnv('REACT_APP_MSG_RETRIEVE_FAILED'), severity_level.error);
                    }
                })
                .catch(error => {
                    showSnackbarMessage((error?.response?.data?.message || error?.message), severity_level.error);
                    setTimeout(() => setTableData([]), 5000);
                });
        })();
    }, [showSnackbarMessage])



    // Customized Grid Properties
	const customProps = {
		title: 'Historical Sales Data',
        isModifyEnabled: isModifyEnabled,
        loader: {
            showLoader: showLoader,
            showOverlayLoader: showOverlayLoader
        },
        vgrid: {
            id: 'idSlsHistRejctCntr',
            rowData: tableData,
            tblProps: tableInputProps,
            dimensions: {
                height: gridDimension?.height,
                width: gridDimension?.width,
                // actionColumnWidth: 80
            },
            psearch: {
                // width: 200
                
            }


        }
    }


	return (
		<>
            <VirtualizedDataGrid1 {...customProps}/>

            {/* <SMISHistSalesRejectedDataTable
				title="Historical Sales Data"
				columns={tableColumns}
				rows={tableData}
                tableDimension={tableDimension}
				options={tableOptions}
				actions={tableColumnActions}
                isLoading={showLoading}
                alertMessage={showSnackbarMessage}
                contextMenuItems={contextMenuItems}
                searchColumns={tableColumnSearch}
                extras={{
                    isModifyEnabled: isModifyEnabled
                }}/> */}
            
            {/* Process / Delete Historical Rejected Data Dialog */}
            <Dialog open={showDialog} style={{pointerEvent: 'auto'}} aria-labelledby="dialog-title" aria-describedby="dialog-description">
                <DialogTitle id="dialog-title">
                    <Typography align="left">{dialogType.title} Historical Sales Data</Typography>
                    <IconButton ref={btnCancelRef} aria-label="close" onClick={dialogClose} sx={{position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500]}}>
                        <CloseIcon/>
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <DialogContent id="dialog-description">

                    {(dialogType.type === 'PM' || dialogType.type === 'DM') && 
                        <>
                            {dialogType.type === 'PM' && <Typography style={{color: '#095eb3'}} align="left">Please confirm to process the selected record(s).</Typography>}
                            {dialogType.type === 'DM' && <Typography style={{color: '#ff0000'}} align="left">Please confirm to delete the selected record(s) permanently.</Typography>}
                        </>
                    }

                    {(dialogType.type === 'P' || dialogType.type === 'D') && 
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={3} style={{padding: '0 16px 20px'}}>
                                    {dialogType.type === 'P' && <Typography style={{color: '#095eb3'}} align="left">Please confirm to process the selected record.</Typography>}
                                    {dialogType.type === 'D' && <Typography style={{color: '#ff0000'}} align="left">Please confirm to delete the selected record permanently.</Typography>}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>State: {selectedRow.ST_C}</TableCell>
                                    <TableCell>Dis Nbr: {selectedRow.JDE_ADDR_BOOK_N}</TableCell>
                                    <TableCell>Re Count: {selectedRow.RECORD_COUNT}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Market</TableCell>
                                    <TableCell colSpan={2}>{selectedRow.MKT_D}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Dist Name</TableCell>
                                    <TableCell colSpan={2}>{selectedRow.DIST_D}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>File Name</TableCell>
                                    <TableCell colSpan={2}>{selectedRow.FILE_M}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    }
                    </DialogContent>

                    <DialogActions>
                        <Button loadingposition="start" startIcon={<CloseIcon/>} ref={btnCloseRef} onClick={dialogClose} variant="outlined">Close</Button>
                        <LoadingButton variant="contained" color="primary" loadingPosition="start" startIcon={<NavigateNextIcon/>}
                            {...(dialogType.icon && {startIcon: <dialogType.icon/>})} loading={btnLoading} disabled={btnDisabled}
                                onClick={(e) => dialogType.action(e, selectedRow)}>{dialogType.actionText}</LoadingButton>
                    </DialogActions>
                </DialogContent>
            </Dialog>

			<Snackbar open={openSnackbar} autoHideDuration={5000} onClose={handleCloseSnackbar}>
				<Alert onClose={handleCloseSnackbar} severity={snackbarMessage.severity} sx={{width: '100%'}}>{snackbarMessage.message}</Alert>
			</Snackbar>
		</>
	)
}
