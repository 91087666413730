import React, { useState, useEffect, useCallback } from 'react';
import styled from '@emotion/styled';
import { List } from 'react-virtualized';
import * as ColumnResizer  from 'react-draggable';
import { SearchBoxDialog } from './component.search.dialog';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
import ViewColumnOutlinedIcon from '@mui/icons-material/ViewColumnOutlined';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import { ConstCfg } from '../utils/utils';
import { CircularProgressLoader, OverlayLoader, SnackbarAlert } from '../components/component.snackbar.alert';
import { Checkbox, Divider, IconButton, Input, InputAdornment, Menu, MenuItem, TableSortLabel } from '@mui/material';
import { EditInline } from './component.iedit';


// Table main container
export const Container = styled.div`
	display: flex;
	margin-top: 8px;
`;
// Table row container
export const TableRowContainer = styled.div`
    flex-grow: 1;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    // background-color: #00ff3b1f;
`;
// Caption bar div style
export const StyledCaptionBar = styled.div`
	display: flex;
	margin: 0 0 1.2em;
	padding: 0 .1em;
    // background-color: #00ff1f;
`;
// Table title style
export const Title = styled.h4`
	margin: 0;
	align-items: center;
    display: flex;
	flex-grow: 1;
	font-size: 0.92rem;
	user-select: none;
`;
// Place holder inner content div
export const StyledPlaceHolderContainerDiv = styled.div`
	overflow-x: auto;
    // background: aquamarine;
    // min-height: 500px;
`;
// Place holder inner content div
export const StyledPlaceHolderInnerContainerDiv = styled.div`
    width: 100%;
`;
// No records found div
export const StyledNoRecordDiv = styled.div`
	white-space: nowrap;
	display: grid;
	font-size: 0.875rem;
	padding: 6em;
	text-align: center;
	color: #979797;
`;
// Column field name / data div style
export const StyledColumnContainerDiv = styled.div`
	display: flex;
  	flex-direction: row;
  	align-items: center;
	background-color: #f2f2f2;
	border-top: 1px solid #cbcbcb;
	border-bottom: 1px solid #cbcbcb;
    height: 3em;
	padding-right: 10px;
	user-select: none;
`;

// Item row container style
export const StyledRowItemContainer = styled(StyledColumnContainerDiv)`
    // border-top: 1px solid #cbcbcb;
	border-bottom: 1px solid #dfdfdf;
`;

// Column filter div style
export const StyledFilterContainerDiv = styled(StyledColumnContainerDiv)`
	border-top: 0 none;
	border-bottom: 1px solid #cbcbcb7a;
	background-color: #f1f1f159;
`;
// Row item / data div style
export const StyledRowItemDiv = styled.div`
	display: flex;
	align-items: center;
	text-overflow: ellipsis;
    white-space: nowrap;
	margin-left: 0.7em;
    min-width: 40px;
    height: 100%;
`;
// Filter row field div style
export const StyledFilterItemDiv = styled(StyledRowItemDiv)`
	&>.MuiInputBase-root {
		font-size: inherit;
		&>.MuiInputBase-input {
            padding: 3px 2px;
		}
		&>.MuiInputAdornment-root {
			margin-right: 2px;
		}
	}
	&>.MuiInputBase-root:before {
		border-bottom: 1px solid rgba(0, 0, 0, 0.22);
	}
	&>.MuiInputBase-root:after {
		border-bottom: 1px solid #1976d2;
	}
	&>.MuiInputBase-root:hover:not(.MUI-disabled):before {
		border-bottom: 1px solid rgba(0, 0, 0, 0.22);
	}
`;
// Column filter div style
export const StyledFilterInput = styled(Input)`
    width: 96%;
	background-color: #ffffff;
`;
// Column sort div style
export const StyledColumnSortDiv = styled(TableSortLabel)`
	flex: auto;
	display: inline-flex;
	align-items: center;
	max-width: 100%;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	background-color: inherit !important;
	&>.MuiTableSortLabel-icon {
		width: 0.6em;
		margin-left: 2px;
		margin-right: 2px;
	}
`;
// Row item text style div
export const StyledRowItemText = styled.div`
	flex: auto;
	display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
	&>span {
		background-color: #ffff66;
	}
`;
// Row action item style div
export const StyledActionItem = styled.span`
    flex-grow: 1;
    padding: 0.1em;
    &>.MuiButtonBase-root {
        &>.MuiSvgIcon-root {
            width: 0.68em;
            height: 0.68em;
        }
    }
`;
// List menu item style
export const StyledListItem = styled(MenuItem)`
	font-size: 1.1em !important;
    padding-top: 4px;
    padding-bottom: 4px;
	&>.MuiCheckbox-root {
		padding: 2px;
		margin-right: 4px;
	}
`;
// Column resizer disable div style
export const StyledColumnResizeDisableDiv = styled.span`
    z-index: 2;
	display: flex;
	flex: 0 0 .2em;
	color: #cbcbcb;
`;
// Column resizer div style
export const StyledColumnResizeHandler = styled(StyledColumnResizeDisableDiv)`
    cursor: col-resize;
	background-color: inherit !important;
	&:hover, &:active {
		z-index: 3;
		color: #646464;
		background-color: #cbcbcbba !important;
	}
`;
// Place holder inner content div
export const StyledPlaceHolderDiv = styled.div`
    padding: .1em 0;
	// border: 1px solid blue;
`;


/**
 * Material virtualized data grid component
 */
export const VirtualizedDataGrid1 = (props) => {
    const {
        title, 
        isModifyEnabled, 
        loader: {
            showLoader,
            showOverlayLoader
        },
        vgrid: {
            rowData,/* : {
                odata, 
                fdata, 
                cdata
            }, */
            tblProps,
            psearch={},
            dimensions,
            selectedRow
        }
    } = props;
    const [sortColumnOrder, setSortColumnOrder] = useState({
		sortBy: '',
		sortOrder: 'asc'
	});
    const [gridRowData, setGridRowData] = useState({
        odata: undefined,
        fdata: undefined,
        cdata: undefined
    });
    // const [selectedRow, setSelectedRow] = useState({});
    const [filterColumns, setFilterColumns] = useState();
	const [scrollToIndex, setScrollToIndex] = useState(-1);
    const [gridColumnWidths, setGridColumnWidths] = useState({/* _ACT_COL: dimensions?.actionColumnWidth || 80 */});
    const [xRefGridColumns, setXRefGridColumns] = useState(tblProps?.columns || []);
    const [gridColumnWidthTotal, setGridColumnWidthTotal] = useState(dimensions?.width);
    const ActionColumnWidth = tblProps?.options?.action ? dimensions?.actionColumnWidth || 60 : 0;
    
    // console.log('VirtualizedDataGrid1', props, ActionColumnWidth);

    // Initialize columns width
    const initColumnsWidth = (columns) => {
        /* let columnsWidth = {};
        columns?.forEach(({field, width=60, visible=false}) => (columnsWidth = {
            ...columnsWidth, 
            [field]: visible && width ? width : 0
        })); */
        return columns.reduce((column, {field, width=60, visible=false}) => {column[field] = visible && width ? width : 0; return column;}, {});
    }

    // Calculate columns total width
    const initColumnsTotalWidth = (columns, actionColWidth) => {
        return (columns?.reduce((prev, curr) => curr?.visible && curr?.width ? prev + curr?.width : prev, 0) + actionColWidth);
    }
    
    // Re-calculate and initialize columns
    const reInitColumns = (columns, item) => {
        return columns.map(column => (column.field === item.field ? {...column, visible: !item?.visible} : column));
    }

    // Calculate grid width based on columns
    const getGridWidth = useCallback((calculatedWidth) => {
        const {width} = dimensions;
        return calculatedWidth > width ? calculatedWidth : (width || 100);
    }, [dimensions])

    // onChangeColumnMenuItemCallback to show / hide grid columns
    const onChangeColumnMenuItemCallback = useCallback((e, item, isAllChecked) => {
        const columns = [...xRefGridColumns];

        // Re-initialize columns
        // const xRefGridColumnsNew = reInitColumns(columns, item);
        const xRefGridColumnsNew = item ? reInitColumns(columns, item) : columns.map(column => ({...column, visible: isAllChecked}));
        setXRefGridColumns(xRefGridColumnsNew);

        // Reset columns width as per column visibility
        const gridColumnWidthsNew = initColumnsWidth(xRefGridColumnsNew);
        setGridColumnWidths(prev => ({...prev, ...gridColumnWidthsNew}));

        // Set grid columns total width
        const gridColumnsWidthTotalNew = getGridWidth(initColumnsTotalWidth(xRefGridColumnsNew, ActionColumnWidth));
        setGridColumnWidthTotal(gridColumnsWidthTotalNew);
    }, [xRefGridColumns, getGridWidth, ActionColumnWidth])

    // onSortColumn callback to sort column
    const onSortColumnCallback = useCallback((e, props) => {
        const {sortCol, altSortCol} = props;
        const sortColumnField = altSortCol || sortCol;
        let {sortBy, sortOrder} = {...sortColumnOrder};

        // Set sort order and sort by value
        if (sortBy === sortColumnField) {
            sortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        }
        else {
            sortBy = sortColumnField;
            sortOrder = 'asc';
        }
        
        // Set column ordering
        setSortColumnOrder(prev => ({
            ...prev,
            sortBy: sortBy,
            sortOrder: sortOrder
        }));

        // Set sorted data
        setGridRowData(prev => ({
            ...prev,
            cdata: prev.cdata?.sort((i, j) => {
                if (i[sortColumnField] < j[sortColumnField]) {
                    return sortOrder === 'asc' ? -1 : 1;
                }
                if (i[sortColumnField] > j[sortColumnField]) {
                    return sortOrder === 'asc' ? 1 : -1;
                }
                return 0;
            })
        }));
    }, [sortColumnOrder])
    
    // onFilterChangeCallback callback to filter column data
    const onFilterChangeCallback = useCallback(({target: {name, value}}) => {
        const {odata} = gridRowData;
        const filterColumnsNew = {...filterColumns, [name]: value};
        
        // Filtered data
        const filteredData = odata?.filter(data => Object.entries(filterColumnsNew)
            .every(([key, val]) => !val?.length || (data[key] || '')?.toString().toLowerCase().includes((val || '').toLowerCase())));
        
        // Set new filter object and data
        setFilterColumns(filterColumnsNew);
        setGridRowData(prev => ({
            ...prev,
            fdata: filteredData,
            cdata: filteredData
        }));
    }, [filterColumns, gridRowData])
    
    // onResizeColumn callback to resize column while dragging
    const onResizeColumnCallback = useCallback((e, props) => {
        let nextDataKey = '';
        const columns = [...xRefGridColumns];
        const {dataKey, deltaX, gridWidth} = props;
        const percentDelta = (deltaX / gridWidth) * 1000;
        columns.forEach((column, i) => {
            if (column?.field === dataKey) {
                nextDataKey = columns[i + 1]?.field || null; //'_ACT_COL';
            }
        });
        setGridColumnWidths(prev => ({
            ...prev,
            [dataKey]: prev[dataKey] + percentDelta,
            [nextDataKey]: prev[nextDataKey] - percentDelta
        }));
    }, [xRefGridColumns])

    // onRowActionClick callback to perform specific action
    const onRowActionClickCallback = useCallback((e, rowItem, rowAction) => {
		if (!isModifyEnabled) {
			return false;
		}

		rowAction?.onClick && 
            rowAction?.onClick(e, {rowItem: rowItem});
    }, [isModifyEnabled])

    // onSearch callback
    const onSearchCallback = useCallback((e, result) => {
        const {foundFirstIndex, searchData: {cdata}} = result;
        setGridRowData(prev => ({
            ...prev,
            cdata: cdata
        }));
		setScrollToIndex(foundFirstIndex);
    }, [])

    // onSearchClear callback
    const onSearchClearCallback = useCallback((e, {odata}) => {
        setScrollToIndex(-1);
        setGridRowData(prev => ({
            ...prev,
            cdata: odata
        }));
    }, [])
    
    // onSearchFirstCallback callback
    const onSearchFirstCallback = useCallback((e, searchedIndex) => {
		setScrollToIndex(searchedIndex);
    }, [])

    // onSearchLastCallback callback
    const onSearchLastCallback = useCallback((e, searchedIndex) => {
		setScrollToIndex(searchedIndex);
    }, [])

    // onSearchPrevCallback callback
    const onSearchPrevCallback = useCallback((e, searchedIndex) => {
		setScrollToIndex(searchedIndex);
    }, [])

    // onSearchNextCallback callback
    const onSearchNextCallback = useCallback((e, searchedIndex) => {
		setScrollToIndex(searchedIndex);
    }, [])
   










	// userEffect hook to load data if changes
    useEffect(() => {
        const columns = [...xRefGridColumns];
        // console.log('>useEffect>columns', columns);
        
        // Set filter columns
        !filterColumns && 
            setFilterColumns(columns.reduce((column, {field}) => {column[field] = ''; return column;}, {}));
        
        // Set indivisual grid columns width
        const gridColumnWidthsNew = initColumnsWidth(columns);
        setGridColumnWidths(prev => ({...prev, ...gridColumnWidthsNew}));

        // Set grid columns total width
        const gridColumnsWidthTotalNew = getGridWidth(initColumnsTotalWidth(columns, ActionColumnWidth));
        setGridColumnWidthTotal(gridColumnsWidthTotalNew);
	}, [xRefGridColumns, filterColumns, getGridWidth, ActionColumnWidth]);

    // userEffect hook to load data on page load
    useEffect(() => {
        // console.log('>useEffect>setGridRowData>rowData>', rowData);
        setGridRowData(prev => ({
            ...prev,
            odata: rowData,
            fdata: rowData,
            cdata: rowData

        }));
    }, [rowData])


	// Customized Grid Properties
	const gridCustomProps = {
        title: title,
        showLoader: showLoader,
        isModifyEnabled: isModifyEnabled,
        vgrid: {
            gridData: gridRowData,
            selectedRow: selectedRow,
            scrollToIndex: scrollToIndex,
            tblProps: {
                ...tblProps, 
                fcolumns: xRefGridColumns
            },
            dimensions: {
                gridWidth: dimensions?.width,
                gridHeight: dimensions?.height,
                gridColWidths: gridColumnWidths,
                actionColWidth: ActionColumnWidth,
                gridTotalWidth: gridColumnWidthTotal
            },
            psearch: {
                ...psearch,
                gridData: gridRowData,
                columns: xRefGridColumns,
                callbacks: {
                    onSearch: onSearchCallback,
                    onSearchClear: onSearchClearCallback,
                    onSearchFirst: onSearchFirstCallback,
                    onSearchLast: onSearchLastCallback,
                    onSearchPrev: onSearchPrevCallback,
                    onSearchNext: onSearchNextCallback
                }
            },
            psorts: {
                sortColumnOrder: sortColumnOrder,
                onSortColumn: onSortColumnCallback
            },
            callbacks: {
                onFilterChange: onFilterChangeCallback,
                onResizeColumn: onResizeColumnCallback,
                onRowActionClick: onRowActionClickCallback
            },
            dwcolmenu: {
                onChangeColumnMenuItem: onChangeColumnMenuItemCallback
            }
        }

		/* title: 'Distributor Product XRef Validation',
        showLoader: showLoader,
        isModifyEnabled: isModifyEnabled,
        vgrid: {
            id: 'idPrdXrefValidationCntr',
            rowData: rowData.cdata,
            selectedRow: selectedRow,
            tblProps: {
                ...tableInputProps, 
                columns: xRefGridColumns
            },
            gridWidth: tableDimension?.width,
            gridHeight: tableDimension?.height,
            gridTotalWidth: gridColumnWidthTotal,
            gridColWidths: gridColumnWidths,
            scrollToIndex: scrollToIndex,
            psearch: {
                rowData: rowData,
                columns: xRefGridColumns,
                callbacks: {
                    onSearch: onSearchCallback,
                    onSearchClear: onSearchClearCallback,
                    onSearchFirst: onSearchFirstCallback,
                    onSearchLast: onSearchLastCallback,
                    onSearchPrev: onSearchPrevCallback,
                    onSearchNext: onSearchNextCallback
                }
            },
            psorts: {
                sortColumnOrder: sortColumnOrder,
                onSortColumn: onSortColumnCallback
            },
            callbacks: {
                onFilterChange: onFilterChangeCallback,
                onResizeColumn: onResizeColumnCallback,
                onRowActionClick: onRowActionClickCallback
            },
            dwcolmenu: {
                onChangeColumnMenuItem: onChangeColumnMenuItemCallback
            }
        } */
    }

	return (
		<Container>
            <SnackbarAlert/>
            <VirtualizedGridContainer {...gridCustomProps}/>
            <OverlayLoader loading={showOverlayLoader}/>
        </Container>
    )
}


// Main Virtualized Grid Container
const VirtualizedGridContainer = React.memo(props => {
    const {
        vgrid: {
            dimensions: {gridWidth}
        }
    } = props;

    return (
        <TableRowContainer style={{width: `${gridWidth}px`}}>
            <TableCaptionBar {...props} />
            <TableGridContainer {...props}/>
            {/* <PopupDialog {...props.vgrid.pdialog}/> */}
        </TableRowContainer>
    )
})

// Table Caption Bar - Title / Search
const TableCaptionBar = React.memo(props => {
    const {
        title,
        vgrid: {
            psearch,
            gridData: {
                cdata: rowData
            },
            dwcolmenu,
            tblProps: {fcolumns}
        }
    } = props;

    return (
        <StyledCaptionBar>
            <Title>{title} ({(rowData && rowData?.length) || 0})</Title>
            <MoreColumnsRenderer columns={fcolumns} dwcolmenu={dwcolmenu}/>
            <SearchBoxDialog {...psearch}/>
        </StyledCaptionBar>
    )
})

// Table Grid Container
const TableGridContainer = React.memo(props => {
    const {
        showLoader,
        vgrid: {
            id,
            scrollToIndex,
            gridData: {
                cdata: rowData
            },
            dimensions: {
                gridWidth,
                gridTotalWidth
            },
            tblProps: {options}
        }
    } = props;

    return (
        <StyledPlaceHolderContainerDiv id={id}>
            <StyledPlaceHolderInnerContainerDiv style={{width: `${gridTotalWidth}px`}}>
                <TableColumnContainer {...props.vgrid} />
                {options?.filtering && 
                    <TableFilterContainer {...props.vgrid} />}
                
                <StyledPlaceHolderDiv>
                    <CircularProgressLoader width={gridWidth} loading={showLoader}/>
                    <List 
                        height={495}
                        rowCount={rowData?.length || 0}
                        rowHeight={30}
                        width={gridTotalWidth}
                        style={{
                            backgroundColor: getBackgroundColor(),
                            transition: 'background-color 0.2s ease'
                        }}
                        rowRenderer={getRowRender(props)}
                        scrollToIndex={scrollToIndex}
                        overscanRowCount={17}
                        scrollToAlignment={'start'}
                        noRowsRenderer={() => <NoRowsRenderer width={gridWidth}/>}/>
                </StyledPlaceHolderDiv>
            </StyledPlaceHolderInnerContainerDiv>
        </StyledPlaceHolderContainerDiv>
    )
})

// Table column container
const TableColumnContainer = React.memo(props => {
	const {
        // dwcolmenu,
        dimensions: {gridTotalWidth, gridColWidths, actionColWidth},
        tblProps: {fcolumns, options},
        callbacks: {onResizeColumn},
        psorts: {
            onSortColumn,
            sortColumnOrder: {sortBy, sortOrder}
        }
    } = props;
    /* const [anchorEl, setAnchorEl] = React.useState(null);
    const anchorElOpen = Boolean(anchorEl); */
    
    return (
		<StyledColumnContainerDiv role="row" style={{fontWeight: 500}}>
			{fcolumns.map((column, idx) => {
                const gridColWidth = gridColWidths[column.field];
                
                return (
                    column?.visible && 
                        <StyledRowItemDiv role="columnheader" key={`h-${column.field}${idx}`} style={{flex: `0 1 ${gridColWidth}px`}}>
                            {column?.sorting 
                                ? <StyledColumnSortDiv style={{justifyContent: column?.align, textAlign: column?.align}} active={sortBy === (column?.sortColumn || column.field)} 
                                        direction={sortOrder} onClick={(e) => onSortColumn(e, {sortCol: column.field, altSortCol: column?.sortColumn})}>{column.label}</StyledColumnSortDiv>
                                : <StyledRowItemText style={{justifyContent: column?.align, textAlign: column?.align}}>{column?.label}</StyledRowItemText>
                            }
                            <ColumnResizerRenderer gridWidth={gridTotalWidth} column={column} onResize={onResizeColumn} /* isOpen={anchorElOpen} setAnchorEl={setAnchorEl} *//>
                        </StyledRowItemDiv>
				)
			})}
			{options?.action && 
                <StyledRowItemDiv style={{flex: `0 1 ${actionColWidth}px`}}>
                    <StyledRowItemText style={{justifyContent: 'center', textAlign: 'center',}}>{'Actions'}</StyledRowItemText>
                </StyledRowItemDiv>}
            {/* <DropdownColumnMenu isOpen={anchorElOpen} anchorEl={anchorEl} onDpColumnMenuClose={() => setAnchorEl(null)} columns={fcolumns} {...dwcolmenu}/> */}
		</StyledColumnContainerDiv>
	)
})

// Column resizer placeholder
const ColumnResizerRenderer = React.memo((props) => {
	const {gridWidth, column, onResize/* , isOpen, setAnchorEl */} = props;
    
    return (
		<>
            {/* <IconButton aria-label="more" id={`long-button`} aria-controls={isOpen ? `dwpcolmenu-long` : undefined} aria-haspopup={true} aria-expanded={isOpen ? 'true' : undefined} 
                onClick={(e) => setAnchorEl({target: e.currentTarget, parent: column.field})} style={{padding: 0, borderRadius: '40%', color: 'rgb(0 0 0 / 28%)'}}>
                <MoreVertIcon sx={{width: '0.5em', height: '0.7em'}}/>
            </IconButton> */}
            
			{column.field && (column?.resize === undefined || !!column?.resize) 
				? <ColumnResizer axis="x" onDrag={(e, {deltaX}) => onResize(e, {gridWidth: gridWidth, dataKey: column.field, deltaX: deltaX})} position={{x: 0}}>
				    <StyledColumnResizeHandler>|</StyledColumnResizeHandler>
				  </ColumnResizer>
				: <StyledColumnResizeDisableDiv>|</StyledColumnResizeDisableDiv>
			}
		</>
	)
})

// Table column filter container
const TableFilterContainer = React.memo(props => {
    const {
        tblProps: {fcolumns, options},
        dimensions: {gridColWidths, actionColWidth},
        callbacks: {onFilterChange}
    } = props;

    return (
        <StyledFilterContainerDiv role="row" style={{fontWeight: 500}}>
            {fcolumns.map((column, idx) => {
                const gridColWidth = gridColWidths[column.field];
                
                return (
                    column?.visible && (column?.filter 
						? <StyledFilterItemDiv key={`f-${column.field}${idx}`} style={{flex: `0 1 ${gridColWidth}px`}}>
                                <StyledFilterInput id={`filter-${column.field}-input`} onKeyUp={onFilterChange} name={column.field} 
									startAdornment={<InputAdornment position="start"><FilterListOutlinedIcon sx={{width: '0.65em'}}/></InputAdornment>}>
								</StyledFilterInput>
                            </StyledFilterItemDiv>
                        : <StyledFilterItemDiv key={`f-${column.field}${idx}`} style={{flex: `0 1 ${gridColWidth}px`}}/>)
                )
            })}
            {options?.action && 
                <StyledFilterItemDiv style={{flex: `0 1 ${actionColWidth}px`}}/>}
        </StyledFilterContainerDiv>
	)
})

// Using a higher order function so that we can look up the rows data to retrieve row from within the rowRender function
const getRowRender = (props) => ({index, style, parent: {props: {rowCount}}}) => {
    // console.log('>getRowRender>props>', props);
    const {
        isModifyEnabled,
        vgrid: {
            selectedRow,
            gridData: {
                cdata: rowData
            },
            dimensions: {gridColWidths, actionColWidth},
            tblProps: {fcolumns, options, actions, mactions},
            callbacks: {onRowActionClick}
        }
    } = props;
    const row = rowData[index] || undefined;
	if (!row) {
		return null;
	}
	// Rendering an extra item for the placeholder by increasing data set size to include one 'fake' item
	const patchedStyle = {
		...style,
		left: style.left,
		top: style.top + 2,
		width: style.width,
		height: style.height - 0.5,
        paddingRight: rowCount < 18 ? 10: 0
	};
    const isRowMatched = (row?.UUID && (selectedRow?.UUID === row?.UUID));
    const backgroundColors = isRowMatched ? {color1: ConstCfg.Colors.LIGHT_YELLOW} : undefined;

    return (
        <StyledRowItemContainer role="row" key={`${row?.UUID}-${index}`} style={{...getStyle(patchedStyle, backgroundColors)}}>
            {fcolumns?.map(column => {
                const gridColWidth = gridColWidths[column.field];
                // const newRowFieldName = `${ConstCfg._FLD_PREFIX}${column.field}`;
				/* const _textValue = column?.format && typeof row[column.field] === 'number' 
                    ? column.format(row[column.field]) 
                        : getMarkdownText(row[column.field]); */
                
                return (
                    column?.visible && 
                        <StyledRowItemDiv role="gridcell" key={`${row.UUID}-${column.field}`} 
                            style={{justifyContent: column?.align, overflow: 'hidden', flex: `0 1 ${gridColWidth}px`}}>
                                <EditInline selectedRow={selectedRow} isRowMatched={isRowMatched} options={options} actions={actions} mactions={mactions} /* onRowActionClick={onRowActionClick}  */isModifyEnabled={isModifyEnabled} column={column} row={row}/>
                                {/* {column?.etdinline ? 
                                    isModifyEnabled ? 
                                        <StyledDropDownExd variant={'standard'} sx={{width: '70%'}}>
                                            <Select labelId="valid-flag-select-label" name={column.field} value={(isRowMatched && selectedRow[newRowFieldName]) || row[column.field]} defaultValue={'N'} 
                                                onChange={e => onRowActionClick(e, {...row, [newRowFieldName]: e.target.value}, mactions.action1)}>
                                                <StyledListItem value="N">N</StyledListItem>
                                                <StyledListItem value="Y">Y</StyledListItem>
                                            </Select>
                                        </StyledDropDownExd>
                                    : <StyledRowItemText style={{textAlign: column.align}} dangerouslySetInnerHTML={_textValue}/>
                                : <StyledRowItemText style={{textAlign: column?.align}} dangerouslySetInnerHTML={_textValue}/>} */}
                            {/* <StyledRowItemText style={{justifyContent: column?.align, textAlign: column?.align}} dangerouslySetInnerHTML={_textValue}/> */}
                        </StyledRowItemDiv>
                )
            })}
            {options.action &&
				<StyledRowItemDiv style={{flex: `0 1 ${actionColWidth}px`}}/*  style={{justifyContent: 'center', width: `${_ACT_COL_WIDTH}px`}} */>
				{actions?.map((action, idx) => (
                    <StyledActionItem key={`_actions_${idx}`}>
                        <IconButton title={action?.title} disabled={!isModifyEnabled} sx={{flexGrow: 1, padding: '.1em'}} onClick={e => onRowActionClick(e, row, action)}>
                            <action.icon/>
                        </IconButton>
                    </StyledActionItem>
				))}
				</StyledRowItemDiv>
			}
        </StyledRowItemContainer>
    )
}

// More Column placeholder
const MoreColumnsRenderer = React.memo((props) => {
	const {dwcolmenu, columns} = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const isOpen = Boolean(anchorEl);
    
    return (
		<div style={{display: 'flex', alignItems: 'center'}}>
            <IconButton aria-label="more" id={`long-button`} aria-controls={isOpen ? `dwpcolmenu-long` : undefined} aria-haspopup={true} aria-expanded={isOpen ? 'true' : undefined} 
                onClick={(e) => setAnchorEl({target: e.currentTarget/* , parent: column.field */})} style={{padding: 0, borderRadius: '10%', color: 'rgb(0 0 0 / 38%)'}}>
                {/* <MoreVertIcon sx={{width: '0.5em', height: '0.7em'}}/> */}
                <ViewColumnOutlinedIcon sx={{width: '0.8em'}}/>
                <span style={{fontSize: '0.675rem', color: 'rgba(0, 0, 0, 0.98)', padding: '3px 0 0 4px'}}>Columns</span>
            </IconButton>
            <DropdownColumnMenu isOpen={isOpen} anchorEl={anchorEl} onDpColumnMenuClose={() => setAnchorEl(null)} columns={columns} {...dwcolmenu}/>
        </div>
    )
})

// Dropdown menu - more columns
const DropdownColumnMenu = React.memo(props => {
    const {
        isOpen,
        columns,
        anchorEl,
        menuHeight=50,
        onDpColumnMenuClose,
        onChangeColumnMenuItem
    } = props;
    const isAllChecked = (columns?.reduce((acc, curr) => curr?.visible && ++acc, 0) === columns?.length);

    return (
        <Menu id={`dwpcolmenu-long`} MenuListProps={{'aria-labelledby': `long-button`}} 
            open={isOpen} onClose={onDpColumnMenuClose} anchorEl={anchorEl?.target} 
            PaperProps={{
                style: {
                    maxHeight: menuHeight * 5,
                    width: 'auto'
                }
            }}>
            <StyledListItem disableRipple onClick={e => onChangeColumnMenuItem(e, undefined, !isAllChecked)}>
                <Checkbox size={'small'} checked={isAllChecked} onChange={e => onChangeColumnMenuItem(e, undefined, !isAllChecked)} 
                    inputProps={{'aria-label': 'controlled'}}/>All Columns
            </StyledListItem>
            <Divider sx={{my: 0.5}} />
            {/* <div style={{padding: '5px 20px', fontWeight: 500}}>All Columns</div>
            <Divider sx={{my: 0.5}} /> */}
            {columns.map((item, idx) => (
                <StyledListItem disableRipple key={`dpcolmenu-${item.field}-${idx}`} {...(item.field !== anchorEl?.parent && {onClick: (e) => onChangeColumnMenuItem(e, item)})}>
                    <Checkbox size={'small'} {...(item.field === anchorEl?.parent && {disabled: true})} checked={item?.visible} 
                        {...(item.field !== anchorEl?.parent && {onChange: (e) => onChangeColumnMenuItem(e, item)})} inputProps={{'aria-label': 'controlled'}}/> {item.label}
                </StyledListItem>
            ))}
        </Menu>
    )
})

// No rows found placeholder
const NoRowsRenderer = React.memo(({width}) => {
	return (
		<StyledNoRecordDiv {...(width && {style: {width: `${width}px`}})}>No record(s) found!</StyledNoRecordDiv>
	)
})

// Get drag item style while dragging
const getStyle = (styles, bgColors) => {
	const {color1} = bgColors || {color1: 'inherit'};
	
	return {
		...styles,
		color: 'inherit',
		borderTop: '0 none',
		backgroundColor: color1
	};
}

// Backgroud color
const getBackgroundColor = () => {
	return 'inherit';
};

// Get Mark down text
/* const getMarkdownText = (value) => {
    return {__html: value};
} */
