import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { Input, InputAdornment, MenuItem, Backdrop, Menu, ListItemText, ListItemIcon, TableSortLabel } from '@mui/material';
import { List } from 'react-virtualized';
import styled from '@emotion/styled';
import CircularProgress from '@mui/material/CircularProgress';
import SearchIcon from '@mui/icons-material/Search';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import IconButton from '@mui/material/IconButton'
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ClearIcon from '@mui/icons-material/Clear';
import * as ColumnResizer  from 'react-draggable';


// Table mail container
const Container = styled.div`
	display: flex;
	margin-top: 8px;
`;
// Table row container
const TableRowContainer = styled.div`
	border-top-left-radius: 2px;
	border-top-right-radius: 2px;
	display: inline-block;
`;
// Table title style
const Title = styled.h4`
	margin: 0;
	align-items: center;
	display: flex;
	flex-grow: 1;
	font-size: 1rem;
	user-select: none;
`;
// Item row container style
const RowContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	user-select: none;
	border-bottom: 1px solid #dfdfdf;
`;
// Loading div style
const StyledLoadingDiv = styled.div`
	display: flex;
	align-items: center;
	padding: 1em 0.6em;
	color: #979797;
	font-size: 0.9rem;
	&>span {
		margin-right: 0.6em;
	}
`;
// Search field container style
const Search = styled.div`
	position: relative;
	margin-left: 1.5em;
	&>.MuiInputBase-root:before {
		border-bottom: 0px solid rgba(0, 0, 0, 0.22);
	}
	&>.MuiInputBase-root:hover:not(.MUI-disabled):before {
		border-bottom: 0px solid rgba(0, 0, 0, 0.22);
	}
	&>.MuiInputBase-root:after {
		border-bottom: 0px solid #1976d2;
	}
`;
const StyledSearchInput = styled(Input)`
	color: inherit;
	width: 14em;
`;
// Search Prev/Next Container
const SearchPrevNextContainer = styled.div`
	border-top: 1px solid rgba(0, 0, 0, 0.22);
	border-bottom: 1px solid rgba(0, 0, 0, 0.22);
	display: flex;
	position: absolute;
	width: 100%;
	background: #ffffaf;
	padding: 8px;
	z-index: 999;
	&>button {
		padding: 1px;
	}
`;
// Column field name / data div style
const ColumnContainerDiv = styled.div`
	display: flex;
  	flex-direction: row;
  	align-items: center;
	background-color: #f2f2f2;
	border-top: 1px solid #cbcbcb;
	border-bottom: 1px solid #cbcbcb;
    height: 3em;
	padding-right: 1.2em;
	user-select: none;
`;
// Column resizer div style
const StyledColumnResizeHandler = styled.span`
	flex: 0 0 .2em;
    display: flex;
	cursor: col-resize;
	color: #cbcbcb;
	z-index: 2;
	background-color: inherit !important;
	&:hover, &:active {
		color: #646464;
		z-index: 3;
		background-color: #cbcbcbba !important;
	}
`;
// Column resizer disable div style
const StyledColumnResizeDisableDiv = styled.span`
	flex: 0 0 .2em;
	display: flex;
	color: #cbcbcb;
	z-index: 2;
`;
// Column filter div style
const FilterContainerDiv = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0.3em 1.2em 0.3em 0;
	border-bottom: 1px solid #cbcbcb7a;
	background-color: #f1f1f159;
	user-select: none;
`;
// Body row field data div style
const FilterItemDiv = styled.div`
	align-items: center;
	text-overflow: ellipsis;
	white-space: nowrap;
	margin-right: .5em;
	min-width: 0px;
	height: 100%;
	display: flex;
	flex-direction: row;
	&:first-of-type {
		margin-left: .4em;
	}
	&:last-of-type {
		margin-right: 0;
	}
	&>.MuiInputBase-root {
		width: 100%;
		max-width: 96%;
	}
	&>.MuiInputBase-root:before {
		border-bottom: 1px solid rgba(0, 0, 0, 0.22);
	}
	&>.MuiInputBase-root:hover:not(.MUI-disabled):before {
		border-bottom: 1px solid rgba(0, 0, 0, 0.22);
	}
	&>.MuiInputBase-root:after {
		border-bottom: 1px solid #1976d2;
	}
`;
// Column filter div style
const FilterInput = styled(Input)`
	background-color: #ffffff;
`;
// Body row field data div style
const RowItemDiv = styled.div`
	display: flex;
	align-items: center;
	text-overflow: ellipsis;
    white-space: nowrap;
	margin-right: .5em;
    min-width: 0px;
    height: 100%;
	&:first-of-type {
		margin-left: .5em;
	}
	&:last-of-type {
		margin-right: 0;
	}
`;
// No records found div
const StyledNoRecordDiv = styled.div`
	white-space: nowrap;
	display: grid;
	font-size: 0.875rem;
	padding: 6em;
	text-align: center;
	color: #979797;
`;
// Caption bar div style
const StyledCaptionBar = styled.div`
	width: 100%;
	display: flex;
	margin: 0 0 1em;
	padding: 0 .1em;
	max-width: 100%;
`;
// Drag handler div style
/* const StyledDragHandler = styled.div`
	display: flex;
	flex: 0 1 69px;
    height: 100%;
	align-items: center;
	justify-content: center;
	margin-right: .5em;
`; */
// Action Menu handler div style - process/delete
const StyledActionMenuDiv = styled.div`
	display: flex;
	margin-left: 1.5em;
	align-items: center;
	& .MuiIconButton-root {
		padding: 0 5px;
	}
	& .MuiIconButton-root:last-child {
		padding: 0 0 0 5px;
		& .MuiSvgIcon-root {
			width: 0.9em !important;
			height: 0.9em !important;
		}
	}
`;
// Overlay loader div style
const StyledLoaderDiv = styled.div`
	background-color: #3ea2e5;
	display: flex;
	align-items: center;
	border-radius: 4px;
	padding: 0.8em;
	color: #ffffff;
`;
// Context Menu div style
const StyledContextMenuDiv = styled.div`
	cursor: context-menu;
`;
// Column sort div style
const StyledColumnSortDiv = styled(TableSortLabel)`
	flex: auto;
	display: inline-flex;
	align-items: center;
	max-width: 100%;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	background-color: inherit !important;
`;
// Row item text style div
const StyledRowItemText = styled.div`
	flex: auto;
	display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
	& >span {
		background-color: #ffff66;
	}
`;

// Default action column with
const ACTION_COLUMN_WITH = 96;

// SMIS Material data table component
export const SMISHistSalesRejectedDataTable = (props) => {
	const {rows, columns, extras, contextMenuItems, searchColumns, tableDimension} = props;
	const {isModifyEnabled} = extras;
	const tableRef = useRef(null);
	const searchInputRef = useRef(null);
	// const listContainerRef = useRef(null);

	const [rowData, setRowData] = useState();
	const [propRows, setPropRows] = useState();
	const [scrollToIndex, setScrollToIndex] = useState(-1);
	const [matchedSearchIds, setMatchedSearchIds] = useState([]);
	const [matchedSearchCount, setMatchedSearchCount] = useState({
		searchPrevNextCount: 0, 
		searchTotalCount: 0, 
		searchPrevBtnDisable: true, 
		searchNextBtnDisable: true
	});
	const [searchSelectedIndex, setSearchSelectedIndex] = useState();
	const [searchInputText, setSearchInputText] = useState();
	const [histDataProcessActionState, setHistDataProcessActionState] = useState({
		process: {disable: true, clicked: false},
		deletei: {disable: true, clicked: false},
		cancel: {disable: true, clicked: false}
	});
	const [histDataProcessItems, setHistDataProcessItems] = useState({
		indexItems: [],
		sourceItems: [],
		histDataProcessType: ''
	});
	const [filterObj, setFilterObj] = useState(columns.reduce((item, {field}) => {item[field] = ''; return item;}, {}));
	const [contextMenu, setContextMenu] = useState(null);
	const [sortColumns, setSortColumns] = useState({
		sortBy: '',
		sortOrder: 'asc'
	});
	const [columnWidths, setColumnWidths] = useState({_ACT_COL: ACTION_COLUMN_WITH / 1000});

	// Handle filter change
	const handleFilterChange = useCallback(({target: {name, value}}) => {
		const newFilterObj = {...filterObj, [name]: value};

		setFilterObj(newFilterObj);
		setRowData(propRows.filter(props => 
			Object
				.entries(newFilterObj)
					.every(([key, val]) => 
						!val?.length || (props[key] || '')?.toString().toLowerCase().includes((val || '').toLowerCase())
					)
		));
	}, [propRows, filterObj]);
	
	// Handle clear search field
	const handleClearSearch = useCallback((e) => {
		setScrollToIndex(-1);
		setRowData(propRows);
		setSearchInputText('');
		setSearchSelectedIndex(0);
		setMatchedSearchCount({
			searchPrevNextCount: 0, 
			searchTotalCount: 0, 
			searchPrevBtnDisable: true, 
			searchNextBtnDisable: true
		});
		searchInputRef.current.value = '';
	}, [propRows]);

	// Handle search functionality
	const handleSearch = useCallback((e) => {
		let matched_count = 0,
			_foundFirstIndex = -1,
			_copyData = [],
			_matchedSearchIds = [],
			_searchColumns = searchColumns,
			searchText = e.target.value || '';
		
		setSearchInputText(searchText);
		// Prevent search if phrase length < 1
		if (searchText.length < 1) {
			matched_count = 0;
			_foundFirstIndex = -1;
			setRowData(propRows);
			setSearchInputText('');
			setSearchSelectedIndex(0);
			setScrollToIndex(_foundFirstIndex);
			setMatchedSearchCount({
				searchPrevNextCount: 0, 
				searchTotalCount: matched_count, 
				searchPrevBtnDisable: true, 
				searchNextBtnDisable: true
			});
			return;
		}

		// Re-build search field
		if (!_searchColumns?.length) {
			_searchColumns = [];
			columns.forEach(clmn => _searchColumns.push(clmn.field));
		}
		let replacedSearchText = searchText.replace(/\\/g, '\\\\');
		const regExpText = new RegExp(`(${replacedSearchText})`, 'gi');
		propRows.forEach((item, i) => {
			let _item = item,
				_isMatchedText = false;
			
			// Find search string on fields
			(_searchColumns || []).forEach(k => {
				const rollup_searched_parts = (item[k]?.toString().split(regExpText) || []);
				rollup_searched_parts.forEach(part => {
					const isMatched = part.toLowerCase() === searchText.toLowerCase();
					if (isMatched) {
						const _searchedText = item[k]?.toString().replace(regExpText, '<span>$1</span>');
						_item = {..._item, [k]: _searchedText};
						_isMatchedText = true;
					}
				});
			});

			if (_isMatchedText) {
				matched_count++;
				_foundFirstIndex = _foundFirstIndex < 0 ? i : _foundFirstIndex;
				_matchedSearchIds.push({k: i, v: item.UUID});
			}
			_copyData[i] = _item;
		});

		let _isMatchedCount = matched_count > 0;
		setRowData(_copyData);
		setMatchedSearchCount({
			searchPrevNextCount: _isMatchedCount ? 1: 0, 
			searchTotalCount: matched_count, 
			searchPrevBtnDisable: true, 
			searchNextBtnDisable: !_isMatchedCount
		});
		setMatchedSearchIds(_matchedSearchIds);
		setSearchSelectedIndex(_foundFirstIndex);
		setScrollToIndex(_foundFirstIndex);
	}, [propRows, columns, searchColumns]);

	// Toggle search functionality - move to first item
	const handleFirstSearch = useCallback((e) => {
		let _searchSelectedIndex = matchedSearchIds.slice(0, 1),
			_searchSelectedIndexUpdated = _searchSelectedIndex[0] ? _searchSelectedIndex[0].k : 0;
		
		setSearchSelectedIndex(_searchSelectedIndexUpdated);
		setScrollToIndex(_searchSelectedIndexUpdated);
		setMatchedSearchCount({
			...matchedSearchCount, 
			searchPrevNextCount: 1, 
			searchPrevBtnDisable: true, 
			searchNextBtnDisable: false
		});
	}, [matchedSearchIds, matchedSearchCount]);

	// Toggle search functionality - move to last item
	const handleLastSearch = useCallback((e) => {
		let _searchSelectedIndex = matchedSearchIds.slice(-1),
			_searchSelectedIndexUpdated = _searchSelectedIndex[0] ? _searchSelectedIndex[0].k : 0;
		
		setSearchSelectedIndex(_searchSelectedIndexUpdated);
		setScrollToIndex(_searchSelectedIndexUpdated);
		setMatchedSearchCount({
			...matchedSearchCount, 
			searchPrevNextCount: matchedSearchIds.length, 
			searchPrevBtnDisable: false, 
			searchNextBtnDisable: true
		});
	}, [matchedSearchIds, matchedSearchCount]);

	// Toggle Next search functionality
	const handleNextSearch = useCallback((e) => {
		let _searchSelectedIndex = matchedSearchIds.find(item => {
			return item.k > (searchSelectedIndex || 0);
		});

		let _searchSelectedIndexUpdated = _searchSelectedIndex ? _searchSelectedIndex.k : searchSelectedIndex,
			_search_count_pnc = (matchedSearchCount.searchPrevNextCount >= matchedSearchCount.searchTotalCount 
				? matchedSearchCount.searchTotalCount 
					: ++matchedSearchCount.searchPrevNextCount),
			_isNextDisabled = _search_count_pnc >= matchedSearchCount.searchTotalCount;

		setSearchSelectedIndex(_searchSelectedIndexUpdated);
		setScrollToIndex(_searchSelectedIndexUpdated);
		setMatchedSearchCount({
			...matchedSearchCount, 
			searchPrevNextCount: _search_count_pnc, 
			searchPrevBtnDisable: false, 
			searchNextBtnDisable: _isNextDisabled
		});
    }, [matchedSearchIds, searchSelectedIndex, matchedSearchCount]);

	// Toggle previous search functionality
	const handlePrevSearch = useCallback((e) => {
		let _firstSearchIndexCount = 1,
			_matchedSearchIds = [...matchedSearchIds];
		_matchedSearchIds.reverse();

		let _searchSelectedIndex = _matchedSearchIds.find(item => {
			return (searchSelectedIndex || 0) > item.k;
		});

		let _search_count_pnc = (matchedSearchCount.searchPrevNextCount <= _firstSearchIndexCount 
			? _firstSearchIndexCount 
				: --matchedSearchCount.searchPrevNextCount),
			_searchSelectedIndexUpdated = _searchSelectedIndex ? _searchSelectedIndex.k : searchSelectedIndex,
			_isPrevDisabled = _search_count_pnc <= _firstSearchIndexCount;
		
		setSearchSelectedIndex(_searchSelectedIndexUpdated);
		setScrollToIndex(_searchSelectedIndexUpdated);
		setMatchedSearchCount({
			...matchedSearchCount, 
			searchPrevNextCount: _search_count_pnc, 
			searchPrevBtnDisable: _isPrevDisabled, 
			searchNextBtnDisable: false
		});
	}, [matchedSearchIds, searchSelectedIndex, matchedSearchCount]);

	// Toggle cancel - process/delete functionality
	const handleCancel = useCallback(e => {
		if (!isModifyEnabled) {
			return false;
		}
		setHistDataProcessActionState({
			process: {disable: true, clicked: false},
			deletei: {disable: true, clicked: false},
			cancel: {disable: true, clicked: true}
		});
		setHistDataProcessItems({
			indexItems: [],
			sourceItems: [],
			histDataProcessType: ''
		});
	}, [isModifyEnabled]);

	// Process sales rejected data
	const handleProcess = useCallback((e) => {
		if (!isModifyEnabled) {
			return false;
		}
		setHistDataProcessActionState({
			process: {disable: true, clicked: true},
			deletei: {disable: true, clicked: false},
			cancel: {disable: true, clicked: false}
		});

		// Callback if there is any
		// let ctxMenuItem = (contextMenuItems || []).filter(ctmItem => ctmItem.id === 'process')[0] || null;
		// ctxMenuItem?.callback && ctxMenuItem.callback(e, {...histDataProcessItems, histDataProcessType: ctxMenuItem?.id, onClearRowSelection: handleCancel});
	}, [isModifyEnabled]);

	// Delete sales rejected data
	const handleDeletei = useCallback(e => {
		if (!isModifyEnabled) {
			return false;
		}
		setHistDataProcessActionState({
			process: {disable: true, clicked: false},
			deletei: {disable: true, clicked: true},
			cancel: {disable: true, clicked: false}
		});

		// Callback if there is any
		/* let ctxMenuItem = (contextMenuItems || []).filter(ctmItem => ctmItem.id === 'deletei')[0] || null;
		ctxMenuItem?.callback && ctxMenuItem.callback(e, {...histDataProcessItems, histDataProcessType: ctxMenuItem?.id, onClearRowSelection: handleCancel}); */
	}, [isModifyEnabled]);
	
	// Grid item click functionality
	const handleRowClick = useCallback((e, row) => {
		if (!isModifyEnabled) {
			return false;
		}
		
		const {
			indexItems, 
			sourceItems
		} = histDataProcessItems;

		let indexItemsTmp = [...indexItems],
			sourceItemsTmp = [...sourceItems];
		if (indexItemsTmp.includes(row.UUID)) {
			indexItemsTmp = indexItems.filter(item => item !== row.UUID);
			sourceItemsTmp = sourceItems.filter(item => item.UUID !== row.UUID);
		}
		else {
			indexItemsTmp.push(row.UUID);
			sourceItemsTmp.push(row);
		}
		
		setHistDataProcessItems(prev => ({
			...prev,
			indexItems: indexItemsTmp,
			sourceItems: sourceItemsTmp
		}));
		setHistDataProcessActionState({
			process: {clicked: false, disable: indexItemsTmp.length ? false : true},
			deletei: {clicked: false, disable: indexItemsTmp.length ? false : true},
			cancel: {clicked: false, disable: indexItemsTmp.length ? false : true}
		});
	}, [isModifyEnabled, histDataProcessItems]);

	// Grid item row action click functionality
	const handleRowActionClick = useCallback((e, rowItem, rowAction) => {
		if (!isModifyEnabled) {
			return false;
		}

		setHistDataProcessItems(prev => ({
			...prev,
			indexItems: [rowItem.UUID],
			sourceItems: [rowItem]
		}));
		setHistDataProcessActionState({
			process: {clicked: false, disable: true},
			deletei: {clicked: false, disable: true},
			cancel: {clicked: false, disable: true}
		});

		rowAction.onClick && 
			rowAction.onClick(e, {rowItem: rowItem, onClearRowSelection: handleCancel});
	}, [isModifyEnabled, handleCancel]);

	// Column sort functionality
	const handleSortActionClick = useCallback((e, sortColumn, altSortColumn) => {
		let {sortBy, sortOrder} = sortColumns,
			sortColumnField = altSortColumn || sortColumn;
		
		if (sortBy === sortColumnField) {
			sortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
		}
		else {
			sortBy = sortColumnField;
			sortOrder = 'asc';
		}
		// Set column ordering
		setSortColumns(prev => ({
			...prev,
			sortBy: sortBy,
			sortOrder: sortOrder
		}));

		// Set sorted data
		setRowData(prev => prev.sort((i, j) => {
			if (i[sortColumnField] < j[sortColumnField]) {
				return sortOrder === 'asc' ? -1 : 1;
			}
			if (i[sortColumnField] > j[sortColumnField]) {
				return sortOrder === 'asc' ? 1 : -1;
			}
			return 0;
		}));
	}, [sortColumns]);
	
	// Define context menu callbacks
	const _fnCallbacks = useMemo(() => {
		return {
			handleprocess: handleProcess,
			handledeletei: handleDeletei,
			handlecancel: handleCancel
		};
	}, [handleProcess, handleDeletei, handleCancel]);

	// Handle context menu on right click
	const handleContextMenu = useCallback((e) => {
		if (!isModifyEnabled) {
			return false;
		}

		// Prevent default context menu
		e.preventDefault();
		e.stopPropagation();

		// Set context menu position
		setContextMenu(
			contextMenu === null 
				? {mouseX: e.clientX + 4, mouseY: e.clientY + 4}
				: null
		);
	}, [isModifyEnabled, contextMenu]);

	// Handle context menu on right click
	const handleContextMenuItem = useCallback((e, type) => {
		if (!isModifyEnabled) {
			return false;
		}

		// Prevent default context menu
		e.preventDefault();
		e.stopPropagation();

		// Set context menu to null to disappear
		setContextMenu(null);
		if (type === 'backdropClick') {
			return;
		}

		// Callback if there is any
		let ctxMenuItem = (contextMenuItems || []).filter(ctmItem => ctmItem.id === type)[0] || null;
		const _methodCallback = _fnCallbacks[`handle${ctxMenuItem?.id}`];
		if (typeof _methodCallback === 'function') {
			_methodCallback();
		}

		// Callback if there is any
		ctxMenuItem?.callback && ctxMenuItem.callback(e, {
			...histDataProcessItems, 
			histDataProcessType: ctxMenuItem?.id, 
			onClearRowSelection: _fnCallbacks.handlecancel
		});
	}, [isModifyEnabled, contextMenuItems, histDataProcessItems, _fnCallbacks]);

	// Resize column
	const resizeColumn = (e, dataKey, deltaX) => {
		let nextDataKey = '';
		const {width: gridWidth} = tableDimension;
		const percentDelta = deltaX / gridWidth;
		columns.forEach((item, i) => {
			if (item.field === dataKey) {
				nextDataKey = columns[i + 1]?.field || '_ACT_COL';
			}
		});
		setColumnWidths(prevWidths => ({
			...prevWidths,
			[dataKey]: prevWidths[dataKey] + percentDelta,
			[nextDataKey]: prevWidths[nextDataKey] - percentDelta
		}));
	}

	// userEffect hook to load data if changes
	useEffect(() => {
		(!propRows || propRows !== rows) && setPropRows(rows);

		!rowData && setRowData(propRows);
		if (rowData && propRows !== rows) {
			setRowData(rows);
			setMatchedSearchIds({});
		}

		// Column width
		columns.forEach(item => {
			setColumnWidths(prevState => ({
				...prevState,
				[item.field]: item?.width/1000 || 0.01
			}));
		});
	}, [rowData, rows, columns, propRows, extras]);

	// Grid actions object
	const gridActions = {
		searchActions: {
			toggleSearch: handleSearch,
			togglePrev: handlePrevSearch,
			toggleNext: handleNextSearch,
			toggleFirst: handleFirstSearch,
			toggleLast: handleLastSearch,
			toggleClear: handleClearSearch
		},
		filterActions: {
			toggleFilter: handleFilterChange
		},
		rowClick: handleRowClick,
		contextMenuItemClick: handleContextMenuItem,
		contextMenuClick: handleContextMenu,
		rowActionClick: handleRowActionClick,
		sortAction: {
			sortColumns: sortColumns,
			sortActionClick: handleSortActionClick
		},
		// columnMouseDown: columnMouseDown,
		resizeColumn: resizeColumn
	};

	// Grid options object
	const gridOptions = {
		histDataProcessOptions: {
			histDataProcessItems: histDataProcessItems,
			histDataProcessActionState: histDataProcessActionState
		},
		searchOptions: {
			searchText: searchInputText,
			searchPrevNextCount: matchedSearchCount.searchPrevNextCount, 
			searchTotalCount: matchedSearchCount.searchTotalCount,
			searchPrevBtnDisable: matchedSearchCount.searchPrevBtnDisable,
			searchNextBtnDisable: matchedSearchCount.searchNextBtnDisable
		},
		isModifyEnabled: isModifyEnabled,
		contextMenu: contextMenu,
		contextMenuItems: contextMenuItems,
		columnWidths: columnWidths
	};

	// Grid reference object
	const gridRefs = {
		tableRef: tableRef,
		searchInputRef: searchInputRef/* ,
		listContainerRef: listContainerRef */
	};

	return (
		<Container>
			<TableContainer {...props} 
				rowData={rowData} 
				scrollToIndex={scrollToIndex} 
				gridRefs={gridRefs} 
				gridOptions={gridOptions} 
				gridActions={gridActions}
				tableDimension={tableDimension}/>
		</Container>
	);
}

// Main Table container
const TableContainer = React.memo(props => {
	const {title, columns, options, actions, rowData, scrollToIndex, isLoading, gridRefs, gridOptions, gridActions, tableDimension} = props;
	const {rowClick, contextMenuClick, contextMenuItemClick, rowActionClick, sortAction, resizeColumn, filterActions: {toggleFilter}} = gridActions;
	const {contextMenu, contextMenuItems, isModifyEnabled, columnWidths, histDataProcessOptions: {histDataProcessActionState}} = gridOptions;
	const {width: gridWidth, height: gridHeight} = tableDimension;
	const {tableRef/* , listContainerRef */} = gridRefs;

	return (
		<TableRowContainer role="grid" ref={tableRef}>
			<TableCaptionBar title={title} totalCount={(rowData && rowData.length) || 0} gridRefs={gridRefs} gridOptions={gridOptions} gridActions={gridActions} options={options} tableDimension={tableDimension}/>
			<TableColumnContainer gridRefs={gridRefs} columns={columns} options={options} columnWidths={columnWidths} sortAction={sortAction} resizeColumn={resizeColumn} tableDimension={tableDimension}/>
			{options.filtering && <TableFilterContainer columns={columns} options={options} columnWidths={columnWidths} handleFilter={toggleFilter} tableDimension={tableDimension}/>}
			{!rowData && <StyledLoadingDiv><CircularProgress disableShrink size={16}/><span style={{paddingTop: '3px'}}>Please wait...</span></StyledLoadingDiv>}
			
			<StyledContextMenuDiv /* ref={listContainerRef}  */onContextMenu={contextMenuClick}>
				<List
					height={gridHeight}
					// autoHeight = {true}
					rowCount={rowData?.length || 0}
					rowHeight={35}
					width={gridWidth}
					style={{
						backgroundColor: getBackgroundColor(),
						transition: 'background-color 0.2s ease'
					}}
					rowRenderer={getRowRender({rowData, options, columns, actions, rowClick, rowActionClick, gridOptions, tableDimension})}
					scrollToIndex={scrollToIndex}
					overscanRowCount={10}
					scrollToAlignment={'start'}
					noRowsRenderer={() => <NoRowsRenderer/>}/>
				<MenuContext contextMenu={contextMenu} contextMenuItemClick={contextMenuItemClick} isModifyEnabled={isModifyEnabled} histDataProcessActionState={histDataProcessActionState} contextMenuItems={contextMenuItems} />
			</StyledContextMenuDiv>

			<OverlayLoader loading={isLoading}/>
		</TableRowContainer>
	)
})

// Context menu
const MenuContext = React.memo(props => {
	const {contextMenu, contextMenuItems, contextMenuItemClick, isModifyEnabled, histDataProcessActionState} = props;

	return (
		<Menu open={contextMenu !== null} onClose={contextMenuItemClick} anchorReference="anchorPosition" 
			anchorPosition={contextMenu !== null ? {top: contextMenu.mouseY, left: contextMenu.mouseX} : undefined}>
			{(contextMenuItems || []).map((ctxMenuItem, idx) => (
				<MenuItem key={`${idx}${ctxMenuItem}_${(new Date()).getTime()}`} onClick={(e) => contextMenuItemClick(e, ctxMenuItem.id)} disabled={histDataProcessActionState[ctxMenuItem.id]?.disable || !isModifyEnabled}>
					<ListItemIcon><ctxMenuItem.icon fontSize="small"/></ListItemIcon>
					<ListItemText>{ctxMenuItem.label}</ListItemText>
				</MenuItem>
			))}
		</Menu>
	)
})

// Table Caption Bar - Title / Search
const TableCaptionBar = React.memo(props => {
	const {title, totalCount, gridRefs: {searchInputRef}, gridOptions, gridActions, options, tableDimension: {width: gridWidth}} = props;
	const {
		contextMenuItemClick,
		searchActions: {toggleClear, toggleSearch, toggleFirst, togglePrev, toggleNext, toggleLast}
	} = gridActions;
	const {
		isModifyEnabled,
		contextMenuItems,
		searchOptions: {searchText, searchPrevNextCount, searchTotalCount, searchPrevBtnDisable, searchNextBtnDisable},
		histDataProcessOptions: {histDataProcessActionState}
	} = gridOptions;

	return (
		<StyledCaptionBar style={{width: `${gridWidth}px`}}>
			<Title>{title} ({totalCount})</Title>
			{options?.searching && <Search>
				<StyledSearchInput inputRef={searchInputRef} startAdornment={<InputAdornment position="start"><SearchIcon/></InputAdornment>} placeholder="Search" onKeyUp={toggleSearch} aria-label="search" 
					endAdornment={<IconButton sx={{visibility: searchText ? 'visible' : 'hidden'}} size="small" onClick={toggleClear}><ClearIcon sx={{width: '0.6em', height: '0.6em'}}/></IconButton>}/>
				{searchTotalCount > 0 && 
					<SearchPrevNextContainer>
						<IconButton onClick={toggleFirst} disabled={searchPrevBtnDisable} size="small"><FirstPageIcon/></IconButton>
						<IconButton onClick={togglePrev} disabled={searchPrevBtnDisable} size="small"><NavigateBeforeIcon/></IconButton>
						<span style={{flexGrow: 1, textAlign: 'center', paddingTop: '3px', fontWeight: 500, userSelect: 'none'}}>{searchPrevNextCount} / {searchTotalCount}</span>
						<IconButton onClick={toggleNext} disabled={searchNextBtnDisable} size="small"><NavigateNextIcon/></IconButton>
						<IconButton onClick={toggleLast} disabled={searchNextBtnDisable} size="small"><LastPageIcon/></IconButton>
					</SearchPrevNextContainer>
				}
			</Search>}

			<StyledActionMenuDiv>
			{(contextMenuItems || []).map((ctxMenuItem, idx) => (
				<IconButton title={ctxMenuItem?.label} key={`${idx}${ctxMenuItem}_${(new Date()).getTime()}`} onClick={(e) => contextMenuItemClick(e, ctxMenuItem.id)} disabled={histDataProcessActionState[ctxMenuItem.id]?.disable || !isModifyEnabled}>
					<ctxMenuItem.icon size={'small'} style={{width: '0.8em', height: '0.8em'}}/>
				</IconButton>
			))}
			</StyledActionMenuDiv>
		</StyledCaptionBar>
	)
})

// Table column container
const TableColumnContainer = React.memo(props => {
	const {columns, options, columnWidths, /* gridRefs: {listContainerRef},  */sortAction: {sortColumns: {sortBy, sortOrder}, sortActionClick}, resizeColumn, tableDimension: {width: gridWidth}} = props;
	const listGridContainer = document.querySelectorAll('div.ReactVirtualized__Grid__innerScrollContainer')[0] || null;
	const scrollBarWidth = (gridWidth - (listGridContainer?.clientWidth || gridWidth));
	
	return (
		<ColumnContainerDiv role="row" style={{fontWeight: 500, paddingRight: `${scrollBarWidth}px`, width: `${gridWidth}px`}}>
			{columns.map((column, idx) => {
				const columnWidth = (columnWidths[column.field] || 0.01) * gridWidth;

				return (
					<RowItemDiv role="columnheader" key={`h-${column.field}${idx}`} style={{flex: `0 1 ${columnWidth}px`}}>
						{column?.sorting 
							? <><StyledColumnSortDiv active={sortBy === (column?.sortColumn || column.field)} direction={sortOrder} onClick={(e) => sortActionClick(e, column.field, column?.sortColumn)}>{column.label}</StyledColumnSortDiv>
									<ColumnResizerRenderer column={column} resizeColumn={resizeColumn}/></>
							: <><StyledRowItemText style={{textAlign: column.align}}>{column.label}</StyledRowItemText>
									<ColumnResizerRenderer column={column} resizeColumn={resizeColumn}/></>
						}
					</RowItemDiv>
				)
			})}
			{options.action && <RowItemDiv style={{justifyContent: 'center', flex: '0 1 9.4em'}}>Actions</RowItemDiv>}
		</ColumnContainerDiv>
	)
})

// Column resizer placeholder
const ColumnResizerRenderer = React.memo((props) => {
	const {column, resizeColumn} = props;

	return (
		<>
			{(column?.resize === undefined || !!column?.resize) 
				? <ColumnResizer axis="x" onDrag={(e, { deltaX }) => resizeColumn(e, column.field, deltaX) } position={{ x: 0 }}>
					<StyledColumnResizeHandler>|</StyledColumnResizeHandler>
				</ColumnResizer>
				: <StyledColumnResizeDisableDiv>|</StyledColumnResizeDisableDiv>
			}
		</>
	)
})

// Table filter container
const TableFilterContainer = React.memo(props => {
	const {columns, options, handleFilter, columnWidths, tableDimension: {width: gridWidth}} = props;
	const actionColumWidth = (columnWidths['_ACT_COL'] || 0.01) * gridWidth;

	return (
		<FilterContainerDiv style={{fontWeight: 500, width: `${gridWidth}px`}}>
			{columns.map((column, idx) => {
				const columnWidth = (columnWidths[column.field] || 0.01) * gridWidth;

				return (
					column.filter 
						? <FilterItemDiv key={`f-${column.field}${idx}`} style={{textAlign: column.align, flex: `0 1 ${columnWidth}px`}}>
								<FilterInput aria-label="description" id={`filter-${column.field}-input`} onKeyUp={handleFilter} name={column.field} 
									startAdornment={<InputAdornment position="start"><FilterListOutlinedIcon sx={{width: '0.7em', height: '0.7em'}}/></InputAdornment>}>
								</FilterInput>
							</FilterItemDiv> 
						: <FilterItemDiv key={`f-${column.field}${idx}`} style={{flex: `0 1 ${columnWidth}px`}}/>
				)
			})}
			{options.action && <FilterItemDiv style={{flex: `0 1 ${actionColumWidth}px`}}/>}
		</FilterContainerDiv>
	)
})

// Table row item
const RowItem = React.memo(props => {
	const {
		columns, row, actions, options, style, rowClick, rowActionClick, index,
		gridOptions: {isModifyEnabled, histDataProcessOptions, columnWidths}, 
		tableDimension: {width: gridWidth}
	} = props;
	const {
		histDataProcessItems: {indexItems}
	} = histDataProcessOptions;
	let bgColors = {
		color1: indexItems?.includes(row.UUID) ? '#71b8ff' : 'inherit'
	};

	return (
		<RowContainer role="row" style={{...getStyle(style, bgColors)}}>
			{columns.map(column => {
				const columnWidth = (columnWidths[column.field] || 0.01) * gridWidth;
				const _textValue = column.format && typeof row[column.field] === 'number' ? column.format(row[column.field]) : getMarkdownText(row[column.field]);

				return (
					<RowItemDiv role="gridcell" onClick={e => rowClick(e, row, index)} key={`${row.UUID}-${column.field}`} 
						style={{justifyContent: column.align, overflow: 'hidden', flex: `0 1 ${columnWidth}px`}}>
							<StyledRowItemText style={{textAlign: column.align}} dangerouslySetInnerHTML={_textValue}/>
					</RowItemDiv>
				)
			})}
			{options.action &&
				<RowItemDiv style={{width: `calc(10.6em - 17px)`}}>
				{(actions || []).map((action, idx) => (
					<IconButton key={`_actions_${idx}`} title={action?.title} disabled={!isModifyEnabled} onClick={e => rowActionClick(e, row, action)} sx={{flexGrow: 1, padding: '.1em'}}><action.icon sx={{width: '0.67em', height: '0.67em'}}/></IconButton>
				))}
				</RowItemDiv>
			}
		</RowContainer>
	)
})

// No rows found placeholder
const NoRowsRenderer = React.memo(() => {
	return (
		<StyledNoRecordDiv>No record(s) found!</StyledNoRecordDiv>
	)
})

// Overlay loader
const OverlayLoader = React.memo(props => {
	const {loading} = props;

	return (
		<Backdrop open={loading} sx={{color: '#0e5ead', backgroundColor: 'rgb(0 0 0 / 2%)', zIndex: (theme) => theme.zIndex.drawer + 1}}>
			<StyledLoaderDiv>
				<CircularProgress size={30} color="inherit"/>
				<span style={{fontSize: '0.9rem', marginLeft: '0.8em'}}>Please wait...</span>
			</StyledLoaderDiv>
		</Backdrop>
	)
})

// Using a higher order function so that we can look up the rows data to retrieve row from within the rowRender function
const getRowRender = (props) => ({index, style}) => {
	const {rowData, options, columns, actions, rowClick, rowActionClick, gridOptions, tableDimension} = props;
	const row = rowData[index] || undefined;
	if (!row) {
		return null;
	}

	// Rendering an extra item for the placeholder by increasing data set size to include one 'fake' item
	const patchedStyle = {
		...style,
		left: style.left,
		top: style.top + 2,
		width: style.width,
		height: style.height - 0.5
	};

	return (
		<RowItem key={row.UUID} index={index} gridOptions={gridOptions} rowClick={rowClick} rowActionClick={rowActionClick} 
			row={row} actions={actions} columns={columns} options={options} style={patchedStyle} tableDimension={tableDimension} />
	);
}

// Get drag item style while dragging
const getStyle = (styles, bgColors) => {
	const {color1} = bgColors || {color1: 'inherit'};
	
	return {
		...styles,
		color: 'inherit',
		borderTop: '0 none',
		backgroundColor: color1
	};
}

// Backgroud color
const getBackgroundColor = () => {
	return 'inherit';
};

// Get Mark down text
const getMarkdownText = (value) => {
    return {__html: value};
}
