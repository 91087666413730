import { Home } from './modules/module.home';
import { ProductRollup } from './modules/module.product.rollup';
import { GeographyRollup } from './modules/module.geography.rollup';
import { HistSalesRejectedData } from './modules/module.hist.sales.rejected';
import { SalesCalNonSellingDays } from './modules/module.sales.nonsellingdays';
import { SalesCalEndOfMonth } from './modules/module.sales.endofmonth';
import { SalesForceHierarchy } from './modules/module.salesforce.hierarchy';
import { SalesForceRepNames } from './modules/module.salesforce.rep.names';
import { ProductXrefValidation } from './modules/module.prod.xref.validation';

// URL prefix
const UrlPrefix = {
	Rollups: '/Rollups',
	SalesCalendar: '/SalesCalendar',
	SalesForce: '/SalesForce',
	Utilities: '/Utilities'
	
};

// Menu Items
export const navMenuItems = [
	/* {
		title: 'Home',
		path: '/'
	}, */
	{
		title: 'Rollups',
		children: [
			{title: 'Product Rollup', path: `${UrlPrefix.Rollups}/product-rollup`},
			{title: 'Geography Rollup', path: `${UrlPrefix.Rollups}/geography-rollup`}
		]
	},
	{
		title: 'Sales Calendar',
		children: [
			{title: 'Non-Selling Days', path: `${UrlPrefix.SalesCalendar}/non-selling-days`},
			{title: 'End of Month', path: `${UrlPrefix.SalesCalendar}/end-of-month`}
		]
	},/* 
	{
		title: 'Sales Force',
		children: [
			{title: 'Sales Force Hierarchy', path: `${UrlPrefix.SalesForce}/sales-force-hierarchy`},
			{title: 'Sales Rep Names', path: `${UrlPrefix.SalesForce}/sales-rep-names`}
		]
	}, */
	{
		title: 'Utilities',
		children: [
			{title: 'Product XRef Validation', path: `${UrlPrefix.Utilities}/distr-product-xref-validation`},
			{title: 'Historical Sales Data', path: `${UrlPrefix.Utilities}/hist-sales-rejected-data`}
		]
	}
];


// Set routing of modules
export const moduleRoutes = [
	{component: Home, link: '/'},
	{component: ProductRollup, link: `${UrlPrefix.Rollups}/product-rollup`},
	{component: GeographyRollup, link: `${UrlPrefix.Rollups}/geography-rollup`},
	{component: SalesCalNonSellingDays, link: `${UrlPrefix.SalesCalendar}/non-selling-days`},
	{component: SalesCalEndOfMonth, link: `${UrlPrefix.SalesCalendar}/end-of-month`},
	{component: SalesForceHierarchy, link: `${UrlPrefix.SalesForce}/sales-force-hierarchy`},
	{component: SalesForceRepNames, link: `${UrlPrefix.SalesForce}/sales-rep-names`},
	{component: HistSalesRejectedData, link: `${UrlPrefix.Utilities}/hist-sales-rejected-data`},
	{component: ProductXrefValidation, link: `${UrlPrefix.Utilities}/distr-product-xref-validation`}
];
